/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { PreFooterData, Response, NewResponse, PreFooterMiddleItem } from '../interface';
import { getAxios } from '@/lib/axios';
import { requestBaseURL, requestBaseURLGCP, requestBaseURLGCPExtOrInt } from '@/config/serviceAPI';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { useRouter } from 'next/router';
import { getPathnameFromAsPath, parseUrl } from '@/utils/urlHelpers';
import { type AxiosInstance } from 'axios';
import { createQuery } from '@/utils/createReactQuery';
import { usePageType } from '@/features/category/api/getPageType';
import { useCMSCategoryData } from '@/features/category/api/getCMSCategoryData';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import { mergePrefooterBackendWithCMSData } from '../utils/mergePrefooterBackendWithCMSData';
import { updatePrefooterBackendLinks } from '../utils/updatePrefooterBackendLinks';
import { getPageDependentPrefooterParams } from '../utils/getPageDependentPrefooterParams';
import { useContentStackDefaultPDPContent } from '@/features/pdp/api/getContentStackDefaultProductContent';
import { useContentStackPremiumPDPContent } from '@/features/pdp/api/getContentStackPremiumProductContent';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import { pageTypes } from '@/constants/page';
import { countryCodes } from '@/constants/locale';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { useContentStackPLPData } from '@/features/productListingPage/api/getContentStackProductListingPageData';

const URL = `${requestBaseURLGCP}/ecomm/b2c/v2/page/prefooter`;
const URL_TO_USE_WITH_CMS = `${requestBaseURL}/external/product-discovery/seo-data/v1/seo-footer-mappings`;
const GCP_URL = `${requestBaseURLGCPExtOrInt}/sls/b2c/product-discovery-seo-data-bs/v2/seo-footer-mappings`;

type Options = {
  locale: Locale;
  seourl: string;
  vehicleId: string;
  cmsShelfPageEnabled?: boolean;
  gcpUrlEnabled: boolean;
};

type GetDataForCMSOptions = {
  isCMSEnabled: boolean;
  filterName?: string;
  pageType?: string;
  catalogRepositoryId?: string;
  taxonomyPath?: string;
  vehicleMake?: string;
  vehicleModel?: string;
  vehicleYear?: string;
  shopByMakeActive?: boolean;
  shopByModelActive?: boolean;
  shopByYearActive?: boolean;
};

const { useData: usePreFooterData, prefetch: prefetchPreFooterData } = createQuery<
  PreFooterData,
  Options & GetDataForCMSOptions
>('preFooterData', async (options, axiosInstance) => {
  if (options.locale === countryCodes.ptBr) return [];

  return options.isCMSEnabled
    ? getPreFooterDataForCMS(options, axiosInstance)
    : getPreFooterData(options, axiosInstance);
});

const getPreFooterDataForCMS = async (
  {
    seourl,
    vehicleId,
    filterName,
    pageType,
    catalogRepositoryId,
    taxonomyPath,
    vehicleMake,
    vehicleModel,
    vehicleYear,
    shopByMakeActive,
    shopByModelActive,
    shopByYearActive,
    locale,
    gcpUrlEnabled,
  }: Options & GetDataForCMSOptions,
  axiosInstance?: AxiosInstance
) => {
  const params = {
    country: getCountryFromLocale(locale),
    customerType: 'B2C',
    filterName: filterName,
    pageType: pageType === 'ProductShelf' || pageType === 'ProductDetails' ? 'PRODUCT' : 'CATEGORY',
    preview: showXMPreviewDate(),
    seoRepositoryId: catalogRepositoryId,
    seoUrl: taxonomyPath ?? seourl,
    shopByMakeActive,
    shopByModelActive,
    shopByYearActive,
    vehicleMake: vehicleMake,
    vehicleModel: vehicleModel,
    vehicleYear: vehicleYear,
    ...(vehicleId && { vehicleId }),
  };
  const response = await getAxios(axiosInstance).get<NewResponse>(
    gcpUrlEnabled ? GCP_URL : URL_TO_USE_WITH_CMS,
    {
      params,
      ...(gcpUrlEnabled && { withCredentials: false }),
    }
  );

  return selectorDataForCMS(response.data);
};

const getPreFooterData = async ({ seourl, vehicleId }: Options, axiosInstance?: AxiosInstance) => {
  const params: { seourl: string; vehicleId?: string } = {
    seourl,
    vehicleId: vehicleId || '0',
  };

  const response = await getAxios(axiosInstance).get<Response>(URL, {
    params,
  });
  return selector(response.data);
};

const selector = (data: Response): PreFooterData => {
  return data.contents;
};

const selectorDataForCMS = (data: NewResponse): PreFooterData => {
  return [
    {
      preFooterMiddle: data.map((item) => {
        return {
          name: item.contentGroupTitle ?? '',
          contents: [
            {
              numItemsDisplay: '10',
              title: item.contentGroupTitle ?? '',
              tablet: true,
              mobile: true,
              desktop: true,
              name: item.contentGroupTitle ?? '',
              content: (item.contentGroupMappings ?? []).map((mapping) => ({
                tablet: true,
                mobile: true,
                desktop: true,
                CTALink: mapping.linkUrl,
                name: mapping.linkLabel,
              })),
            },
          ],
        };
      }),
      contents: [],
    },
  ];
};

type PrefooterMiddleDataResult = PreFooterMiddleItem[];

type PrefooterMiddleDataSelect = (data: PreFooterData) => PrefooterMiddleDataResult;

const usePreFooterMiddleData = ({ gcpUrlEnabled }: { gcpUrlEnabled: boolean }) => {
  const { asPath, pathname } = useRouter();
  const locale = useLocale();
  const seourl = getPathnameFromAsPath(asPath).replace(/(\/bn\/|\/b\/).*/, '');
  const vehicleId = usePreferredVehicle()?.catalogVehicleId ?? '0';
  const { data: pageTypeData } = usePageType();
  const isPDP = pathname === '/products/[...slug]';
  const isShelf = pageTypeData?.pageType?.toLowerCase().includes(pageTypes.shelf);

  const { data: productDetails, isFetched: areProductDetailsFetched } = useProductDetails({
    enabled: isPDP,
  });
  const { data: plpCMSData, isCSProductListingPageEnabled } = useContentStackPLPData({
    enabled: false,
  });

  const { data: catgeoryCMSData, enabled: isCatgeoryCMSEnabled } = useCMSCategoryData();

  const taxonomyPath = productDetails?.product?.cmsContentPath ?? '';
  const brandName =
    productDetails?.product?.subBrandName ?? productDetails?.product?.brandName ?? '';

  const { data: defaultPDPContent } = useContentStackDefaultPDPContent({
    enabled: isPDP,
    taxonomyPath,
  });
  const premiumBrands: string[] = defaultPDPContent?.premiumBrands?.brands ?? [];

  const { data: premiumPDPContent } = useContentStackPremiumPDPContent({
    enabled:
      isPDP &&
      areProductDetailsFetched &&
      premiumBrands.includes(productDetails?.product?.brandName ?? ''),
    taxonomyPath,
    brandName,
  });

  const prefooterContentStack = isCatgeoryCMSEnabled
    ? catgeoryCMSData
    : isPDP
    ? premiumPDPContent ?? defaultPDPContent
    : plpCMSData;

  const pageDependentParams = getPageDependentPrefooterParams({
    isPDP,
    pageTypeData,
    productDetails,
  });

  const isUS = locale === 'en-US';
  const isCMSDisabled =
    !(isCSProductListingPageEnabled && isShelf) && !isPDP && !isCatgeoryCMSEnabled;

  return usePreFooterData<PrefooterMiddleDataResult, PrefooterMiddleDataSelect>({
    locale,
    seourl,
    enabled: !isCMSDisabled ? (isPDP ? !!productDetails?.product?.categoryId : true) : isUS,
    vehicleId,
    isCMSEnabled: !isCMSDisabled,
    ...pageDependentParams,
    select: (data) => {
      if (isCMSDisabled) {
        return data[0]?.preFooterMiddle ?? [];
      }

      const prefooterColumns = updatePrefooterBackendLinks(
        data[0].preFooterMiddle,
        (isPDP ? productDetails?.product?.seoUrl : pageTypeData?.canonicalPath) ??
          parseUrl(asPath).url
      );

      return mergePrefooterBackendWithCMSData(
        prefooterColumns,
        prefooterContentStack?.prefooter?.prefooter?.[0].column,
        isCatgeoryCMSEnabled
      );
    },
    gcpUrlEnabled,
  });
};

export { usePreFooterData, usePreFooterMiddleData, prefetchPreFooterData };
