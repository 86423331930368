/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxios } from '@/lib/axios';
import { type AxiosInstance } from 'axios';
import { type MyProfileApi, type MyProfileData } from '../interface';
import { createQuery } from '@/utils/createReactQuery';
import { type QueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { type ReduxState } from '@/types';
import { requestBaseURL } from '@/config/serviceAPI';

const URL = `${requestBaseURL}/ecomm/b2c/v1/currentUser`;

type Options = Record<string, never>;

const getMyProfile = async (options: Options, axiosInstance?: AxiosInstance) => {
  const response = await getAxios(axiosInstance).get<MyProfileApi>(URL, {
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  });

  return selector(response.data);
};

export const selector = (data: MyProfileApi): MyProfileData => data;

export const invalidateMyProfileQuery = (queryClient: QueryClient) =>
  queryClient.invalidateQueries({ queryKey: [myProfileQuery.getFullKey({})] });

const {
  useData: useMyProfileBase,
  prefetch: prefetchMyProfile,
  query: myProfileQuery,
} = createQuery<MyProfileData, Options>('myProfile', getMyProfile);

type MyProfileOptions = {
  enabled?: boolean;
};

const useMyProfile = (options: MyProfileOptions = { enabled: true }) => {
  const { enabled } = options;
  const userAuthenticated = useSelector((state: ReduxState) => state.appData.userAuthenticated);
  return useMyProfileBase({ enabled: userAuthenticated && enabled });
};

export { getMyProfile, myProfileQuery, prefetchMyProfile, useMyProfile };
