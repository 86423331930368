/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type ContentStackTermsAndConditions } from '../../interface';
import styles from './CMSTermsAndConditions.module.scss';
import CMSTableOfContents from './CMSTableOfContents';
import ScrollToTopButton from '@/components/ScrollToTopButton/ScrollToTopButton';
import { View } from '@az/starc-ui';
import { useEffect } from 'react';

export type Props = {
  content: ContentStackTermsAndConditions;
};

const CMSTermsAndConditions = ({ content }: Props) => {
  const paragraphs = content.content;
  useEffect(() => {
    const tables = document.querySelectorAll(`.${styles.paragraph} table`);
    tables.forEach((table) => {
      const wrapper = document.createElement('div');
      wrapper.className = styles.tableWrapper;
      table.parentNode?.insertBefore(wrapper, table);
      wrapper.appendChild(table);
    });
  }, []);

  return (
    <View className={styles.page}>
      <View>
        <h1 className={styles.pageTitleMobile}>{content.heading}</h1>
        <div
          className={styles.pageDescriptionMobile}
          dangerouslySetInnerHTML={{ __html: content.page_description }}
        />
      </View>
      <View className={styles.container}>
        {content.menu_section.menu_group.length > 0 && (
          <View className={styles.tableOfContentsContainer}>
            <CMSTableOfContents groups={content.menu_section.menu_group} />
          </View>
        )}
        <View className={styles.contentContainer}>
          <View>
            <h1 className={styles.pageTitle}>{content.heading}</h1>
            <div
              className={styles.pageDescription}
              dangerouslySetInnerHTML={{ __html: content.page_description }}
            />
          </View>
          {paragraphs.map((paragraph, index) => (
            <View key={`${paragraph.section_id}-${index}`} className={styles.paragraphsContainer}>
              <h2 className={styles.paragraphHeading} id={paragraph.section_id?.replace('#', '')}>
                {paragraph.heading}
              </h2>
              {paragraph.group_content.map((section) => (
                <div
                  key={`${section.section_id}`}
                  id={section.section_id.replace('#', '')}
                  className={styles.paragraph}
                  dangerouslySetInnerHTML={{ __html: section.content }}
                />
              ))}
            </View>
          ))}
        </View>
      </View>
      <ScrollToTopButton />
    </View>
  );
};

export default CMSTermsAndConditions;
