/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { useHeaderData } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { getAxios } from '@/lib/axios';
import logger from '@/utils/logger';
import { type AxiosInstance } from 'axios';
import { type QueryFunctionContext, useQuery, type UseQueryResult } from '@tanstack/react-query';
import { type SkuAvailabiltyResponse } from '../interface';
import type { SkuAvailabilty } from '@/types/availability';

const URL = `${requestBaseURL}/ecomm/b2c/browse/v2/sku/availability`;

type Options = {
  skuIds: Array<string | number | null> | null;
  // These are not used in the API call, but the result varies depending on them
  // So we need to use them in the key for the cache
  vehicleId: string | undefined;
  storeNumber: string | undefined;
};
const getProductAvailability = async (
  { queryKey }: QueryFunctionContext<ReturnType<typeof getProductAvailabilityKey>>,
  axiosInstance?: AxiosInstance
) => {
  const [, { skuIds, vehicleId }] = queryKey;

  if (!skuIds) {
    logger.warn({ message: "A 'skuId' wasn't passed" });
    return;
  }

  const response = await getAxios(axiosInstance).get<SkuAvailabiltyResponse>(
    `${URL}/${skuIds.join(',')}`
  );

  return selector(response.data, vehicleId);
};

const selector = (data: SkuAvailabiltyResponse, vehicleId: string): SkuAvailabilty[] => {
  return data.map((skuDetailsResponse: SkuAvailabilty) => {
    return {
      catalogVehicleId: vehicleId,
      ...skuDetailsResponse,
    };
  });
};

export const getProductAvailabilityKey = ({ skuIds, vehicleId, storeNumber, ...rest }: Options) =>
  [
    'productAvailability',
    {
      skuIds: skuIds && skuIds.length ? skuIds : null,
      storeNumber: storeNumber || '',
      vehicleId: vehicleId || '0',
      ...rest,
    },
  ] as const;

export const useProductAvailability = <T extends [string | number] | Array<string | number>>({
  skuIds,
  enabled = Boolean(skuIds?.length),
}: {
  skuIds: T | null;
  enabled?: boolean;
}): UseQueryResult<T extends [string | number] ? [SkuAvailabilty] : SkuAvailabilty[]> => {
  const headerResult = useHeaderData();
  const storeNumber = headerResult.data?.storeNumber;
  const vehicleId = getPreferredVehicle(headerResult.data)?.catalogVehicleId;

  return useQuery({
    enabled: headerResult.isSuccess && enabled,
    queryKey: getProductAvailabilityKey({ skuIds, storeNumber, vehicleId }),
    queryFn: getProductAvailability,
    cacheTime: 0,
  });
};
