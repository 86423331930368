/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { AxiosInstance } from 'axios';
import type { Dispatch, GetState, ThunkAction } from '../types';
import actionConstant from '../types/action';
import azURL, { getATGDataWithoutCache, relPath } from '../config/serviceAPI';
import { checkoutConstants } from '../constants/checkout';
import { type QueryClient } from '@tanstack/react-query';
import { type HeaderData } from '@/features/header';
import { getHeaderDataKey } from '@/features/header/api/getHeader';
import { type OrderDetailsData } from '../types/index';

const getMiniCartData = (data: any) => {
  const dataLength = data?.orders ? data.orders.length : 0;
  const headerData = {
    corePrice: 0,
    itemCount: 0,
    orderSubTotal: 0,
    orderDetails: data,
  };

  if (dataLength > 0) {
    headerData.orderSubTotal = data.grandOrderPriceInfo.totalPrice;
    headerData.corePrice = data.grandOrderPriceInfo.corePrice;
    data.orders.forEach((order: any) => {
      order.shipmentInfoList.forEach((shipmentInfo: any) => {
        shipmentInfo.lineItemList.forEach((item: any) => {
          headerData.itemCount += Number(item.itemQuantity);
        });
      });
    });
  }

  return headerData;
};

export const requestingOrderItem = (): ThunkAction => (dispatch: Dispatch) => {
  dispatch({ type: actionConstant.UPDATE_ORDERITEM_REQUESTING });
};

export const getOrderDetails = async (
  axios: AxiosInstance,
  hideInStorePurchase = false
): Promise<OrderDetailsData> => {
  let orderDetailsUrl = azURL(relPath.orderDetailsV4);

  if (hideInStorePurchase) {
    orderDetailsUrl += checkoutConstants.nonInStoreOrderTypesParams;
  }

  const { data } = await getATGDataWithoutCache<OrderDetailsData>(axios, orderDetailsUrl);
  return data;
};

export const fetchOrderDetails =
  (hideInStorePurchase = false, queryClient: QueryClient): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: AxiosInstance) => {
    dispatch({ type: actionConstant.ORDER_DETAILS_REQUESTING });
    try {
      const data = await getOrderDetails(axios, hideInStorePurchase);
      dispatch({
        type: actionConstant.ORDER_DETAILS_SUCCESS,
        data,
      });

      queryClient.setQueryData<HeaderData>(
        getHeaderDataKey({
          previewDate: undefined,
          workspaceId: undefined,
        }),
        (oldData: HeaderData | undefined) => {
          if (!oldData) {
            return undefined;
          }

          return {
            ...oldData,
            miniCartMap: {
              ...oldData.miniCartMap,
              ...getMiniCartData(data),
            },
            miniCartSuccessNote: true,
          };
        }
      );
    } catch (err: any) {
      dispatch({
        type: actionConstant.ORDER_DETAILS_FAILURE,
        err: err.message,
        errorLog: err,
      });
    }
  };

export const clearOrderDetails = (): ThunkAction => (dispatch: Dispatch) => {
  dispatch({ type: actionConstant.CLEAR_ORDER_DETAILS });
};

export const setCheckoutButton =
  (checkoutButtonState: boolean): ThunkAction =>
  (dispatch: Dispatch) => {
    dispatch({
      type: actionConstant.SET_CHECKOUT_BUTTON,
      setCheckoutButton: checkoutButtonState,
    });
  };

export const setOrderStatus =
  (orderStatus: string): ThunkAction =>
  (dispatch: Dispatch) => {
    dispatch({
      type: actionConstant.SET_ORDER_STATUS,
      orderStatus,
    });
  };
