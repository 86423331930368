/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const STH_FULFILLMENT_TYPE_IDS = [101, 102, 103, 104, 109];

/**
 * Determines if a given fulfillment type ID is a Ship to Home (STH) type.
 * @param fulfillmentTypeId - The fulfillment type ID to check
 * @returns True if the ID is a STH type, false otherwise
 */
export function getIsSTHFulfillmentTypeId(fulfillmentTypeId: number) {
  return STH_FULFILLMENT_TYPE_IDS.includes(fulfillmentTypeId);
}
