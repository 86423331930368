/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { AxiosInstance } from 'axios';
import type { Dispatch, GetState, ThunkAction } from '../types';
import { getOrderDetails } from '@/actions/details';
import cartAnalyticsAdapter from '../adaptors/cartAnalytics';
import actionConstant from '../types/action';
import azUrl, { getATGData } from '@/config/serviceAPI';
import { type HeaderData } from '@/features/header';
import { getHeaderDataKey } from '@/features/header/api/getHeader';
import { type QueryClient } from '@tanstack/react-query';

type MiniCartData = { itemCount: number; orderSubTotal: number; corePrice: number };

const fetchMiniCartData =
  (queryClient: QueryClient): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: AxiosInstance) => {
    // TODO: API team will be returning orderDetails as part of miniCart endpoint in the future.
    // Whenever this happens, remove the extra API call here
    await getATGData<MiniCartData>(axios, azUrl('miniCart')).then(async (res) => {
      const { data: miniCartData } = res;

      await getOrderDetails(axios, undefined).then((res) => {
        const orderDetails = res;

        const cartAnalyticsData = cartAnalyticsAdapter(orderDetails);
        queryClient.setQueryData(
          getHeaderDataKey({
            previewDate: undefined,
            workspaceId: undefined,
          }),
          (oldData: HeaderData | undefined): HeaderData => {
            return {
              ...(oldData as HeaderData),
              miniCartMap: {
                ...miniCartData,
                orderDetails,
                cartAnalyticsData,
              },
              analyticsData: {
                ...((oldData as HeaderData)?.analyticsData || {}),
                ...(cartAnalyticsData || {}),
              },
              miniCartSuccessNote: true,
            };
          }
        );

        dispatch({
          type: actionConstant.UPDATE_ORDERITEM_SUCCESS,
          data: orderDetails,
        });
      });
    });
  };

export default fetchMiniCartData;
