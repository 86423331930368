/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React from 'react';
import { type ContentStackImageContent } from '../../interface';
import { CMSImage } from '../CMSImage/CMSImage';
import { CMSMediaContent } from '../CMSMediaContent/CMSMediaContent';

type Props = {
  content: ContentStackImageContent;
};

export const isValidCMSImageContent = (content: ContentStackImageContent) => {
  return Boolean(content?.image?.image_url || content.description || content.heading);
};

export function CMSImageContent({ content }: Props) {
  const options = {
    button: content.button,
    description: content.description,
    direction: content.image_direction,
    heading: content.heading,
    heading_underline: content.heading_underline,
    mobile_number: content.mobile_number,
    button_text: content.button_text,
  };
  return (
    <CMSMediaContent
      options={options}
      mediaElement={content.image && <CMSImage image={content.image} />}
    />
  );
}
