/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type AxiosInstance } from 'axios';
import { requestBaseURL } from '@/config/serviceAPI';
import type {
  BrandShelfResponseModel,
  FacetModel,
  GetBrandListResultsUsingGetParamsModel,
} from '@/api/types/browse-search-types';
import { getAxios } from '@/lib/axios';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { createQuery } from '@/utils/createReactQuery';
import { useLocale } from '@/hooks/useLocale';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useRouter } from 'next/router';
import { type InfiniteData, type QueryClient, useInfiniteQuery } from '@tanstack/react-query';
import { parseUrl } from '@/utils/urlHelpers';
import { getMapFacet, useMapFacet } from '@/hooks/useMapFacet';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import { useIs24ProductViewEnabled } from '@/hooks/useIs24ProductViewEnabled';
import type { GetProductBrandDataOptions, MappedBrandShelfResults } from '../interface';
import { useMemo } from 'react';
import { getKiboDecisionFlag, useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';

const URL = `${requestBaseURL}/external/product-discovery/browse-search/v1/brand-shelves`;

const selector = (
  { brandShelfResults, redirectUrl, xFusionQueryId }: BrandShelfResponseModel,
  mapFacet: ReturnType<typeof useMapFacet>
): MappedBrandShelfResults => {
  return {
    redirectLocation: redirectUrl,
    recordsPerPage: brandShelfResults?.recordsPerPage ?? 12,
    total: brandShelfResults?.totalNumberOfRecords,
    quickFilters: brandShelfResults?.quickFilters,
    firstRecNum: brandShelfResults?.firstRecordNumber,
    lastRecNum: brandShelfResults?.lastRecordNumber,
    staticNavigation: brandShelfResults?.facets?.map(mapFacet) ?? [],
    brandName: brandShelfResults?.brandName ?? '',
    records: brandShelfResults?.skuRecords?.map((record) => ({
      ...record,
      active: record.activeFlag,
      alternatePartNumber: record.alternatePartNumber,
      brandName: record.brandName,
      seoUrl: record.productDetailsPageUrl,
      imageUrl: record.itemImageUrl ?? '',
      productImageUrl: record.itemImageUrl ?? '',
      description: record.itemDescription,
      skuNumber: Number(record.itemId),
      locationFilter: record.itemLocation,
      lineCode: record.lineCode,
      systemCode: Number(record.originalSystemCode),
      partNumber: record.partNumber,
      productRepositoryId: record.eCommerceProductId,
      productId: record.eCommerceProductId,
      quickNote: record.quickNote,
      techNote: record.technicalNote,
      recordType: record.recordType,
      skuReviewEnabled: record.reviewEnabledFlag,
      vehicleFitmentLabel: record.vehicleFitmentLabel,
      warrantyMonths: record.warrantyMonths,
      brand: record.brandName ?? '',
      name: record.itemDescription,
      productReviewsEnabled: record.reviewEnabledFlag,
      vehicleFit: record.vehicleFitmentFlag ?? false,
      vehicleSpecific: record.vehicleSpecificFlag ?? false,
      originalPartTypeId: record.originalPartTypeId ?? '',
    })),
    xFusionQueryId,
  };
};

const getBrandProductData = async (
  options: GetProductBrandDataOptions,
  axiosInstance?: AxiosInstance
) => {
  const { locale, ...rest } = options;
  const country = getCountryFromLocale(locale);
  const customerType = 'B2C';
  const salesChannel = 'ECOMM';

  const params: GetBrandListResultsUsingGetParamsModel = {
    ...rest,
    country,
    customerType,
    salesChannel,
  };
  const response = await getAxios(axiosInstance).get<BrandShelfResponseModel>(URL, {
    params,
  });

  return response.data;
};

const {
  useData: useBrandProducts,
  prefetch: prefetchProductBrand,
  query: productBrandQuery,
} = createQuery<BrandShelfResponseModel, GetProductBrandDataOptions>(
  'brandProducts',
  getBrandProductData
);

export const useBrandProductData = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const locale = useLocale();
  const router = useRouter();
  const mapFacet = useMapFacet();
  const preferredVehicle = usePreferredVehicle();
  const { data: storeDetailsData } = useStoreDetailsData();
  const is24ProductViewEnabled = useIs24ProductViewEnabled();
  const storeNumber = storeDetailsData?.storeNumber;
  const vehicleId = preferredVehicle?.catalogVehicleId;
  const isLoadMoreEnabled = useMonetateDecisionFlag('loadMoreEnabled');
  const {
    currentPage,
    sort = [],
    recsPerPage,
    pageNumber,
    facet,
    minPrice,
    maxPrice,
  } = router.query;
  const sortArr = typeof sort === 'string' ? sort.split('-') : sort;
  const sortOrder = sortArr[sortArr.length - 1];
  const sortFieldName = sortArr.slice(0, sortArr.length - 1).join('-');

  const path = router.asPath;
  const parsedUrl = parseUrl(path);
  const options: GetProductBrandDataOptions = {
    locale,
    pageNumber:
      typeof currentPage === 'string'
        ? Number(currentPage)
        : typeof pageNumber === 'string'
        ? Number(pageNumber)
        : 1,
    vehicleId,
    recordsPerPage:
      typeof recsPerPage === 'string'
        ? Number(recsPerPage)
        : is24ProductViewEnabled
        ? 24
        : undefined,
    minPrice: minPrice ? String(minPrice) : undefined,
    maxPrice: maxPrice ? String(maxPrice) : undefined,
    facet: Array.isArray(facet) ? facet.join(',') : facet,
    sort: sortFieldName && sortOrder ? `${sortFieldName}-${sortOrder}` : undefined,
    storeId: storeNumber,
    seoUrl: parsedUrl.url,
    preview: showXMPreviewDate(),
    mapFacet: mapFacet,
  };
  let infiniteQueryData: InfiniteData<BrandShelfResponseModel> | undefined;
  let fetchNextPageBrandProductData = () => Promise.resolve();
  let hasNextPageBrandProductData: boolean | undefined = false;
  let fetchPreviousPageBrandProductData = () => Promise.resolve();
  let hasPreviousPageBrandProductData: boolean | undefined = false;
  let statusBrandProductData = 'idle';
  let refetchBrandProductResults = () => Promise.resolve();

  const infiniteQueryFn = ({ pageParam = 1 }) =>
    getBrandProductData({
      ...options,
      pageNumber: pageParam,
    });

  const infiniteQueryResult = useInfiniteQuery({
    queryKey: [...productBrandQuery.getFullKey(options), 'infinite'],
    queryFn: infiniteQueryFn,
    getNextPageParam: (lastPage, allPages) => {
      const totalRecords = lastPage?.brandShelfResults?.totalNumberOfRecords ?? 0;
      const recsPerPage = Number(options.recordsPerPage) || 24;
      const currentPageNumber = allPages.length;
      const totalPages = Math.ceil(totalRecords / recsPerPage);

      if (currentPageNumber < totalPages) {
        return currentPageNumber + 1;
      }
      return undefined;
    },
    enabled: enabled && isLoadMoreEnabled,
    onSuccess: async (data: InfiniteData<BrandShelfResponseModel>) => {
      const lastPage = data.pages[data.pages.length - 1];
      if (lastPage.redirectUrl) {
        await router.replace(lastPage.redirectUrl);
      }
    },
  });
  const regularQueryResult = useBrandProducts({
    ...options,
    enabled: enabled && !isLoadMoreEnabled,
    onSuccess: async (data: BrandShelfResponseModel) => {
      if (data.redirectUrl) {
        await router.replace(data.redirectUrl);
      }
    },
  });
  if (isLoadMoreEnabled) {
    const {
      data,
      fetchNextPage,
      hasNextPage,
      fetchPreviousPage,
      hasPreviousPage,
      status,
      refetch,
    } = infiniteQueryResult;
    fetchNextPageBrandProductData = () => fetchNextPage().then(() => {});
    hasNextPageBrandProductData = hasNextPage;
    fetchPreviousPageBrandProductData = () => fetchPreviousPage().then(() => {});
    hasPreviousPageBrandProductData = hasPreviousPage;
    statusBrandProductData = status;
    refetchBrandProductResults = () => refetch().then(() => {});
    infiniteQueryData = data;
  }
  const mergedData = useMemo(() => {
    if (!infiniteQueryData?.pages) {
      return undefined;
    }
    return infiniteQueryData?.pages.reduce(
      (acc: BrandShelfResponseModel, page: BrandShelfResponseModel): BrandShelfResponseModel => {
        if (page.brandShelfResults) {
          return {
            ...acc,
            brandShelfResults: {
              ...acc.brandShelfResults,
              ...page.brandShelfResults,
              skuRecords: [
                ...(acc.brandShelfResults?.skuRecords ?? []),
                ...(page.brandShelfResults?.skuRecords ?? []),
              ],
            },
            redirectUrl: page.redirectUrl,
            redirectPageType: page.redirectPageType,
            xFusionQueryId: page.xFusionQueryId,
          };
        }
        return acc;
      },
      { brandShelfResults: { skuRecords: [] } } as unknown as BrandShelfResponseModel
    );
  }, [infiniteQueryData?.pages]);
  const isFetching = isLoadMoreEnabled
    ? infiniteQueryResult.isFetching
    : regularQueryResult.isFetching;

  const isLoading = isLoadMoreEnabled
    ? infiniteQueryResult.isLoading
    : regularQueryResult.isLoading;
  const resultData = isLoadMoreEnabled ? mergedData : regularQueryResult.data;
  const selectedData = selector(
    resultData ?? ({ brandShelfResults: { skuRecords: [] } } as unknown as BrandShelfResponseModel),
    mapFacet ?? ((facet: FacetModel) => getMapFacet(facet, 'Clearance'))
  );

  return {
    data: selectedData,
    pageNumber: isLoadMoreEnabled ? infiniteQueryData?.pages.length ?? 1 : options.pageNumber,
    fetchNextPage: fetchNextPageBrandProductData,
    hasNextPage: hasNextPageBrandProductData,
    fetchPreviousPage: fetchPreviousPageBrandProductData,
    hasPreviousPage: hasPreviousPageBrandProductData,
    isFetching,
    isLoading,
    status: isLoadMoreEnabled ? statusBrandProductData : regularQueryResult.status,
    refetch: isLoadMoreEnabled ? refetchBrandProductResults : regularQueryResult.refetch,
    fetchStatus: isLoadMoreEnabled
      ? infiniteQueryResult.fetchStatus
      : regularQueryResult.fetchStatus,
    isSuccess: isLoadMoreEnabled ? infiniteQueryResult.isSuccess : regularQueryResult.isSuccess,
    isError: isLoadMoreEnabled ? infiniteQueryResult.isError : regularQueryResult.isError,
  };
};

export const prefetchBrandInfinityData = (
  queryClient: QueryClient,
  options: GetProductBrandDataOptions,
  axiosInstance?: AxiosInstance
) => {
  return queryClient.prefetchInfiniteQuery({
    queryKey: [...productBrandQuery.getFullKey(options), 'infinite'],
    queryFn: ({ pageParam = 1 }: { pageParam?: number }) =>
      getBrandProductData({ ...options, pageNumber: pageParam }, axiosInstance),
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = allPages.length + 1;
      return nextPage <=
        Math.ceil(
          (lastPage?.brandShelfResults?.totalNumberOfRecords ?? 0) /
            (lastPage?.brandShelfResults?.recordsPerPage ?? 0)
        )
        ? nextPage
        : undefined;
    },
  });
};

export const getBrandProductDataFromCache = (
  queryClient: QueryClient,
  options: GetProductBrandDataOptions
): MappedBrandShelfResults | undefined => {
  const isLoadMoreEnabled = getKiboDecisionFlag(queryClient, 'loadMoreEnabled');
  if (isLoadMoreEnabled) {
    return queryClient.getQueryData<InfiniteData<MappedBrandShelfResults>>([
      ...productBrandQuery.getFullKey(options),
      'infinite',
    ])?.pages?.[0];
  }

  return queryClient.getQueryData<MappedBrandShelfResults>(productBrandQuery.getFullKey(options));
};

export { prefetchProductBrand };
