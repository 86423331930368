/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import dayjs from 'dayjs';
import { type CustomizedStoreHours } from '@/components/StoreLocator/types';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export const isDuringBusinessHours = (hours: CustomizedStoreHours) => {
  dayjs.extend(customParseFormat);
  const closed = 'Closed';
  const { dayNumber, openHour, openMinute, closeHour, closeMinute } = hours;
  const currentTime = dayjs();
  const currentDay = currentTime.day();

  const closedString = `${closeHour}:${closeMinute}`;
  const midnightValue = closedString === '0:00';
  const closingTime = dayjs(closedString, 'HH:mm');
  const isSameDay = parseInt(dayNumber) === currentDay;

  const minuteBeforeMidnight = dayjs('23:59', 'HH:mm');

  const isAfterHours = isSameDay && currentTime.isAfter(closingTime);
  const isOpen24Hours =
    openHour === '0' && openMinute === '00' && closeHour === '0' && closeMinute === '00';

  const storeCurrentlyOpen = currentTime.isBefore(
    midnightValue ? minuteBeforeMidnight : closingTime
  );
  const closesAtMidnight = storeCurrentlyOpen && midnightValue;
  const storeClosedToday = openHour === closed && openMinute === closed;

  switch (true) {
    case closesAtMidnight:
      return true;

    case isOpen24Hours:
      return true;

    case storeClosedToday:
      return false;

    case isAfterHours:
      return false;

    default:
      return true;
  }
};
