/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import cx from 'classnames';
import { type Keys, useTheme } from '../../theme/ThemeContext';
import styles from './styles.module.scss';
import type { CommonProps } from './types';

const HiddenCssRoot = ({
  className,
  styleProps,
  children,
}: {
  className?: string;
  styleProps: CommonProps & {
    breakpoints: {
      breakpoint: Keys;
      dir: string;
    }[];
  };
  children: React.ReactNode;
}) => {
  const classNames = styleProps.breakpoints.map(
    (breakpoint) => styles[`${breakpoint.breakpoint}-${breakpoint.dir}`]
  );
  return (
    <div data-testid="hidden-root" className={cx(classNames, className)}>
      {children}
    </div>
  );
};

function HiddenCss(props: CommonProps) {
  const { children, className, only, ...other } = props;
  const theme = useTheme();
  const breakpoints = [];

  for (let i = 0; i < theme.breakpoints.keys.length; i += 1) {
    const breakpoint = theme.breakpoints.keys[i];
    const breakpointUp = other[`${breakpoint}Up`];
    const breakpointDown = other[`${breakpoint}Down`];

    if (breakpointUp) {
      breakpoints.push({
        breakpoint,
        dir: 'up',
      });
    }

    if (breakpointDown) {
      breakpoints.push({
        breakpoint,
        dir: 'down',
      });
    }
  }

  if (only) {
    const onlyBreakpoints = Array.isArray(only) ? only : [only];
    onlyBreakpoints.forEach((breakpoint) => {
      breakpoints.push({
        breakpoint,
        dir: 'only',
      });
    });
  }

  const styleProps = { ...props, breakpoints };
  return (
    <HiddenCssRoot className={className} styleProps={styleProps}>
      {children}
    </HiddenCssRoot>
  );
}

export default HiddenCss;
