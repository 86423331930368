/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { createContext, useState, type ReactNode } from 'react';

export type Flows =
  | 'login'
  | 'chooseSignInMethod'
  | 'OTPFlow'
  | 'password'
  | 'forgotPassword'
  | 'resetPasswordEmailSent'
  | 'autoCreatedOnlineAccount'
  | 'manualCreateAccount'
  | 'rewardsEnterId'
  | 'signInOrCreateNewPW';

export type RewardsState = 'initial' | 'loggedIn' | 'enterId';

export type ForgotPasswordState = 'initial' | 'requested';

type SignInFlowTypeProviderType = {
  setFlowTypeFn: (flowTypeValue: Flows) => void;
  setRewardsStateFn: (rewardsStateValue: RewardsState) => void;
  setUserLoginFn: (value: string) => void;
  setShowGlobalNotificationFn: (value: boolean) => void;
  resetSignInFlowTypeContextFn: () => void;
  setIsSignInModalOpened: (value: boolean) => void;
  isSignInModalOpened: boolean;
  userLogin: string;
  flowType: string;
  rewardsState: string;
  showGlobalNotification: boolean;
  previousFlowType: string;
};

export const SignInFlowTypeContext = createContext<SignInFlowTypeProviderType>(
  {} as SignInFlowTypeProviderType
);

type SignInFlowProviderProps = {
  children: ReactNode;
};

export const SignInFlowTypeProvider = ({ children }: SignInFlowProviderProps) => {
  const [flowType, setFlowType] = useState<Flows>('login');
  const [rewardsState, setRewardsState] = useState<RewardsState>('initial');
  const [previousFlowType, setPreviousFlowType] = useState('');
  const [userLogin, setUserLogin] = useState('');
  const [showGlobalNotification, setShowGlobalNotification] = useState<boolean>(false);
  const [isSignInModalOpened, setIsSignInModalOpened] = useState(false);
  const setFlowTypeFn = (currentFlowTypeValue: Flows) => {
    // do not update previousFlowType if 'Resend Code' link was used
    if (
      currentFlowTypeValue === 'OTPFlow' &&
      (previousFlowType === 'forgotPassword' || previousFlowType === 'chooseSignInMethod')
    ) {
      setPreviousFlowType(previousFlowType);
    } else {
      setPreviousFlowType(flowType);
    }

    setFlowType(currentFlowTypeValue);
  };

  const setRewardsStateFn = (rewardsStateValue: RewardsState) => setRewardsState(rewardsStateValue);

  const setUserLoginFn = (value: string) => setUserLogin(value);

  const setShowGlobalNotificationFn = (value: boolean) => setShowGlobalNotification(value);

  const resetSignInFlowTypeContextFn = () => {
    setFlowTypeFn('login');
    setUserLoginFn('');
  };

  return (
    <SignInFlowTypeContext.Provider
      value={{
        setFlowTypeFn,
        setRewardsStateFn,
        setUserLoginFn,
        setShowGlobalNotificationFn,
        resetSignInFlowTypeContextFn,
        setIsSignInModalOpened,
        isSignInModalOpened,
        flowType: flowType,
        rewardsState: rewardsState,
        userLogin: userLogin,
        showGlobalNotification: showGlobalNotification,
        previousFlowType: previousFlowType,
      }}
    >
      {children}
    </SignInFlowTypeContext.Provider>
  );
};
