/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { formControlState, type State } from '../FormControl/formControlState';
import { useFormControl } from '../FormControl/FormControlContext';
import { FormLabel } from '../FormLabel/FormLabel';
import styles from './InputLabel.module.scss';

type Props = {
  children?: React.ReactNode;
  color?: 'primary' | 'secondary';
  /**
   * If `true`, the transition animation is disabled.
   */
  disableAnimation?: boolean;
  /**
   * If `true`, apply disabled class.
   */
  disabled?: boolean;
  /**
   * If `true`, the label will be displayed in an error state.
   */
  error?: boolean;
  /**
   * If `true`, the input of this label is focused.
   */
  focused?: boolean;
  /**
   * If `dense`, will adjust vertical spacing. This is normally obtained via context from
   * FormControl.
   */
  margin?: 'dense';
  /**
   * if `true`, the label will indicate that the input is required.
   */
  required?: boolean;
  /**
   * If `true`, the label is shrunk.
   */
  shrink?: boolean;
  /**
   * The variant to use.
   */
  variant?: 'standard' | 'outlined' | 'filled';
  /**
   * @ignore
   */
  className?: string;
  classes?: {
    [key: string]: string;
  };
  htmlFor?: string;
  'data-testid'?: string;
  id?: string;
};

export const InputLabel = React.forwardRef<HTMLElement, Props>(function InputLabel(props, ref) {
  const {
    className,
    classes = {},
    disableAnimation = false,
    margin,
    shrink: shrinkProp,
    variant,
    ...other
  } = props;
  const muiFormControl = useFormControl();
  let shrink;

  if (typeof shrinkProp === 'undefined') {
    shrink = muiFormControl?.filled || muiFormControl?.focused || muiFormControl?.adornedStart;
  }

  const fcs = formControlState({
    props,
    muiFormControl,
    states: Object.keys({
      margin,
      variant,
    }) as State[],
  });
  return (
    <FormLabel
      data-shrink={shrink || shrinkProp}
      className={cx(
        classes.root,
        classes.multiline,
        styles.root,
        {
          [cx(classes.formControl, styles.formControl)]: muiFormControl,
          [cx(classes.animated, styles.animated)]: !disableAnimation,
          [cx(classes.shrink, styles.shrink)]: shrink || shrinkProp,
          [cx(classes.marginDense, styles.marginDense)]: fcs.margin === 'dense',
          [cx(classes.filled, styles.filled)]: fcs.variant === 'filled',
          [cx(classes.outlined, styles.outlined)]: fcs.variant === 'outlined',
        },
        className
      )}
      classes={{
        focused: classes.focused,
        disabled: classes.disabled,
        error: classes.error,
        required: classes.required,
        asterisk: classes.asterisk,
      }}
      ref={ref}
      {...other}
    />
  );
});
