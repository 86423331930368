/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxios } from '@/lib/axios';
import { requestBaseURL } from '@/config/serviceAPI';
import { type AxiosInstance } from 'axios';
import { createQuery } from '@/utils/createReactQuery';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { type CategoryByModelData, type CategoryByModelResponse } from '../interface';
import { type GetShopByMakeUsingGetParamsModel } from '@/api/types/browse-search-types';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';

const URL = `${requestBaseURL}/external/product-discovery/browse-search/v1/category-models`;

type Options = {
  seoUrl: string;
  locale: Locale;
  enabled?: boolean;
  preview: boolean;
};

type OptionsQuery = {
  seoUrl: string;
  enabled?: boolean;
};

const getCategoryModels = async (
  { seoUrl, locale, preview }: Options,
  axiosInstance?: AxiosInstance
) => {
  const country = getCountryFromLocale(locale);
  const customerType = 'B2C';
  const salesChannel = 'ECOMM';

  const params: GetShopByMakeUsingGetParamsModel = {
    country,
    customerType,
    salesChannel,
    preview,
    seoUrl,
  };
  const response = await getAxios(axiosInstance).get<CategoryByModelResponse>(URL, {
    params,
  });
  return selector(response.data);
};

const selector = (data: CategoryByModelResponse): CategoryByModelData => {
  const response = data.modelRanges.map((item) => ({
    name: item.linkDisplayText,
    seoUrl: item.seoUrl,
    makeModelYearPath: item.makeModelYearPath,
  }));

  return {
    shopByMMYList: response,
    title: data.title,
    canonicalPath: data.canonicalPath,
    taxonomyPath: data.taxonomyPath,
    pageType: data.pageType,
  };
};

const { useData: useCategoryByModelData } = createQuery<CategoryByModelData, Options>(
  'categoryModels',
  getCategoryModels
);

const useCategoryByModel = ({ enabled = true, ...rest }: OptionsQuery) => {
  const locale = useLocale();

  const options: Options = {
    ...rest,
    locale,
    preview: showXMPreviewDate(),
  };
  return useCategoryByModelData({
    enabled: enabled,
    ...options,
  });
};

export { useCategoryByModel };
