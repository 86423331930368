/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Stack } from '@az/starc-ui';
import { type ContentStackImageVideoSplit } from '../../interface';
import styles from './CMSImageVideoSplit.module.scss';
import { CMSHeadline } from '../CMSHeadline/CMSHeadline';
import { CMSImageVideoContent } from '../CMSImageVideoContent/CMSImageVideoContent';
import React from 'react';

type Props = {
  content: ContentStackImageVideoSplit;
};

export const CMSImageVideoSplit = ({ content }: Props) => {
  const { headline, split_content } = content;
  return (
    <Stack gap={8}>
      <CMSHeadline noGutter>{headline}</CMSHeadline>
      <View className={styles.splitContent}>
        {split_content?.map((imageContent, index) => (
          <View className={styles.componentContainer} key={index}>
            {<CMSImageVideoContent content={imageContent} />}
          </View>
        ))}
      </View>
    </Stack>
  );
};
