/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React from 'react';
import { type ContentStackCertona } from '../../interface';
import { CertonaBlock, type CertonaBlockProps } from '@/features/certona';
import styles from './CMSCertona.styles.module.scss';
import cx from 'classnames';
import { useResponsiveCertonaContainerStyle } from '../../hooks/useResponsiveCertonaContainerStyle';
import { useRouter } from 'next/router';

type Props = {
  content: ContentStackCertona;
} & CertonaBlockProps;

type Theme = 'white' | 'dark';
type CertonaTheme = 'white' | 'grey';

const responsiveTargets = { tablet: true, desktop: true, mobile: true };

export function CMSCertona({ content, certonaRecommendations, ...certonaProps }: Props) {
  const { pathname } = useRouter();
  const isShelfSkinnyRecommendations = pathname === '/[...seoUrlPath]' && !!certonaProps.subtitle;
  const { container_id, theme, title, container_style } = content;
  const currentTheme = theme as Theme;
  const customContainerStyle = useResponsiveCertonaContainerStyle(container_style);

  const mapThemeToCertonaTheme = (theme: Theme): CertonaTheme =>
    theme === 'dark' ? 'grey' : 'white';

  const contentItem = {
    name: title,
    containerID: container_id,
    themeCode: mapThemeToCertonaTheme(currentTheme),
    ...responsiveTargets,
  };

  return (
    <CertonaBlock
      contentItem={contentItem}
      certonaRecommendations={certonaRecommendations}
      cardsContainerCustomClass={styles.cardsContainer}
      containerType={customContainerStyle}
      className={cx({ [styles.skinnyRecommendations]: isShelfSkinnyRecommendations })}
      {...certonaProps}
    />
  );
}
