/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState, type MutableRefObject } from 'react';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

const useIsOverflow = (ref: MutableRefObject<any>, isVerticalOverflow: boolean) => {
  const [isOverflow, setIsOverflow] = useState(false);

  useIsomorphicLayoutEffect(() => {
    const handleResize = () => {
      if (ref && ref.current) {
        const { current } = ref;
        const { clientWidth, scrollWidth, clientHeight, scrollHeight } = current;

        const checkIfHasOverflow = () => {
          const hasOverflow = isVerticalOverflow
            ? scrollHeight > clientHeight
            : scrollWidth > clientWidth;

          setIsOverflow(hasOverflow);
        };

        checkIfHasOverflow();
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref, isVerticalOverflow]);

  return isOverflow;
};

export default useIsOverflow;
