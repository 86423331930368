/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { AddCertonaItemObjectType, CtjAnalyticsType } from '../interface';
import type { AddToCartData, CertonaItem, CertonaPricing } from '@/types';
import ScrolledPartCardContainer from './ScrolledPartCardContainer';
import StackedPartCardContainer from './StackedPartCardContainer';
import type { SkuAvailabilty } from '@/types/availability';
import PartsCarousel from '@/components/AZCustomComponent/PartsCarousel';

type Props = {
  id: string | undefined;
  title: string;
  subtitle: string;
  partsList: CertonaItem[];
  pricingInfo?: Record<string, CertonaPricing>;
  pageType: '' | string;
  greyBackground?: boolean;
  isFromExternal?: boolean;
  onCertonaRecommendationClick?: () => void;
  updateAfterAddtoCartClick?: (item?: AddToCartData) => void;
  addToCartAnalyticsandSuccessMessage?: (cartData: AddToCartData) => void;
  skuDetailsData?: SkuAvailabilty[] | null;
  containerID?: string;
  thresholdHeaderText?: string;
  inDrawer: boolean;
  cardsContainerCustomClass?: string;
  setAddedCertonaItemObject?: React.Dispatch<React.SetStateAction<AddCertonaItemObjectType | null>>;
  isOos?: boolean;
  doesNotFit?: boolean;
  shelfSeoUrl?: string;
  isCompleteTheJob?: boolean;
  getCtjAnalytics?: (skuId: string) => CtjAnalyticsType;
};

const CertonaPartsCarousel = (props: Props) => {
  const {
    id,
    title,
    subtitle,
    partsList,
    pricingInfo,
    pageType,
    greyBackground,
    isFromExternal,
    onCertonaRecommendationClick,
    updateAfterAddtoCartClick,
    addToCartAnalyticsandSuccessMessage,
    skuDetailsData,
    containerID,
    thresholdHeaderText,
    inDrawer,
    isOos,
    doesNotFit,
    shelfSeoUrl,
    cardsContainerCustomClass,
    setAddedCertonaItemObject,
    isCompleteTheJob,
    getCtjAnalytics,
  } = props;

  if (!partsList || partsList.length < 0) {
    return null;
  }
  const isOosOrDoesNotFit = isOos || doesNotFit;
  const partsListForOos = isOosOrDoesNotFit ? partsList.slice(0, 3) : partsList;

  switch (true) {
    case containerID?.includes('horizontal_stacked'):
      return (
        <StackedPartCardContainer
          title={title}
          partsList={partsList}
          contentsAligment="horizontal"
          pageType={pageType}
          onCertonaRecommendationClick={onCertonaRecommendationClick}
          updateAfterAddtoCartClick={updateAfterAddtoCartClick}
          addToCartAnalyticsandSuccessMessage={addToCartAnalyticsandSuccessMessage}
          skuDetailsData={skuDetailsData}
          pricingInfo={pricingInfo}
          thresholdHeaderText={thresholdHeaderText}
          setAddedCertonaItemObject={setAddedCertonaItemObject}
          inDrawer={inDrawer}
          isCompleteTheJob={isCompleteTheJob}
          getCtjAnalytics={getCtjAnalytics}
        />
      );
    case containerID?.includes('horizontal_scrolled'):
      return (
        <ScrolledPartCardContainer
          id={id}
          title={title}
          subtitle={subtitle}
          partsList={partsListForOos}
          contentsAligment="horizontal"
          pageType={pageType}
          onCertonaRecommendationClick={onCertonaRecommendationClick}
          addToCartAnalyticsandSuccessMessage={addToCartAnalyticsandSuccessMessage}
          skuDetailsData={skuDetailsData}
          pricingInfo={pricingInfo}
          updateAfterAddtoCartClick={updateAfterAddtoCartClick}
          thresholdHeaderText={thresholdHeaderText}
          isOos={isOos}
          doesNotFit={doesNotFit}
          shelfSeoUrl={shelfSeoUrl}
          inDrawer={inDrawer}
          setAddedCertonaItemObject={setAddedCertonaItemObject}
        />
      );
    case containerID?.includes('large_stacked'):
      return (
        <StackedPartCardContainer
          title={title}
          partsList={partsList}
          contentsAligment="vertical"
          pageType={pageType}
          onCertonaRecommendationClick={onCertonaRecommendationClick}
          addToCartAnalyticsandSuccessMessage={addToCartAnalyticsandSuccessMessage}
          skuDetailsData={skuDetailsData}
          pricingInfo={pricingInfo}
          thresholdHeaderText={thresholdHeaderText}
          setAddedCertonaItemObject={setAddedCertonaItemObject}
          inDrawer={inDrawer}
          isCompleteTheJob={isCompleteTheJob}
          getCtjAnalytics={getCtjAnalytics}
        />
      );
    case containerID?.includes('large_scrolled'):
      return (
        <ScrolledPartCardContainer
          title={title}
          subtitle={subtitle}
          partsList={partsList}
          contentsAligment="vertical"
          pageType={pageType}
          onCertonaRecommendationClick={onCertonaRecommendationClick}
          addToCartAnalyticsandSuccessMessage={addToCartAnalyticsandSuccessMessage}
          skuDetailsData={skuDetailsData}
          pricingInfo={pricingInfo}
          thresholdHeaderText={thresholdHeaderText}
          setAddedCertonaItemObject={setAddedCertonaItemObject}
        />
      );
    default:
      return (
        <PartsCarousel
          title={title}
          partsList={partsList}
          pageType={pageType}
          greyBackground={greyBackground}
          isFromExternal={isFromExternal}
          onCertonaRecommendationClick={onCertonaRecommendationClick}
          addToCartAnalyticsandSuccessMessage={addToCartAnalyticsandSuccessMessage}
          skuDetailsData={skuDetailsData}
          cardsContainerCustomClass={cardsContainerCustomClass}
        />
      );
  }
};

export default CertonaPartsCarousel;
