/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { Locale } from '@/types/i18n';
import { landingPagesHandledByContentStack } from '../consts';
import { countryCodes } from '@/constants/locale';

export const isLandingPageHandledByContentStack = (
  contentStackEnabled: boolean,
  locale: Locale,
  pageName?: string
) => {
  if (locale === countryCodes.ptBr || locale === countryCodes.mx) return true;

  return Boolean(
    contentStackEnabled && pageName && landingPagesHandledByContentStack[locale].includes(pageName)
  );
};
