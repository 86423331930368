/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { type VehicleProps } from '../components/types';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { type VehicleDetailsResponse } from '../interface';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { createQuery } from '@/utils/createReactQuery';
import { type AxiosInstance } from 'axios';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';

const URL = `${requestBaseURL}/external/product-discovery/vehicle-identification/v1/vehicles`;

type VehicleByVinQueryOptions = {
  locale: Locale;
  vinNumber: string | undefined;
  preview: boolean;
};

type VehicleByVINResponse = Array<{ vehicleId: string; vehicleDisplayName: string }>;

type VehicleByVINData = VehicleByVINResponse;

const { useData: useVehicleVINLookup } = createQuery<VehicleByVINData, VehicleByVinQueryOptions>(
  'vin',
  async (options, axiosInstance) => getVehicleByVIN(options, axiosInstance)
);

const getVehicleByVIN = async (
  options: VehicleByVinQueryOptions,
  axiosInstance?: AxiosInstance
) => {
  const { locale, vinNumber, preview } = options;
  const country = getCountryFromLocale(locale);
  const customerType = 'B2C';
  const salesChannel = 'ECOMM';

  if (!vinNumber) {
    throw new Error('vinNumber is required');
  }

  const response = await getAxios(axiosInstance).get<VehicleDetailsResponse>(`${URL}`, {
    params: {
      country,
      customerType,
      salesChannel,
      vehicleIdentificationNumber: vinNumber,
      preview,
    },
  });

  return newSelector(response.data);
};

const newSelector = (data: VehicleDetailsResponse): VehicleByVINData => {
  return data.map((item) => ({
    vehicleDisplayName: item.vehicleDisplayName,
    vehicleId: item.azVehicleId,
  }));
};

type UseVINLookupOptions = {
  vinNumber?: string;
  onSuccess?: (vehicles: VehicleProps[]) => void;
  enabled?: boolean;
};

export const useVINLookup = ({ vinNumber, onSuccess, enabled = true }: UseVINLookupOptions) => {
  const locale = useLocale();

  return useVehicleVINLookup({
    enabled: Boolean(vinNumber) && enabled,
    onSuccess,
    locale,
    vinNumber,
    preview: showXMPreviewDate(),
    cacheTime: 0,
  });
};
