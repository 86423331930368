/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import dayjs, { type Dayjs } from 'dayjs';
import { countryCodes } from '@/constants/locale';
import type { Locale } from '@/types/i18n';
import { localeDateFormats } from '@/constants/date';

export const formatDate = (
  date: string | Date,
  locale?: Locale | null,
  isDatePreFormatted?: boolean
): string => {
  if (typeof date === 'string' && date.length < 10) {
    return date;
  }

  if (isDatePreFormatted) {
    return getFormattedDate(date, localeDateFormats['en-US']);
  }

  return getFormattedDate(date, localeDateFormats[locale ?? countryCodes.us]);
};

// Function to round the time and return formatted string
export function roundToNearestHourString(date: Dayjs): string {
  return date.add(1, 'hour').minute(0).second(0).format('h A');
}

export function getFormattedDate(
  date: string | Date | undefined,
  dateFormat: string,
  isForShipmentTracking?: boolean,
  dateForStoreOrder?: boolean,
  isDelivered?: boolean
): string {
  if (!date) {
    return '';
  }

  //There is an issue with certain date formats when using javascript Date object.
  //Need to either adjust formatting from BE or pass date string and dateFormat directly to dayjs

  const dateObject = dayjs(new Date(date));

  const isToday = dateObject.isSame(dayjs(), 'day');
  const isTomorrow = dateObject.isSame(dayjs().add(1, 'day'), 'day');
  const formattedDeliveryTime = roundToNearestHourString(dateObject);

  if (dateObject.isValid()) {
    if (isForShipmentTracking) {
      if (dateForStoreOrder || isDelivered) {
        // for STOREORDER and delivered Item, we don't need the Arriving today/tomorrow text, just the formatted date
        return dateObject.format(dateFormat);
      }
      if (isToday) {
        return `Arriving today by ${formattedDeliveryTime}`;
      } else if (isTomorrow) {
        return `Arriving tomorrow by ${formattedDeliveryTime}`;
      } else {
        return dateObject.format(dateFormat);
      }
    } else {
      return dateObject.format(dateFormat);
    }
  }

  return date.toString();
}

export const getDeliveryDate = (
  deliveryDate: string,
  locale: string,
  labels: Record<string, string>,
  delivered: boolean = false
) => {
  const today = new Date();
  const date = new Date(deliveryDate);

  const difference = date.getDate() - today.getDate();

  if (date.getMinutes() > 0) {
    date.setTime(date.getTime() + 3600000);
  }

  const isToday =
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate();
  const isTomorrow =
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate() + 1;

  const isPM = date.getHours() >= 12;
  const hour =
    date.getHours() === 0 ? 12 : date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
  if ((isToday || isTomorrow) && !delivered) {
    return `${
      isToday ? labels['label_orderHistory_todayBy'] : labels['label_orderHistory_tomorrowBy']
    } ${hour} ${isPM ? 'PM' : 'AM'}`;
  }

  if (
    difference < 7 &&
    difference >= 0 &&
    date.getFullYear() == today.getFullYear() &&
    date.getMonth() == today.getMonth() &&
    !delivered
  ) {
    return date.toLocaleDateString(locale, { weekday: 'long' });
  } else {
    return `${date.toLocaleDateString(locale, { month: 'short' })} ${date.getDate()}`;
  }
};
