/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Component, type ErrorInfo } from 'react';
import { useRouter } from 'next/router';
import { Label } from '@/features/i18n';
import { Header } from '@/features/header/components/Header';
import logger from '../../utils/logger';
import environmentConfig from '../../config/environment';
import { actionLogger } from '../../utils/actionLogger';
import styles from './styles.module.scss';
import { Footer } from '@/features/footer/components/Footer';
import NextImage from '../NextImage';

type Props = {
  children: JSX.Element;
  userAuthenticated?: boolean;
  path: string;
  prodFallbackComponent: JSX.Element;
};

type State = {
  hasError: boolean;
  error: unknown;
  errorInfo: ErrorInfo | null;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.path !== this.props.path) {
      this.setState({
        hasError: false,
      });
    }
  }

  componentDidCatch(error: unknown, info: ErrorInfo) {
    logger.warn({
      message: 'Error in app ErrorBoundary',
      meta: {
        url: __IS_SERVER__ ? undefined : window.location.href,
        error,
        info,
        reduxActions: actionLogger.printLIFO(),
        isClient: !__IS_SERVER__,
      },
    });
    this.setState({
      hasError: true,
      error,
      errorInfo: info,
    });
  }

  render() {
    const nodeEnv = environmentConfig.NODE_ENV || 'production';
    const isProduction = nodeEnv === 'production';

    if (this.state.hasError) {
      if (!isProduction) {
        // eslint-disable-next-line no-console
        console.error('State Error', this.state.error, this.state.errorInfo);
      }

      return (
        <div className={styles.errorOuter}>
          <Header />
          <div className={styles.boundaryWrapper}>
            {isProduction ? (
              this.props.prodFallbackComponent
            ) : (
              <DevelopmentFallback error={this.state.error} errorInfo={this.state.errorInfo} />
            )}
          </div>
          <Footer />
        </div>
      );
    }

    return this.props.children;
  }
}

const ErrorBoundaryWrapper = ({ children }: { children: any }) => {
  const router = useRouter();
  return (
    <ErrorBoundary path={router.asPath} prodFallbackComponent={<ProductionFallback />}>
      {children}
    </ErrorBoundary>
  );
};

const ProductionFallback = () => {
  return (
    <p className={styles.errorParagraph}>
      <div className={styles.engineImage}>
        <NextImage
          src="/images/icon-oops.svg"
          alt="Error loading component"
          width={137}
          height={88}
        />
      </div>
      <Label label="label_error_somethingNotworking" />
    </p>
  );
};

const DevelopmentFallback = ({
  error,
  errorInfo,
}: {
  error: unknown;
  errorInfo: ErrorInfo | null;
}) => {
  return (
    <>
      <div className={styles.errorHeader}>
        <Label label="label_error_somethingNotworking" />
      </div>
      <div className={styles.errorMessage}>{error?.toString()}</div>
      <div className={styles.acknowledge}>(See the error stack information below to debug...)</div>
      <div className={styles.errorContent}>
        <pre className={styles.errorPre}>{errorInfo?.componentStack}</pre>
      </div>
    </>
  );
};

export default ErrorBoundaryWrapper;
