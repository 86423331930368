/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import azCommonStyles from '@/theme/globals.module.scss';
import { useLabel } from '@/hooks/useLabels';
import { routePaths } from '@/constants/routePaths';
import { useRouter } from 'next/router';
import { SmartLink as Link } from '@/utils/smartLink';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { type ReduxState } from '@/types';
import { useHeaderData } from '../../header/api/getHeader';
import { formatPrice } from '@/utils/validator/formatPrice';

type CartProps = {
  dataTestIdCartLink: string;
  isFixedHeader?: boolean;
};

const cartIcon = {
  src: '/images/az-cart-nav.svg',
};

const ninetyNinePlus = '99+';

export const Cart = ({ dataTestIdCartLink, isFixedHeader = false }: CartProps) => {
  const router = useRouter();
  const labelCart = useLabel('label_cart_topNavigation_Cart');
  const lblAriaRoleCart = useLabel('label_ariaRole_topNavigation_Cart');

  const orderSummary = useSelector((state: ReduxState) => state.orders.details.orderSummary);
  const { data: headerData } = useHeaderData();
  const miniCartMap = headerData?.miniCartMap;
  const itemCount = miniCartMap?.itemCount ?? 0;
  const overNinetyNine = itemCount > 99;

  const price = orderSummary?.itemTotalPrice
    ? orderSummary.itemTotalPrice
    : miniCartMap?.orderSubTotal;

  const labelPrice =
    price && !(router.asPath === routePaths.orderConfirmation)
      ? `$${formatPrice(price)}`
      : labelCart;

  const cartCounterBadge =
    itemCount === 0 ? (
      ''
    ) : (
      <span
        data-testid="azItemBadgeCount"
        aria-hidden
        className={cx({
          [styles.badgeItemCount]: itemCount,
          [styles.azCaptionOverNinetyNine]: overNinetyNine,
          [azCommonStyles['az-caption']]: !overNinetyNine,
          [styles.fixedBadgeItemCount]: isFixedHeader,
        })}
      >
        {overNinetyNine ? ninetyNinePlus : itemCount}
      </span>
    );

  const cartCounterPrice = (
    <div
      aria-hidden={labelPrice === labelCart}
      className={cx(azCommonStyles['az-caption'], styles.cartButtonLabel, {
        [styles.cartButtonLabelSticky]: isFixedHeader,
      })}
    >
      {labelPrice}
    </div>
  );

  const cartDescription =
    itemCount === 1
      ? lblAriaRoleCart.replace('x', '1').slice(0, -1)
      : lblAriaRoleCart.replace('x', String(itemCount));

  return (
    <Link
      className={cx(styles.cartIconLink, {
        [styles.cartIconLinkSticky]: isFixedHeader,
      })}
      to={routePaths.cart}
      data-testid={dataTestIdCartLink}
      aria-label={cartDescription}
      rel="nofollow"
    >
      <img
        className={styles.cartIcon}
        src={cartIcon.src}
        alt={cartDescription}
        data-testid="cart-icon"
        width={20}
        height={20}
        decoding="async"
      />
      {cartCounterBadge}
      {cartCounterPrice}
    </Link>
  );
};
