/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxios } from '@/lib/axios';
import { type AxiosError, type AxiosInstance } from 'axios';
import { requestBaseURL } from '@/config/serviceAPI';
import type { CustomerLookupResponse, ErrorData } from '@/types';
import { useMutation } from '@tanstack/react-query';
import { stringifyUrl } from '@/utils/urlHelpers';
import { type CustomerLookupFields } from '../interface';

const URL = `${requestBaseURL}/ecomm/b2c/customer/v1/search`;

type CustomerLookupOptions = {
  customerData: CustomerLookupFields;
  maxResult?: number;
  pageNumber?: number;
};

const postCustomerLookup = async (
  options: CustomerLookupOptions,
  axiosInstance?: AxiosInstance
) => {
  const { customerData, ...params } = options;

  const urlWithParams = stringifyUrl({ url: URL, query: params }, { skipNull: true });

  const response = await getAxios(axiosInstance).post<CustomerLookupResponse>(
    urlWithParams,
    customerData
  );

  return response.data;
};

type CustomerLookupMutationProps = {
  onSuccess?: (data: CustomerLookupResponse) => void;
  onError?: (error: AxiosError<ErrorData>, values: CustomerLookupOptions) => void;
};

export const useCustomerLookupMutation = ({
  onSuccess,
  onError,
}: CustomerLookupMutationProps = {}) => {
  return useMutation({
    mutationFn: postCustomerLookup,
    onSuccess: async (data) => {
      onSuccess?.(data);
    },
    onError: (error: AxiosError<ErrorData>, values) => {
      onError?.(error, values);
    },
  });
};
