/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Button } from '@/components/Button';
import { Grid } from '@/components/Grid';
import Hidden from '@/components/Hidden';
import NextImage from '@/components/NextImage';
import { useHeaderData } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { Label } from '@/features/i18n';
import { useLabel } from '@/hooks/useLabels';
import azCommonStyles from '@/theme/globals.module.scss';
import cx from 'classnames';
import { useContext } from 'react';
import { setInteractionLocation } from '../utils/ymmeLocationHelper';
import styles from './YMMEContentBlock.module.scss';
import { trackShopWithoutVehicle } from '@/utils/analytics/track/trackShopWithoutVehicle';
import { SnackBarContext } from '@/components/SnackBarContext';

type Props = {
  pageType: string;
  removeDefaultVehicle: () => void;
  showYmmeWidget: () => void;
};

const HomePagePreferredVehicle = ({ removeDefaultVehicle, showYmmeWidget, pageType }: Props) => {
  const { showSnackBar } = useContext(SnackBarContext);
  const { data: headerData } = useHeaderData();
  const vehicle = getPreferredVehicle(headerData);
  const yourLabel = useLabel('label_MyAccountMyProfile_landing_RewardYour');
  const hasBeenMovedLabel = useLabel('label_YMME_Body_HasBeenMovedTo');

  const handleShopWithoutVehicle = () => {
    setInteractionLocation(pageType);
    removeDefaultVehicle();
    const snackMessage = vehicle?.vehicleName
      ? `${yourLabel} ${vehicle.vehicleName} ${hasBeenMovedLabel}`
      : '';
    showSnackBar(snackMessage);
    trackShopWithoutVehicle();
  };
  const vehicleImage =
    vehicle && !!vehicle.vehicleImageURL ? (
      <div className={styles.vehicleImage}>
        <NextImage src={vehicle.vehicleImageURL} alt="" height={28} width={60} />
      </div>
    ) : (
      <div className={styles.preferedvehicleIcon}>
        <NextImage src="/images/vehicle/orange.svg" alt="" height={32} width={36} />
      </div>
    );

  const vehicleName = vehicle ? (
    vehicle.vehicleName
  ) : (
    <Label label="label_YMME_Body_NoVehicleSelected" data-testid="no-vehicle-selected" />
  );

  const selectedVehicleStyle = vehicle ? styles.vehicleSelected : '';

  return (
    <Grid container>
      <Grid className={selectedVehicleStyle} item xs={12}>
        <Grid item xs={12} md={6} lg={4} className={styles.vehicleGridItem}>
          <div className={styles.vehicleInfoCard}>
            <h2
              className={cx(
                azCommonStyles['az-title-5-heavy'],
                styles.ymmeTitleColor,
                styles.ymmeLabel
              )}
              data-testid="ymme-title"
            >
              <Label upper label="label_ContentBlock_YMME_CurrentlyShoppingFor" />
            </h2>
            <Button
              variant="ghost"
              onClick={() => showYmmeWidget()}
              customClass={styles.ymmeVehicleSelection}
            >
              <div className={styles.noPreferedvehicleIcon}>
                <NextImage
                  src="/images/vehicle/light-gray.svg"
                  alt={vehicle ? `Preferred vehicle is ${vehicle.vehicleName}` : ''}
                  height={32}
                  width={32}
                />
              </div>
              {vehicleImage}
              <div
                className={cx(
                  azCommonStyles['az-body-1-regular'],
                  styles.ymmeTitleColor,
                  styles.ymmeDesktopTitle
                )}
                data-testid="ymme-card-vehicle-name"
              >
                {vehicleName}
              </div>
            </Button>
          </div>
        </Grid>
        <Hidden implementation="js" smDown>
          <Grid item md={5}>
            <div
              className={cx(
                azCommonStyles['az-body-1-regular'],
                styles.ymmeTitleColor,
                styles.ymmeVehicleSet
              )}
              data-testid="ymme-shopping-information"
            >
              <Label label="label_ContentBlock_Rewards_WhileShoppingTheRestOfTheSiteWeWillFilterAllResultsToOnlyShowProductsThatFitYourVehicle" />
            </div>
            <div
              className={cx(
                azCommonStyles['az-body-1-regular'],
                styles.ymmeTitleColor,
                styles.changeVehicleLink
              )}
            >
              <Button
                variant="ghost"
                onClick={() => {
                  showYmmeWidget();
                }}
                customClass={styles.changeVehicleButton}
                data-testid="change-vehicle-button"
              >
                <Label label="hyperlink_YMME_Body_ChangeVehicle" />
              </Button>
              <span>|</span>
              <Button
                id="shopWithoutVehicle"
                data-testid="shop-without-vehicle-button"
                variant="ghost"
                fullWidth={true}
                onClick={handleShopWithoutVehicle}
                customClass={styles.shopWithoutVehicle}
              >
                <Label label="hyperlink_YMME_Body_ShopWithoutVehicle" />
              </Button>
            </div>
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default HomePagePreferredVehicle;
