/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { rewardsConstants } from '@/constants/rewards';
import type { RewardsActivityLineItem } from '../../../interface';
import { useLabels } from '@/hooks/useLabels';
import { Balance } from '../Balance/Balance';
import { useRewardsActivity } from '../../../api/getRewardsActivity';
import { Card, View, Text, Divider } from '@az/starc-ui';
import styles from './Activity.module.scss';

type Props = {
  content: RewardsActivityLineItem;
};

const labelMap = {
  lblpostedOn: 'label_MyAccountMyProfile_orderhistory_PostedOn',
  lblinStoreReturn: 'label_MyAccountMyProfile_orderhistory_InStoreReturn',
  lblrewardsAdjustment: 'label_MyAccountMyProfile_orderhistory_RewardsAdjustment',
  lblcreditRequest: 'label_MyAccountMyProfile_orderhistory_RewardsCreditRequest',
  lblrewardsCredit: 'label_MyAccountMyProfile_orderhistory_RewardsCredit',
  lblcreditAdjustment: 'label_MyAccountMyProfile_orderhistory_CreditAdjustment',
};

export const Activity = ({ content }: Props) => {
  const labels = useLabels(labelMap);
  const isReturn = content.description === rewardsConstants.rewardsActivity.Return;
  const [adjustment, request] = content.orderType.split('-');
  const { data: rewardsData } = useRewardsActivity();
  const loyaltyAccount = rewardsData?.loyaltyAccount;

  return (
    <Card
      data-testid="rewards_activity_rewardearndate"
      hasHoverEffect={false}
      className={styles.card}
    >
      <View padding={{ s: [5, 4], m: 4, l: [6, 5] }} direction="column">
        <View direction="row" justify="space-between" align="center">
          <Text className={styles.headerText} variant="small-body">
            {labels.lblpostedOn}{' '}
            {(content.rewardsActivityLineItemDetail.rewardEarnDate ?? '').split(' ')[0]}
          </Text>
          <Text className={styles.headerText} variant="small-body">
            #{content.transactionNumber}
          </Text>
        </View>
        <Divider className={styles.divider} />
        <View direction="column">
          <View direction="row" align="center" justify="space-between">
            <Text className={styles.headerText} variant="small-body">
              {isReturn
                ? labels.lblinStoreReturn
                : content.description === rewardsConstants.adjustment
                ? labels.lblrewardsAdjustment
                : adjustment}
            </Text>
            <Balance activity={content.creditActivity} isReturn={isReturn} credit />
          </View>
          <View direction="row" align="center" justify="space-between">
            {!isReturn ? (
              <Text className={styles.headerText} variant="small-body">
                {content.description === rewardsConstants.adjustment
                  ? labels.lblcreditRequest
                  : request}
              </Text>
            ) : (
              <View />
            )}
            {loyaltyAccount && (
              <>
                {content.rewardActivity && !isReturn ? (
                  <Balance activity={content.rewardActivity} />
                ) : null}
              </>
            )}
          </View>
        </View>
      </View>
    </Card>
  );
};
