/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { createContext, useState, type ReactNode, useMemo } from 'react';

type SearchBoxContextType = {
  setSearchBoxOpen: (value: boolean) => void;
  searchBoxOpen: boolean;
};

export const SearchBoxContext = createContext<SearchBoxContextType>({} as SearchBoxContextType);

type SearchBoxProviderProps = {
  children: ReactNode;
};

export const SearchBoxProvider = ({ children }: SearchBoxProviderProps) => {
  const [searchBoxOpen, setSearchBoxOpen] = useState(false);

  const value = useMemo(
    () => ({ searchBoxOpen, setSearchBoxOpen }),
    [searchBoxOpen, setSearchBoxOpen]
  );

  return <SearchBoxContext.Provider value={value}>{children}</SearchBoxContext.Provider>;
};
