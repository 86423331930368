/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import styles from './styles.module.scss';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Grid } from '@/components/Grid';
import { type ContentStackPrefooter } from '../../interface/content-types';
import { DesktopLinkColumn } from './DesktopLinkColumn';
import { MobileLinkColumn } from './MobileLinkColumn';

type Props = {
  content: ContentStackPrefooter['column'];
};

export function CMSPreFooter({ content }: Props) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  if (!content?.length) {
    return null;
  }
  return (
    <section className={styles.preFooterContainer}>
      <div className={styles.preFooterInnerContainer}>
        {content.map((column, index) => {
          return (
            <Grid item container lg={3} md={6} key={`linkColumContainer_${index}`}>
              {isMobile ? (
                <div className={styles.mobileLinkContainer}>
                  <MobileLinkColumn key={`linkColumn-${index}`} column={column} />
                </div>
              ) : (
                <DesktopLinkColumn key={`linkColumn-${index}`} column={column} />
              )}
            </Grid>
          );
        })}
      </div>
    </section>
  );
}
