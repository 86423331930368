/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Grid } from '@/components/Grid';
import Image from '@/components/NextImage';
import { downArrow } from '@/constants/images/downArrow';
import { greyCarImg } from '@/constants/images/greyCarImg';
import { useLabel } from '@/hooks/useLabels';
import azCommonStyles from '@/theme/globals.module.scss';
import cx from 'classnames';
import React, { useMemo } from 'react';
import { type YmmeDropdownData } from '../../interface';
import styleYmme from '../YMMEContentBlock.module.scss';
import AutoCompleteComponent from './AutoComplete';
import { type UseQueryResult } from '@tanstack/react-query';
import { useFeatureFlag } from '@/features/globalConfig';
import { type Item } from './types';

type RenderProps = {
  dataSource: Array<UseQueryResult<YmmeDropdownData>>;
  focusEngine: boolean;
  focusMake: boolean;
  focusModel: boolean;
  focusYear: boolean;
  pageType: string;
  selectedEngine: string | undefined;
  selectedMake: string | undefined;
  selectedModel: string | undefined;
  selectedYear: string | undefined;
  selectEngine: (a: string) => void;
  selectLabels?: {
    first: string;
    second: string;
    third: string;
    fourth: string;
  };
  forMMYE?: boolean;
  selectMake: (a: string) => void;
  selectModel: (a: string) => void;
  selectYear: (a: string) => void;
  showYmmeMobile?: () => void;
  resetAll?: () => void;
  forceMobile?: boolean;
  enablePrefilledData?: boolean;
  resetFocus: () => void;
};

const labelMap = {
  lblYear: 'label_YMME_Body_Year',
  lblMake: 'label_YMME_Body_Make',
  lblModel: 'label_YMME_Body_Model',
  lblEngine: 'label_YMME_Body_Engine',
  lblAddvehicle: 'hyperlink_ShelfPage_body_AddAVehicle',
  lblPopularMakes: 'label_popular_makes',
  lblAllMakes: 'label_All_Makes',
};

const MobileToggle = ({ showYmmeMobile }: { showYmmeMobile?: React.MouseEventHandler }) => {
  const addVehicleLabel = useLabel(labelMap.lblAddvehicle);

  return (
    <div
      id="toggleYMMEMobile"
      role="presentation"
      className={`${styleYmme.addVechiclePart} ${styleYmme.link}`}
      onClick={showYmmeMobile}
    >
      <Image
        className={styleYmme.carIcon}
        src={greyCarImg.src}
        alt={greyCarImg.alt}
        width={18}
        height={14}
      />
      <span className={azCommonStyles['az-margin-right-4xs']}>{addVehicleLabel}</span>
      <Image
        className={styleYmme.downIcon}
        src={downArrow.src}
        alt={downArrow.alt}
        width={13}
        height={13}
      />
    </div>
  );
};

const YmmeSelectRender = ({
  dataSource,
  pageType,
  selectedEngine,
  selectedMake,
  selectedModel,
  selectedYear,
  selectEngine,
  selectLabels,
  selectMake,
  selectModel,
  selectYear,
  showYmmeMobile,
  focusYear,
  focusMake,
  focusModel,
  focusEngine,
  resetAll,
  forMMYE = false,
  forceMobile = false,
  enablePrefilledData,
  resetFocus,
}: RenderProps) => {
  const makeQuery = dataSource[1];
  const modelQuery = dataSource[2];
  const disableMake =
    !makeQuery.isLoading && enablePrefilledData
      ? (!makeQuery.data?.length && !selectedMake) || !selectedYear
      : !selectedYear;
  const disableModel =
    !modelQuery.isLoading && enablePrefilledData
      ? (!modelQuery.data?.length && !selectedModel) || !selectedYear
      : !selectedMake || !selectedYear;
  const disableEngine = !selectedYear || !selectedMake || !selectedModel;
  const engineLabel = useLabel(labelMap.lblEngine);
  const makeLabel = useLabel(labelMap.lblMake);
  const modelLabel = useLabel(labelMap.lblModel);
  const yearLabel = useLabel(labelMap.lblYear);
  const popularMakesLabel = useLabel(labelMap.lblPopularMakes);
  const allMakesLabel = useLabel(labelMap.lblAllMakes);
  const popularMakes = useFeatureFlag('POPULAR_MAKES') || '';

  const makeDataSrc = useMemo(() => {
    const popularMakesArray = popularMakes
      .split(' ')
      .map((item) => item.trim())
      .slice(0, 10);

    const dataSrcAllMake =
      makeQuery.data ?? (selectedMake ? [{ value: selectedMake, label: selectedMake }] : []);

    const allSet = new Set(dataSrcAllMake.map((brand) => brand.label));
    const filteredPopularMakes = popularMakesArray.reduce<Item[]>((acc, brand) => {
      if (allSet.has(brand)) {
        acc.push({ value: brand, label: brand, isPopular: true });
      }
      return acc;
    }, []);

    const makeData =
      filteredPopularMakes.length === 0
        ? dataSrcAllMake
        : [
            {
              value: 'popularMakesLabel',
              label: popularMakesLabel,
              isLabel: true,
            },
            ...filteredPopularMakes,
            { value: 'allMakesLabel', label: allMakesLabel, isLabel: true },
            ...dataSrcAllMake,
          ];
    return makeData;
  }, [allMakesLabel, makeQuery.data, popularMakes, popularMakesLabel, selectedMake]);

  return (
    <>
      <div
        role="form"
        className={cx(styleYmme.selectSection, {
          [styleYmme.forceMobile]: forceMobile,
        })}
        data-testid="ymme-component"
      >
        <div
          className={cx(styleYmme.ymmeRow, {
            [styleYmme.forceMobile]: forceMobile,
          })}
        >
          <Grid container spacing={0} className={styleYmme.ymmeRegion}>
            <div
              className={cx(styleYmme.ymmeSelectBox, {
                [styleYmme.makeSelect]: forMMYE,
                [styleYmme.yearSelect]: !forMMYE,
                [styleYmme.ymmeSelectBoxFocused]: focusYear,
                [styleYmme.ymmeSelectBoxdesktopStyles]: !forceMobile,
              })}
            >
              <AutoCompleteComponent
                list={dataSource[0]?.data || []}
                disabled={false}
                selectedItem={selectedYear}
                handleClick={selectYear}
                maxLength={4}
                label={selectLabels?.first || yearLabel}
                labelNumber={!enablePrefilledData ? '1' : ''}
                focusElement={focusYear}
                name={'year'}
                id={'year' + pageType}
                resetAll={resetAll}
                resetFocus={resetFocus}
              />
            </div>
          </Grid>
          <Grid container spacing={0} className={styleYmme.ymmeRegion}>
            <div
              className={cx(styleYmme.ymmeSelectBox, {
                [styleYmme.modelSelect]: forMMYE,
                [styleYmme.makeSelect]: !forMMYE,
                [styleYmme.ymmeSelectBoxFocused]: focusMake && !disableMake,
                [styleYmme.ymmeSelectBoxdesktopStyles]: !forceMobile,
              })}
            >
              <AutoCompleteComponent
                list={makeDataSrc}
                handleClick={selectMake}
                disabled={disableMake}
                selectedItem={selectedMake}
                maxLength={100}
                label={selectLabels?.second || makeLabel}
                labelNumber={!enablePrefilledData ? '2' : ''}
                focusElement={focusMake}
                name={'make'}
                id={'make' + pageType}
                resetFocus={resetFocus}
              />
            </div>
          </Grid>
          <Grid container spacing={0} className={styleYmme.ymmeRegion}>
            <div
              className={cx(styleYmme.ymmeSelectBox, {
                [styleYmme.yearSelect]: forMMYE,
                [styleYmme.modelSelect]: !forMMYE,
                [styleYmme.ymmeSelectBoxFocused]: focusModel && !disableModel,
                [styleYmme.ymmeSelectBoxdesktopStyles]: !forceMobile,
              })}
            >
              <AutoCompleteComponent
                list={
                  modelQuery.data ??
                  (selectedModel ? [{ value: selectedModel, label: selectedModel }] : [])
                }
                disabled={disableModel}
                selectedItem={selectedModel}
                handleClick={selectModel}
                maxLength={100}
                label={selectLabels?.third || modelLabel}
                labelNumber={!enablePrefilledData ? '3' : ''}
                focusElement={focusModel}
                name={'model'}
                id={'model' + pageType}
                resetFocus={resetFocus}
              />
            </div>
          </Grid>
          <Grid container spacing={0} className={styleYmme.ymmeRegion}>
            <div
              className={cx(styleYmme.ymmeSelectBox, styleYmme.engineSelect, {
                [styleYmme.ymmeSelectBoxFocused]: focusEngine && !disableEngine,
                [styleYmme.ymmeSelectBoxdesktopStyles]: !forceMobile,
              })}
            >
              <AutoCompleteComponent
                list={dataSource[3]?.data || []}
                disabled={disableEngine}
                handleClick={selectEngine}
                selectedItem={selectedEngine}
                maxLength={100}
                label={selectLabels?.fourth || engineLabel}
                labelNumber={!enablePrefilledData ? '4' : ''}
                focusElement={focusEngine}
                name={'engine'}
                id={'engine' + pageType}
                resetFocus={resetFocus}
              />
            </div>
          </Grid>
        </div>
      </div>
      <div className={styleYmme.mobileToggle}>
        <MobileToggle showYmmeMobile={showYmmeMobile} />
      </div>
    </>
  );
};

export default YmmeSelectRender;
