/**
 * Copyright 2019-2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { AxiosInstance } from 'axios';
import actionType from '../types/action';
import type { Dispatch, GetState, ThunkAction } from '../types';
import environmentConfig from '../../src/config/environment';
import { API_URLS } from '../constants/apiUrls';
import logger from '../../src/utils/logger';

export const setHeaderStatus = (
  status: boolean
): {
  status: boolean;
  type: string;
} => ({
  type: actionType.SET_HEADER_STATE,
  status,
});

export const setSecondaryHeaderFooterStatus = (
  status: boolean
): {
  status: boolean;
  type: string;
} => ({
  type: actionType.SET_SECONDARY_HEADER_FOOTER_STATE,
  status,
});

export const setFooterStatus = (
  status: boolean
): {
  status: boolean;
  type: string;
} => ({
  type: actionType.SET_FOOTER_STATE,
  status,
});

export const setChatAgentStatus =
  ({
    isNewChatVendorEnabled,
    NEW_CHAT_AVAILABLE_ENDPOINT,
  }: {
    isNewChatVendorEnabled: boolean;
    NEW_CHAT_AVAILABLE_ENDPOINT: string;
  }): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: AxiosInstance) => {
    try {
      dispatch({
        type: actionType.SET_CHAT_AGENT_STATUS,
        status: null,
        requestStatus: 'loading',
      });

      let vendorResponse;
      if (isNewChatVendorEnabled) {
        vendorResponse = await axios.get(NEW_CHAT_AVAILABLE_ENDPOINT, {
          withCredentials: false,
        });
      } else {
        vendorResponse = await axios.get(
          `${environmentConfig.PUBLIC_GATEWAY_URL}${API_URLS.GENESYS_CHAT_AGENTS_AVAILABILITY}`
        );
      }

      const regex = /<ns2:count>(\d+)<\/ns2:count>/;
      const match = vendorResponse?.data?.match(regex);
      const chatAgentsAreAvailable = Boolean(match && match.length > 1 && match[1] > 0);

      dispatch({
        type: actionType.SET_CHAT_AGENT_STATUS,
        status: chatAgentsAreAvailable,
        requestStatus: 'success',
      });
    } catch (e) {
      logger.warn({
        message: 'Error in chat availability call',
        meta: e,
      });
      dispatch({
        type: actionType.SET_CHAT_AGENT_STATUS,
        status: false,
        requestStatus: 'error',
      });
    }
  };

export const setAuthState = (
  sessionAuthState: boolean
): {
  sessionAuthState: boolean;
  type: string;
} => ({
  type: actionType.UPDATE_AUTHENTICATION,
  sessionAuthState,
});

export const setAppBannerShown = (
  appBannerShown: boolean
): {
  appBannerShown: boolean;
  type: string;
} => ({
  type: actionType.SET_APP_BANNER_SHOWN,
  appBannerShown,
});

export const setFetchingLocationStatus = (
  status: boolean
): {
  status: boolean;
  type: string;
} => ({
  type: actionType.SET_FETCHING_LOCATION_STATUS,
  status,
});

export const setCanShowToastNotification = (
  canShowToastNotification: boolean
): {
  canShowToastNotification: boolean;
  type: string;
} => ({
  type: actionType.SET_CAN_SHOW_TOAST_NOTIFICATION,
  canShowToastNotification,
});

export const setIsAutofill = (
  isAutofill: boolean
): {
  isAutofill: boolean;
  type: string;
} => ({
  type: actionType.SET_IS_AUTOFILL,
  isAutofill,
});
