/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { View } from '@az/starc-ui';
import React from 'react';
import { CMSHeadline } from '../CMSHeadline/CMSHeadline';
import { type ContentStackHeading } from '../../interface';
import styles from './CMSHeading.module.scss';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';

type Props = {
  content: ContentStackHeading;
};

export const CMSHeading = ({ content }: Props) => {
  const { heading, heading_underline } = content;
  const { asPath } = useRouter();
  const isMXorBRCircularPage =
    asPath === routePaths.landingPageCircularMX || asPath === routePaths.landingPageCircularBR;
  return (
    <View className={cx({ [styles.circularPage]: isMXorBRCircularPage })}>
      <CMSHeadline noGutter className={styles.headline} underline={heading_underline}>
        {heading}
      </CMSHeadline>
    </View>
  );
};
