/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useAppState } from '@/stores/AppState';
import { type ReduxState } from '@/types';
import logger from '@/utils/logger';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useGeolocation = () => {
  const [coordinates, setCoordinates] = useState<GeolocationCoordinates | null>(null);
  const deviceType = useSelector((state: ReduxState) => state.appData.deviceType);
  const { isMobileApp } = useAppState().state;

  useEffect(() => {
    if (deviceType === 'bot' || isMobileApp) {
      return;
    }

    const positionOptions: PositionOptions = {
      timeout: 5000,
      maximumAge: 5000,
    };

    const handleGeoSuccess: PositionCallback = (position) => {
      setCoordinates(position.coords);
    };

    const handleGeoFailure: PositionErrorCallback = (error) => {
      logger.log({ message: error?.message });
    };

    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(handleGeoSuccess, handleGeoFailure, positionOptions);
    }
  }, [deviceType, isMobileApp]);

  return coordinates;
};
