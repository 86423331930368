/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type AnyAction } from 'redux';
import type { AddressDetails } from '../../types';
import addressActionTypes from '../../types/action';

export const initialState: AddressDetails = {
  addressReadyStatus: addressActionTypes.ADDRESS_DETAILS_INVALID,
  err: null,
  errorCode: null,
  shippingAddress: {} as {},
  billingAddress: {} as {},
  profileAddress: {} as {},
  enteredAddress: {} as {},
  shippingType: 'savedAddress',
  billingType: 'savedAddress',
  profileAndBillingAddressDifferent: false,
  shippingAndBillingAddressDifferent: false,
  profileAndShippingAddressDifferent: false,
  billingAddressAvailable: false,
  profileAddressAvailable: false,
  shippingAddressAvailable: false,
  shippingAddressValid: true,
  containsShipToHomeItem: false,
  fedExDetails: {} as {},
  fedExStatus: addressActionTypes.FEDX_VALIDATION_INVALID,
  billingAddressStatus: addressActionTypes.BILLING_ADDRESSS_INVALID,
  fedExError: '',
  fedExSuccessFlag: false,
  shippingFormDefault: false,
  billingFormDefault: false,
  shouldSaveAddressToProfile: false,
  shippingAddressValidation: false,
  readyStatus: '',
  bvalue: false,
  formValueAdded: false,
  email: '',
  phoneNumber: '',
};

const addressDetails = (
  state: AddressDetails = initialState,
  action: AnyAction
): AddressDetails => {
  switch (action.type) {
    case addressActionTypes.INITIAL_ADDRESS_DETAILS_REQUESTING:
      return {
        ...state,
        addressReadyStatus: addressActionTypes.INITIAL_ADDRESS_DETAILS_REQUESTING,
        fedExSuccessFlag: action.fedExSuccessFlag,
        shippingFormDefault: false,
        formValueAdded: false,
      };

    case addressActionTypes.INITIAL_ADDRESS_DETAILS_SUCCESS:
      return {
        ...state,
        addressReadyStatus: addressActionTypes.INITIAL_ADDRESS_DETAILS_SUCCESS,
        shippingAddress: action.data.shippingAddress,
        billingAddress: action.data.billingAddress,
        profileAddress: action.data.profileAddress,
        profileAndBillingAddressDifferent: action.data.profileAndBillingAddressDifferent,
        shippingAndBillingAddressDifferent: action.data.shippingAndBillingAddressDifferent,
        profileAndShippingAddressDifferent: action.data.profileAndShippingAddressDifferent,
        billingAddressAvailable: action.data.billingAddressAvailable,
        profileAddressAvailable: action.data.profileAddressAvailable,
        shippingAddressAvailable: action.data.shippingAddressAvailable,
        shippingAddressValid: action.data.shippingAddressValid,
        shippingFormDefault:
          !action.data.shippingAddressAvailable || !action.data.shippingAddressValid,
        containsShipToHomeItem: action.data.containsShipToHomeItem,
        fedExSuccessFlag: action.fedExSuccessFlag,
        fedExError: '',
        isAddressError: false,
        fedExDetails: action.data.fedExDetails,
      };

    case addressActionTypes.UPDATE_SHIPPING_ADDRESS_AVAILABLE:
      return {
        ...state,
        shippingAddressAvailable: action.shippingAddressAvailable,
        shippingAddressValid: action.shippingAddressValid,
      };

    case addressActionTypes.UPDATE_PROFILE_ADDRESS:
      return { ...state, profileAddress: action.profileAddress };

    case addressActionTypes.INITIAL_ADDRESS_DETAILS_FAILURE:
      return {
        ...state,
        addressReadyStatus: addressActionTypes.INITIAL_ADDRESS_DETAILS_FAILURE,
        err: action.err,
      };

    case addressActionTypes.SAVE_ADDRESS_TO_PROFILE:
      return {
        ...state,
        readyStatus: addressActionTypes.ADDRESS_SUCCESS,
        shouldSaveAddressToProfile: true,
        profileAndShippingAddressDifferent: false,
        profileAddressAvailable: true,
        fedExDetails: {
          ...(state.fedExDetails ?? {}),
          shouldSaveAddressToProfile: true,
          profileAndShippingAddressDifferent: false,
          profileAddressAvailable: true,
        },
      };

    case addressActionTypes.FEDX_VALIDATION_NOT_REQUIRED:
      return {
        ...state,
        fedExStatus: addressActionTypes.FEDX_VALIDATION_SUCCESS,
        shippingAddressValidation: action.shippingAddressValidation,
      };

    case addressActionTypes.FEDX_VALIDATION_REQUESTING:
      return {
        ...state,
        fedExStatus: addressActionTypes.FEDX_VALIDATION_REQUESTING,
        fedExError: '',
        errorCode: '',
        isAddressError: false,
      };

    case addressActionTypes.FEDX_VALIDATION_SUCCESS: {
      return {
        ...state,
        fedExStatus: addressActionTypes.FEDX_VALIDATION_SUCCESS,
        fedExDetails: action.data,
        shippingAddress: action.data.shippingAddress,
        billingAddress: action.data.billingAddress,
        enteredAddress: action.enteredAddress,
        profileAddressAvailable: action.data.profileAddressAvailable,
        billingFormDefault: false,
        shippingFormDefault: false,
        isAddressError: false,
        fedExError: '',
        shippingAddressValidation: action.shippingAddressValidation,
        billingAddressStatus: '',
        err: null,
      };
    }

    case addressActionTypes.FEDX_VALIDATION_INVALID:
      return {
        ...state,
        fedExStatus: addressActionTypes.FEDX_VALIDATION_INVALID,
        shippingFormDefault: false,
      };

    case addressActionTypes.FEDX_VALIDATION_FAILURE: {
      return {
        ...state,
        fedExStatus: addressActionTypes.FEDX_VALIDATION_FAILURE,
        fedExError:
          action.err?.errorDetails?.[0].errorMessageDefault || action.err?.errorMessageDefault,
        shippingFormDefault: true,
        isAddressError: true,
        bilingValidationType: action.bilingValidationType,
        enteredAddress: action.enteredAddress,
        billingAddressStatus: '',
        err: null,
        errorCode: action.err?.errorDetails?.[0].errorCode || action.err?.errorCode,
      };
    }

    case addressActionTypes.BILLING_ADDRESSS_FAILURE: {
      return {
        ...state,
        billingAddressStatus: addressActionTypes.BILLING_ADDRESSS_FAILURE,
        enteredAddress: action.enteredAddress,
        err: action.err?.errorDetails?.[0].errorMessageDefault || action.err?.errorMessageDefault,
      };
    }

    case addressActionTypes.FEDX_VALIDATION_EDIT_NOT_FOUND_ADDRESS:
      return {
        ...state,
        fedExStatus: addressActionTypes.FEDX_VALIDATION_EDIT_NOT_FOUND_ADDRESS,
        fedExError: '',
        shippingFormDefault: true,
        isAddressError: false,
        bilingValidationType: action.bilingValidationType,
      };

    case addressActionTypes.FEDX_VALIDATION_NOT_FOUND_AND_WITHOUT_SUGGESTED_ADDRESS:
      return {
        ...state,
        fedExStatus: addressActionTypes.FEDX_VALIDATION_NOT_FOUND_AND_WITHOUT_SUGGESTED_ADDRESS,
        shippingFormDefault: true,
        isAddressError: false,
        bilingValidationType: action.bilingValidationType,
        enteredAddress: action.enteredAddress,
        warningMsg: action.warningMsg,
      };

    case addressActionTypes.SET_ADDRESS_STATE:
      return { ...state, readyStatus: addressActionTypes.ADDRESS_SUCCESS };

    case addressActionTypes.SET_BILLING_DEFAULT:
      return {
        ...state,
        billingStatus: addressActionTypes.SET_BILLING_DEFAULT,
        billingType: action.billingType,
      };

    case addressActionTypes.SET_SHIPPING_DEFAULT:
      return {
        ...state,
        shippingStatus: addressActionTypes.SET_SHIPPING_DEFAULT,
        shippingType: action.shippingType,
      };

    case addressActionTypes.SHOW_BILLING_FORM_DEFAULT:
      return { ...state, billingFormDefault: action.bolValue };

    case addressActionTypes.SHOW_SHIPPING_FORM_DEFAULT:
      return { ...state, shippingFormDefault: action.value };

    case addressActionTypes.BILLING_ADDRESS_INDICATOR_UPDATE:
      return {
        ...state,
        billingAddressIndicator: action.bvalue,
        billingAddressStatus: '',
        err: '',
      };

    case addressActionTypes.BILLING_ADDRESS_INITIALIZE:
      return {
        ...state,
        billingAddress: {},
      };

    case addressActionTypes.RESET_ENTERED_ADDRESS:
      return { ...state, isAddressError: false };

    case addressActionTypes.SET_EMAIL_AND_PHONE:
      return {
        ...state,
        email: action.email,
        phoneNumber: action.phoneNumber,
      };

    default:
      return state;
  }
};

export default addressDetails;
