/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxios } from '@/lib/axios';
import { type QueryClient, type QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { type AxiosInstance } from 'axios';
import type { LandingPageResponse, LandingPageData, LandingPageFallbackData } from '@/types/legacy';
import { useXMPreviewState } from '@/stores/XMPreviewState';
import { stringifyUrl } from '@/utils/urlHelpers';
import { requestBaseURL } from '@/config/serviceAPI';

type Options = {
  pageName: string | undefined;
  workspaceId: string | undefined;
  previewDate: string | undefined;
};

const URL = `${requestBaseURL}/ecomm/b2c/v1/xm/lp/`;

type MaybeXMQueries = {
  previewDate?: string;
  workspaceId?: string;
};

const getLandingPageData = async (
  { queryKey }: QueryFunctionContext<ReturnType<typeof getLandingPageDataKey>>,
  axiosInstance?: AxiosInstance
) => {
  const [, options] = queryKey;
  const { pageName, workspaceId, previewDate } = options;

  const cleanPageName = pageName?.replace('/:', ':');
  let landingPageUrl = `${URL}${cleanPageName ? cleanPageName : ''}`;
  const query: MaybeXMQueries = {};

  if (workspaceId) {
    query.workspaceId = workspaceId;
  }
  if (previewDate) {
    query.previewDate = previewDate;
  }
  landingPageUrl = stringifyUrl({
    url: `${URL}${cleanPageName ? cleanPageName : ''}`,
    query,
  });

  const response = await getAxios(axiosInstance).get<LandingPageResponse>(landingPageUrl);

  return selector(response.data);
};

const selector = (data: LandingPageResponse): LandingPageData | LandingPageFallbackData => {
  // TO DO this function can return 2 types of very different data
  // in order to manage this data correctly we're using a type predicate
  // https://www.typescriptlang.org/docs/handbook/advanced-types.html#using-type-predicates
  // this logic should be refactored
  let returnData;
  if (data.templateTypes && data.templateTypes[0] === 'Page') {
    returnData = data.contents[0];
  } else {
    returnData = data;
  }
  return returnData;
};

const getLandingPageDataKey = (options: Options) => ['landingPage', options] as const;

export const useGetLandingPageData = (pageName: string | undefined, enabled = false) => {
  const { workspaceId, previewDate } = useXMPreviewState();
  const options: Options = {
    pageName,
    workspaceId,
    previewDate,
  };
  return useQuery({
    queryKey: getLandingPageDataKey(options),
    queryFn: getLandingPageData,
    enabled: enabled && !!pageName,
  });
};

export const getLandingPageDataFromCache = (
  queryClient: QueryClient,
  pageName: string
): LandingPageData | undefined => {
  const options: Options = {
    pageName,
    workspaceId: undefined,
    previewDate: undefined,
  };
  return queryClient.getQueryData(getLandingPageDataKey(options));
};

export const prefetchLandingPageData = (
  queryClient: QueryClient,
  pageName: string | undefined,
  axiosInstance: AxiosInstance
) => {
  const options: Options = {
    pageName,
    workspaceId: undefined,
    previewDate: undefined,
  };
  return queryClient.prefetchQuery({
    queryKey: getLandingPageDataKey(options),
    queryFn: (context: QueryFunctionContext<ReturnType<typeof getLandingPageDataKey>>) =>
      getLandingPageData(context, axiosInstance),
  });
};
