/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export type WarrantyIcon = {
  alt: string;
  src: string;
};

export const getWarrantyBadge = (warranty: string): WarrantyIcon => {
  let iconNumber = '';

  if (warranty.match(/limited\Wlifetime/i) || warranty.match(/limitada de por vida/i)) {
    iconNumber = warranty.match(/limitada de por vida/i)
      ? 'limitada-de-por-vida'
      : 'Limited-Lifetime';
  } else {
    const iconWarrantyNumber = warranty.split('-')?.[0];
    iconNumber = isNaN(Number(iconWarrantyNumber)) ? warranty.split(' ')?.[0] : iconWarrantyNumber;
  }

  return {
    src: `/images/warranty/${iconNumber}.svg`,
    alt: warranty?.replace(/-/g, ' ').toLowerCase(),
  };
};
