/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type Dispatch, type ReactNode, type SetStateAction, createContext, useState } from 'react';

type Errors = {
  first_name: boolean;
  last_name: boolean;
  zip_code: boolean;
  phone_number: boolean;
  password: boolean;
};

type CreateAccountProviderType = {
  firstName: string;
  lastName: string;
  zipCode: string;
  phoneNumber: string;
  password: string;
  errors: Errors;
  rewardsId: string;
  maskedRewardsId: string;
  rewardsLinkingId: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  setLastName: Dispatch<SetStateAction<string>>;
  setZipCode: Dispatch<SetStateAction<string>>;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  setPassword: Dispatch<SetStateAction<string>>;
  setErrors: Dispatch<SetStateAction<Errors>>;
  setRewardsId: Dispatch<SetStateAction<string>>;
  setMaskedRewardsId: Dispatch<SetStateAction<string>>;
  setRewardsLinkingId: Dispatch<SetStateAction<string>>;
  reset: () => void;
};

export const CreateAccountContext = createContext<CreateAccountProviderType>(
  {} as CreateAccountProviderType
);

type AccountViewProviderProps = {
  children: ReactNode;
};

export function CreateAccountProvider({ children }: AccountViewProviderProps) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({
    first_name: false,
    last_name: false,
    zip_code: false,
    phone_number: false,
    password: false,
  });
  const [rewardsId, setRewardsId] = useState('');
  const [maskedRewardsId, setMaskedRewardsId] = useState('');
  const [rewardsLinkingId, setRewardsLinkingId] = useState('');

  function reset() {
    setFirstName('');
    setLastName('');
    setZipCode('');
    setPhoneNumber('');
    setRewardsId('');
    setMaskedRewardsId('');
    setRewardsLinkingId('');
  }

  return (
    <CreateAccountContext.Provider
      value={{
        firstName,
        lastName,
        zipCode,
        phoneNumber,
        password,
        errors,
        rewardsId,
        maskedRewardsId,
        rewardsLinkingId,
        setFirstName,
        setLastName,
        setZipCode,
        setPhoneNumber,
        setPassword,
        setErrors,
        setRewardsId,
        setMaskedRewardsId,
        setRewardsLinkingId,
        reset,
      }}
    >
      {children}
    </CreateAccountContext.Provider>
  );
}

export default CreateAccountProvider;
