/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React from 'react';
import { type ContentStackCategoryGrid } from '../../interface';
import { Grid } from '@/components/Grid';
import { CategoryGridItem } from './CategoryGridItem/CategoryGridItem';
import styles from './CMSCategoryGrid.module.scss';
import { CMSHeadline } from '../CMSHeadline/CMSHeadline';

type Props = {
  content: ContentStackCategoryGrid;
};

function chunk<T>(arr: T[] | undefined, size: number): T[][] | undefined {
  if (!arr) return;

  const result: T[][] = [];
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size));
  }

  return result;
}

export function CMSCategoryGrid({ content }: Props) {
  const { headline, tool_content: toolContent } = content;

  const rows = chunk(toolContent, 4) ?? [];

  return (
    <Grid container spacing={0}>
      <CMSHeadline>{headline}</CMSHeadline>
      <Grid container spacing={0}>
        {rows.map((row, i) => (
          <Grid container spacing={0} key={i} className={styles.row}>
            {row.map(({ image, link: links }, i) => (
              <CategoryGridItem key={i} image={image} links={links} />
            ))}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
