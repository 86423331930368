/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { API_URLS } from '@/constants/apiUrls';
import { getAxios } from '@/lib/axios';
import { createQuery } from '@/utils/createReactQuery';
import { type AxiosInstance } from 'axios';
import { type CertonaPricing } from '@/types';

type Options = {
  skuNumbers?: string[];
  storeNumber?: string;
};

const URL = `${requestBaseURL}${API_URLS.CERTONA_PRICING}`;

const fetchProductSkuPrice = async (
  { skuNumbers, storeNumber }: Options,
  axiosInstance?: AxiosInstance
) => {
  if (!skuNumbers?.length || !skuNumbers) {
    throw new Error('skuNumbers are required');
  }
  const response = await getAxios(axiosInstance).get<CertonaPricing[]>(
    `${URL}${skuNumbers?.join(',') ?? ''}?storeNumber=${storeNumber ?? ''}`
  );

  return selector(response.data);
};

const selector = (data: CertonaPricing[]): Record<string, CertonaPricing> => {
  const result: Record<string, CertonaPricing> = {};

  data.forEach((part) => {
    result[part.skuId] = part;
  });

  return result;
};

const { useData: useGetProductSkuPrice, prefetch: prefetchGetProductSkuPrice } = createQuery(
  'certonaPricing',
  fetchProductSkuPrice
);

export { useGetProductSkuPrice, prefetchGetProductSkuPrice };
