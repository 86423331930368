/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { ContentStackFile, ContentStackImage, SizePerDevice } from '../interface';
import { type ImageSize } from '../interface/models';

type Props = {
  image?:
    | ContentStackImage
    | {
        image_alt_text: NonNullable<ContentStackImage['image_alt_text']>;

        image_url: ContentStackFile['url'];

        attributes: undefined;
      };
  sizePerDevice: SizePerDevice;
};

type Image = {
  src: string;
  width: ImageSize['width'];
  height: ImageSize['height'];
};

const useResponsiveImageData = ({ image, sizePerDevice }: Props) => {
  const imgSrcs = image?.attributes?.reduce<Record<string, Pick<Image, 'src'>>>(
    (acc, { device, device_image }) => {
      const deviceType = device?.toLowerCase() ?? '';
      return { ...acc, [deviceType]: { src: device_image ?? '' } };
    },
    {}
  );

  return Object.keys(sizePerDevice).reduce<Record<string, Image>>((acc, key) => {
    const src = imgSrcs?.[key]?.src ?? image?.image_url ?? '';
    return { ...acc, [key]: { ...sizePerDevice[key], src } };
  }, {});
};

export { useResponsiveImageData };
