/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { clickTrack } from '@/utils/analytics/clickTrack';
import { analyticsGlobalVariables } from '@/utils/analytics/dataLayer/analyticsGlobalVariables';
import { deleteCookie, setCookie } from '@/utils/cookie';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useSelector } from 'react-redux';
import root from 'window-or-global';
import { replaceHistoryStateOptionsOnClick } from '@/features/common/utils/replaceHistoryStateOptionsOnClick';
import { historyStateOptionAttributes } from '@/constants/historyStateOption';
import { type ReduxState } from '@/types';
import { sessionStorage } from '@/utils/sessionStorage';
import { useSetPreferredStoreMutation } from '../api/postPreferredStore';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useRouter } from 'next/router';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';

const trackSetStore = (selectedStoreId: string, searchedInput?: string) => {
  const nearbyStoreFlowType = String(sessionStorage.getItem('sister_store_update_type'));
  if (
    nearbyStoreFlowType === 'select_store_and_add_to_cart' ||
    nearbyStoreFlowType === 'select_store_and_update_cart'
  ) {
    return;
  }

  clickTrack({
    eventType: 'setStoreGlobalHeader',
    storeId: selectedStoreId,
    storeSearchTerm: searchedInput,
  });
};

export function useSetStore(
  closeStoreModal: () => void
): (
  storeNumber: string,
  zipCode: string,
  isNearByStoreSelected: boolean,
  searchedInput?: string
) => void {
  const { data: storeDetails } = useStoreDetailsData();
  const currentStore = storeDetails?.storeNumber ?? '';
  const userAuthenticated = useSelector((state: ReduxState) => state.appData?.userAuthenticated);
  const router = useRouter();
  const { refetch: refetchHeaderData } = useHeaderData();
  const { mutateAsync: setStore } = useSetPreferredStoreMutation();
  const isLoadMoreEnabled = useMonetateDecisionFlag('loadMoreEnabled');
  if (router.query.loadMore && isLoadMoreEnabled) {
    sessionStorage.setItem('previousStore', currentStore);
  }
  const dispatchSetStoreNumber = async (storeNumber: string) => {
    try {
      await setStore({ storeNumber });
    } catch {}
    refetchHeaderData();
    replaceHistoryStateOptionsOnClick(
      historyStateOptionAttributes.latestSelectedPageYOffset,
      root.pageYOffset
    );
  };

  return (
    storeNumber: string,
    zipCode: string,
    isNearByStoreSelected: boolean,
    searchedInput?: string
  ) => {
    dispatchSetStoreNumber(storeNumber);
    closeStoreModal();
    /* Analytics Start */

    trackSetStore(storeNumber, searchedInput);
    deleteCookie('preferredZipCode');
    deleteCookie('preferredStoreId');

    setCookie('preferredZipCode', zipCode);
    setCookie('preferredStoreId', storeNumber);
    analyticsGlobalVariables({ userAuthenticated });
    /* Analytics End */
  };
}
