/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type Dispatch, type ReactNode, type SetStateAction, createContext, useState } from 'react';

type AccountNotificationProviderType = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  text: string;
  setText: Dispatch<SetStateAction<string>>;
  type: NotificationType;
  setType: Dispatch<SetStateAction<NotificationType>>;
  onClose: () => void;
};

export const AccountNotificationContext = createContext<AccountNotificationProviderType>(
  {} as AccountNotificationProviderType
);

type AccountNotificationProviderProps = {
  children: ReactNode;
};

type NotificationType =
  | 'info'
  | 'success'
  | 'error'
  | 'warning'
  | 'high-alert'
  | 'snack-bar'
  | 'custom';

export function AccountNotificationProvider({ children }: AccountNotificationProviderProps) {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [type, setType] = useState<NotificationType>('info');

  function onClose() {
    setShow(false);
    setTitle('');
    setText('');
    setType('info');
  }

  return (
    <AccountNotificationContext.Provider
      value={{
        show,
        setShow,
        title,
        setTitle,
        text,
        setText,
        type,
        setType,
        onClose,
      }}
    >
      {children}
    </AccountNotificationContext.Provider>
  );
}

export default AccountNotificationProvider;
