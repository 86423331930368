/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { getPageName } from '@/utils/analytics/legacyUtils/getPageName';
import { pageTypes } from '@/utils/analytics/legacyUtils/pageTypes';
import { getAxiosForClient } from '@/lib/axios';
import { type ErrorDetails } from '@/types';
import { type FormikHelpers } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { siteSections } from '@/utils/siteSections';

type SmsOptInResponse = {
  links: unknown[];
  message: string;
};

const getKey = () => ['smsOptIn'];

const SMS_OPT_IN_URL = `${requestBaseURL}/ecomm/b2c/v1/utility/sms/optIn`;

type Values = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  zip: string;
};

type MutationVariables = {
  values: Values;
  actions?: FormikHelpers<Values>;
};

const formatErrors = (errorDetails: ErrorDetails[]) => {
  const errors: {
    email?: string;
    lastName?: string;
    firstName?: string;
    phoneNumber?: string;
    zip?: string;
  } = {};
  errorDetails.forEach(({ errorMessageDefault, errorMessageKey }) => {
    if (errorMessageKey.includes('email')) {
      errors.email = errorMessageDefault;
    }

    if (errorMessageKey.includes('firstName')) {
      errors.firstName = errorMessageDefault;
    }

    if (errorMessageKey.includes('lastName')) {
      errors.lastName = errorMessageDefault;
    }

    if (errorMessageKey.includes('phoneNumber')) {
      errors.phoneNumber = errorMessageDefault;
    }

    if (errorMessageKey.includes('zip')) {
      errors.zip = errorMessageDefault;
    }
  });
  return errors;
};

const handleSubmit = async ({ values, actions }: MutationVariables) => {
  const formattedPhoneNumber = values.phoneNumber.replace(/[^0-9.]/g, '');
  const payload = { ...values, phoneNumber: formattedPhoneNumber };
  let topLevelError = false;

  try {
    const { data } = await getAxiosForClient().post<SmsOptInResponse>(SMS_OPT_IN_URL, payload);
    const isSuccess = data.message === 'success';

    if (!isSuccess) {
      topLevelError = true;
      throw new Error(data.message);
    }

    clickTrack({
      pageName: getPageName(pageTypes.sms, ''),
      siteSection: siteSections.siteSectionSms,
      pageType: pageTypes.sms,
      eventType: 'smsRegistration',
      customLinkName: 'smsSuccessfulRegistration',
    });
  } catch (err: unknown) {
    if (topLevelError) {
      throw err;
    }

    // @ts-expect-error we're checking if it's an object
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    const errorDetails = err?.response?.data?.errorDetails;
    if (errorDetails && typeof errorDetails === 'object') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      actions?.setErrors(formatErrors(errorDetails));
    }
  }
};

export const useSMSOptinMutation = () => {
  return useMutation({
    mutationFn: ({ values, actions }: MutationVariables) => handleSubmit({ values, actions }),
    mutationKey: getKey(),
  });
};
