/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { createContext, useState, type ReactNode, useEffect, useContext } from 'react';
import { useMyProfile } from '@/features/myProfile/api/getMyProfile';
import { getDecodedCookies, setCookie } from '@/utils/cookie';
import { useSelector } from 'react-redux';
import { type ReduxState } from '@/types';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';

type Banner90DayTypeProvider = {
  setOpenBannerFn: (open: boolean, enableSetCookie?: boolean) => void;
  openBanner: boolean;
};

const Banner90DayContext = createContext<Banner90DayTypeProvider>({} as Banner90DayTypeProvider);

export const useBanner90DayContext = () => {
  const context = useContext(Banner90DayContext);
  if (context === undefined) {
    throw new Error('useBanner90DayContext must be used within a Banner90DayProvider');
  }
  return context;
};

type Banner90DayProviderProps = {
  children: ReactNode;
};

export const Banner90DayProvider = ({ children }: Banner90DayProviderProps) => {
  const userAuthenticated = useSelector(({ appData }: ReduxState) => appData.userAuthenticated);
  const router = useRouter();
  const isClient = typeof document !== 'undefined';
  const isCartPage = router.asPath.includes(routePaths.cart);
  const isCheckoutPage = router.asPath.includes(routePaths.checkout);
  const { data: myProfile } = useMyProfile();
  // Alert type "TC" is for 0 day
  // Alert type "90" is the 90 day banner
  const loyaltyAccountAlertPromptObject = myProfile?.loyaltyAccountAlerts?.find(
    (alert) => alert.alertType === '90'
  );
  const isPrompt = loyaltyAccountAlertPromptObject?.status === 'PROMPT';
  const cookies = isClient ? getDecodedCookies(['90DayBannerSeen']) : null;
  const isBannerViewed = isClient ? cookies?.get('90DayBannerSeen') : true;
  useEffect(() => {
    setOpenBanner(
      userAuthenticated && isPrompt && !isBannerViewed && !isCartPage && !isCheckoutPage
    );
  }, [isPrompt, isBannerViewed, userAuthenticated, isCartPage, isCheckoutPage]);

  const [openBanner, setOpenBanner] = useState(isPrompt && !isCartPage && !isCheckoutPage);

  const setOpenBannerFn = (open: boolean, enableSetCookie = false) => {
    setOpenBanner(open);
    // for use cases where we only close the banner without setting the cookie
    if (enableSetCookie) {
      setCookie('90DayBannerSeen', 'true');
    }
  };

  return (
    <Banner90DayContext.Provider
      value={{
        setOpenBannerFn,
        openBanner,
      }}
    >
      {children}
    </Banner90DayContext.Provider>
  );
};
