/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import logger from '@/utils/logger';
import environmentConfig from '@/config/environment';
import { useEffect } from 'react';

const useContentStackLivePreview = ({ enabled }: { enabled: boolean }) => {
  useEffect(() => {
    if (enabled && environmentConfig.CMS_PREVIEW_ENABLED && window != window.parent) {
      import('@contentstack/live-preview-utils')
        .then((module) => {
          module.default.init({
            enable: enabled,
            ssr: true,
          });
        })
        .catch((err: unknown) => {
          logger.error({
            message: 'Failed to init preview SDK',
            meta: {
              error: err,
            },
          });
        });
    }
  }, [enabled]);
};

export { useContentStackLivePreview };
