/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { IconButton } from '../IconButton/IconButton';
import { Alert } from '../Alert/Alert';
import { signout } from '../../actions/signout';
import { warningExclamation } from '../../constants/images/warningExclamation';
import CloseIcon from '../../../public/images/close.svg';
import iconStyles from '../../theme/iconStyles.module.scss';
import styles from './styles.module.scss';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { useQueryClient } from '@tanstack/react-query';
import { localStorage } from '@/utils/localStorage';
import { useSelector } from 'react-redux';
import { type ReduxState } from '@/types';
import { useRouter } from 'next/router';
type Props = {
  closed: boolean;
  color: string;
  message: string;
  severity: 'error' | 'info' | 'success' | 'warning';
  variant?: string;
  fixed?: boolean;
};

export const Toast = ({ closed, color, message, severity, variant, fixed }: Props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(!closed);
  const queryClient = useQueryClient();
  const appData = useSelector(({ appData }: ReduxState) => appData);

  const canShowToastNotification = localStorage.getItem('canShowToastNotification');

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      localStorage.removeItem('canShowToastNotification');
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (appData.userAuthenticated) {
      dispatch(signout(queryClient));
    }
  }, [dispatch, queryClient, appData.userAuthenticated]);

  const handleOnClick = () => {
    setOpen(false);
    localStorage.removeItem('canShowToastNotification');
  };

  return (
    <div className={fixed ? styles.fixedToastContainer : styles.toastContainer}>
      {open && canShowToastNotification ? (
        <Alert
          icon={<img src={warningExclamation.src} alt={warningExclamation.alt} />}
          color={color}
          variant={variant}
          severity={severity}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={handleOnClick}>
              <CloseIcon className={cx(iconStyles.defaultIconStyle, iconStyles.fontSizeInherit)} />
            </IconButton>
          }
        >
          {message}
        </Alert>
      ) : null}
    </div>
  );
};
