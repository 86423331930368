/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type AnyAction } from 'redux';
import { mergeDeep } from '@/utils/mergeDeep';
import type { CartItemState } from '../types';
import actionTypes from '../types/action';

const cartItemState = (
  state: CartItemState = {},
  action: AnyAction
):
  | any
  | CartItemState
  | {}
  | {
      [x: string]: any;
    } => {
  switch (action.type) {
    case actionTypes.SAVE_CART_ITEM_STATE: {
      const cartItemId = action.cartItemId;
      return {
        ...state,
        [cartItemId]: { ...(state[cartItemId] || {}), ...action.newState },
      };
    }

    case actionTypes.BULK_SAVE_CART_ITEM_STATE:
      return mergeDeep(state, action.cartItems);

    case actionTypes.RESET_CART_ITEMS_STATE:
      return {};

    default:
      return state;
  }
};

export default cartItemState;
