/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type YmmeDropdownData } from '../interface';

export const findListItemByLabel = (ymmeData: YmmeDropdownData = [], label: string | null = '') => {
  return ymmeData.find((ymmeListItem) => ymmeListItem.label === label);
};
