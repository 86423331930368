/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React, { useEffect, useRef } from 'react';
import type { ContentStackBanner, ContentStackBannerSplit, SizePerDevice } from '../../interface';
import { Legal } from '@/components/AZCustomComponent/Legal';
import styles from './CMSBanner.module.scss';
import { mapContentstackLegalValues } from '@/utils/mapContentstackLegalValues';
import cx from 'classnames';
import { CMSImage } from '../CMSImage/CMSImage';
import { reportCitrusClick } from '@/features/citrus/utils/reportCitrusClick';
import { reportCitrusImpression } from '@/features/citrus/utils/reportCitrusImpression';
import { CMSLink } from '../CMSLink/CMSLink';
import { CMSHeadline } from '../CMSHeadline/CMSHeadline';

export type CMSBannerProps = {
  content: ContentStackBanner;
  dropShadowSize?: ContentStackBannerSplit['drop_shadow'];
  sponsored?: boolean;
  citrusInfo?: {
    citrusAdId: string;
  };
  legalPadding?:
    | boolean
    | {
        s?: boolean;
        m?: boolean;
        l?: boolean;
      };
  priority?: boolean;
  sizePerDevice?: SizePerDevice;
  loading?: 'lazy' | 'eager';
};

const defaultSizePerDevice = {
  mobile: {
    width: 1376,
    height: 550,
  },
  tablet: {
    width: 2220,
    height: 275,
  },
  desktop: {
    width: 2332,
    height: 270,
  },
};

export function CMSBanner({
  content,
  dropShadowSize,
  sponsored,
  citrusInfo,
  legalPadding,
  priority,
  sizePerDevice,
  loading,
}: CMSBannerProps) {
  const { image, legal, link, asset_size, heading, heading_underline } = content;
  const imageRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    if (!sponsored) return;
    const currentImageRef = imageRef.current;
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && citrusInfo?.citrusAdId) {
        void reportCitrusImpression(citrusInfo.citrusAdId);
      }
    }, {});

    if (currentImageRef) {
      observer.observe(currentImageRef);
    }

    return () => {
      currentImageRef && observer.unobserve(currentImageRef);
    };
  }, [sponsored, citrusInfo?.citrusAdId]);

  return (
    <>
      {heading && (
        <CMSHeadline isBannerHeadline underline={Boolean(heading_underline)}>
          {heading}
        </CMSHeadline>
      )}
      {image &&
        (link ? (
          <CMSLink
            to={link}
            samePage
            onClick={() => {
              if (citrusInfo?.citrusAdId) {
                void reportCitrusClick(citrusInfo.citrusAdId);
              }
            }}
            onAuxClick={(e) => {
              if (e.button === 1 && citrusInfo?.citrusAdId) {
                void reportCitrusClick(citrusInfo.citrusAdId);
              }
            }}
          >
            <CMSImage
              ref={imageRef}
              image={image}
              loading={loading}
              sizePerDevice={sizePerDevice ?? defaultSizePerDevice}
              priority={priority}
              className={cx(
                styles.bannerImage,
                dropShadowSize && styles[`dropShadow${dropShadowSize}`],
                asset_size && !sponsored && styles[`assetSize${asset_size}`]
              )}
            />
          </CMSLink>
        ) : (
          <CMSImage
            ref={imageRef}
            image={image}
            loading={loading}
            sizePerDevice={sizePerDevice ?? defaultSizePerDevice}
            priority={priority}
            className={cx(
              styles.bannerImage,
              dropShadowSize && styles[`dropShadow${dropShadowSize}`],
              asset_size && styles[`assetSize${asset_size}`]
            )}
          />
        ))}
      {legal && (
        <div
          className={cx({
            [styles.legalPaddingS]:
              (typeof legalPadding === 'object' && legalPadding.s) ||
              (typeof legalPadding === 'boolean' && !!legalPadding),
            [styles.legalPaddingM]:
              (typeof legalPadding === 'object' && legalPadding.m) ||
              (typeof legalPadding === 'boolean' && !!legalPadding),
            [styles.legalPaddingL]:
              (typeof legalPadding === 'object' && legalPadding.l) ||
              (typeof legalPadding === 'boolean' && !!legalPadding),
          })}
        >
          <Legal content={mapContentstackLegalValues(legal)} isQuotient={sponsored} />
        </div>
      )}
    </>
  );
}
