/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxios } from '@/lib/axios';
import { type QueryClient, type QueryFunctionContext, useQuery } from '@tanstack/react-query';
import type {
  RewardsActivityData,
  RewardsActivityFilters,
  RewardsActivityResponse,
} from '../interface';
import { requestBaseURL } from '@/config/serviceAPI';
import { type AxiosInstance } from 'axios';
import { useHeaderData } from '@/features/header/api/getHeader';
import { type OrderType } from '@/constants/orderTypes';

const URL = `${requestBaseURL}/ecomm/b2c/v1/currentUser/rewards`;

type RewardsActivityAPIParams = {
  /** Format: YYYYMMDD */
  fromDate?: string;
  /** Format: YYYYMMDD */
  toDate?: string;
  order_type?: OrderType;
};

const getRewardsActivity = async (
  { queryKey }: QueryFunctionContext<ReturnType<typeof getRewardsActivityKey>>,
  axiosInstance?: AxiosInstance
) => {
  const [, , { rewardLoyaltyCardNumber, filters }] = queryKey;

  if (!rewardLoyaltyCardNumber) {
    throw new Error('rewardLoyaltyCardNumber is required');
  }

  const { fromDate, toDate, orderType } = filters ?? {};

  const params: RewardsActivityAPIParams = {
    fromDate: fromDate ?? undefined,
    toDate: toDate ?? undefined,
    order_type: orderType ?? undefined,
  };

  const response = await getAxios(axiosInstance).get<RewardsActivityResponse>(
    `${URL}/${rewardLoyaltyCardNumber}`,
    {
      params,
    }
  );

  return selector(response.data);
};

const selector = (data: RewardsActivityResponse): RewardsActivityData => {
  return data;
};

type QueryOptions = {
  rewardLoyaltyCardNumber: string | undefined;
  userAnonymous: boolean;
  filters?: RewardsActivityFilters;
};

const getRewardsActivityKey = (queryOptions: QueryOptions) =>
  ['user', 'rewardsActivity', queryOptions] as const;

export const useRewardsActivity = (filters?: RewardsActivityFilters) => {
  const { data: headerData, isFetching: isFetchingHeader } = useHeaderData();
  const userAnonymous = headerData?.myAccountMap?.anonymous !== 'false';
  const rewardsId = headerData?.myAccountMap.rewardsId;

  return useQuery({
    queryKey: getRewardsActivityKey({
      rewardLoyaltyCardNumber: rewardsId,
      userAnonymous,
      filters: filters ?? {
        fromDate: null,
        toDate: null,
        orderType: null,
      },
    }),
    queryFn: getRewardsActivity,
    enabled: !isFetchingHeader && !!rewardsId,
  });
};

export const prefetchRewardsActivity = async (
  options: QueryOptions,
  queryClient: QueryClient,
  axiosInstance: AxiosInstance
) => {
  return queryClient.prefetchQuery({
    queryKey: getRewardsActivityKey(options),
    queryFn: (context: QueryFunctionContext<ReturnType<typeof getRewardsActivityKey>>) =>
      getRewardsActivity(context, axiosInstance),
  });
};
