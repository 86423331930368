/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState, useEffect } from 'react';
import cx from 'classnames';
import { Grid } from '@/components/Grid';
import styles from './styles.module.scss';
import Image, { pureLoader } from '@/components/NextImage';
import root from 'window-or-global';
import azCommonStyles from '@/theme/globals.module.scss';
import type { CertonaPricing, AddToCartData } from '@/types';
import { replaceHistoryStateOptionsOnClick } from '@/features/common/utils/replaceHistoryStateOptionsOnClick';
import { historyStateOptionAttributes } from '@/constants/historyStateOption';
import { useActiveElement } from '@/hooks/useActiveElement';
import { useLabels } from '@/hooks/useLabels';
import { Button } from '@/components/Button';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useAddToCart } from '@/features/orders/api/postAddToCart';
import { useAddToCartWithMiniCart } from '../../hooks/useAddToCartWithMiniCart';
import type { AddCertonaItemObjectType } from '../../interface';
import { SmartLink as Link } from '@/utils/smartLink';
import { Rating } from '@/components/Rating/Rating';
import { LoadingIndicatorDots } from '@/components/LoadingIndicatorDots';
import { fetchPaymentOptions } from '@/actions/checkout';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';
import { useDeviceType } from '@/utils/useDeviceType';
import { formatPrice } from '@/utils/validator/formatPrice';
import { type OrderType } from '@/constants/orderTypes';
import type { CustomLinkName } from '@/types/analytics';
import { Text, View } from '@az/starc-ui';

type Props = {
  part: {
    properties: {
      seoUrl: string;
      imageUrl: string;
      refPageType: string;
      makeModelYearPath: string | undefined;
    };
    label: string;
    rating?: string;
    totalReviews?: string;
    averageRating?: string;
  };
  actionHandler: (b: string, a: string) => void;
  pricingInfo?: CertonaPricing | null;
  addToCartData?: AddToCartData | null;
  itemId?: string;
  isCertonaCard?: boolean;
  isFromExternal?: boolean;
  dynamicRef?: HTMLElement;
  onkeydown?: boolean;
  showCard?: boolean;
  addToCartAnalyticsandSuccessMessage?: (cartData: AddToCartData) => void;
  setAddedCertonaItemObject?: React.Dispatch<React.SetStateAction<AddCertonaItemObjectType | null>>;
  showAddtoCartCTA?: boolean;
};

type AddToCartRequestType = {
  sku_id: string;
  product_id: string;
  quantity: string;
  category_id: string;
  order_type: OrderType | number;
  catalog_vehicle_id: string;
  store_number: string;
  orderType: string;
  requestFromCertona: boolean;
};

const labelMap = {
  priceUnavailable: 'label_ProductDisplayPage_body_PriceUnavailable',
  clickToSeePrice: 'label_ProductDisplayPage_body_ClickToSeePrice',
  lblCarouselImage: 'label_Carousel_Image',
  lblAdd: 'label_MyAccountVehicle_maintenanceInterval_Add',
  lblToCart: 'label_ProductDisplayPage_body_ToCart',
  lblAddToCart: 'label_add_to_cart_certona',
  lblAdded: 'label_added_to_cart_certona',
  lblTotalReviews: 'label_Carousel_TotalReviews',
  lblOutOfFiveStars: 'label_Carosuel_OutOfFiveStars',
  lblSeeOptions: 'label_certonaSeeOptions',
};

export function LargePartCard(props: Props) {
  const {
    part: { properties, label, totalReviews, averageRating },
    actionHandler,
    itemId = '',
    pricingInfo,
    dynamicRef,
    onkeydown,
    addToCartAnalyticsandSuccessMessage,
    showCard = true,
    addToCartData,
    setAddedCertonaItemObject,
    showAddtoCartCTA,
  } = props;
  const {
    priceUnavailable,
    clickToSeePrice,
    lblAdd,
    lblToCart,
    lblAdded,
    lblTotalReviews,
    lblOutOfFiveStars,
    lblSeeOptions,
  } = useLabels(labelMap);
  const router = useRouter();
  const isCart = router.pathname === '/cart';
  const isVehicleDetails = router.asPath.includes(routePaths.vehicleDetails);
  const deviceType = useDeviceType();
  const isBot = deviceType === 'bot';
  const certonaAddToCartEnabled = !isBot && showAddtoCartCTA;
  const dispatch = useDispatch();
  const [showLoadingIndicators, setShowLoadingIndicators] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);
  const addProductToCart = `${lblAdd} ${label} ${lblToCart}`;
  const addedProductToCart = `${lblAdded} ${label} ${lblToCart}`;
  const { corePrice, retailPrice, totalPrice, configurableSku } = pricingInfo ?? {};
  const focusedElement = useActiveElement();
  const { data: headerData } = useHeaderData();
  const skusPresentInCart = headerData?.analyticsData.cartProductSkus;
  const catalogVehicleId = headerData?.vehicleMap?.catalogVehicleId ?? '';

  const submitToCart = () => {
    void router.push(routePaths.cart);
  };

  const { addToCartCall, addingToCartStatus, cartSuccess } = useAddToCartWithMiniCart();
  const [addToCartStatus, setAddToCartStatus] = useState<string>('idle');
  const cartSuccessData = cartSuccess;

  useEffect(() => {
    setAddToCartStatus(addingToCartStatus);
  }, [addingToCartStatus]);

  const scrollIntoViewOptions: ScrollIntoViewOptions = {
    block: 'nearest',
    inline: 'nearest',
    behavior: 'auto',
  };
  const { data: storeDetailsData } = useStoreDetailsData();

  const addCertonaItemObject: AddCertonaItemObjectType = {
    productImage: properties?.imageUrl ?? '',
    cartSuccessData: cartSuccessData,
    productDetails: {
      productName: {
        productBrand: addToCartData?.brandName ?? '',
        productPart: addToCartData?.productPartNumber ?? '',
        quickNote: addToCartData?.productRepositoryId ?? '',
      },
      skuId: addToCartData?.skuId,
      partType: addToCartData?.originalPartTypeId?.toString(),
      productId: addToCartData?.productId,
    },
    submitToCart,
    productTitle: label ?? '',
    pageName: 'pdp',
  };

  const afterAddTocartSuccess = async () => {
    await dispatch(fetchPaymentOptions());
    setShowLoadingIndicators(false);
    setAddedToCart(true);
    const addToCartAnalyticsData = {
      price: totalPrice?.toFixed(2),
      ...addToCartData,
    };
    addToCartAnalyticsandSuccessMessage &&
      addToCartAnalyticsandSuccessMessage(addToCartAnalyticsData);
  };
  const addToCartMutation = useAddToCart();

  const newAfterAddTocartSuccess = async () => {
    setShowLoadingIndicators(false);
    setAddedToCart(true);
    await dispatch(fetchPaymentOptions());
    const addToCartAnalyticsData = {
      price: totalPrice?.toFixed(2),
      ...addToCartData,
    };
    addToCartAnalyticsandSuccessMessage &&
      addToCartAnalyticsandSuccessMessage(addToCartAnalyticsData);
    if (!!setAddedCertonaItemObject) {
      setAddedCertonaItemObject(addCertonaItemObject);
    }
  };

  useEffect(() => {
    if (onkeydown && focusedElement === dynamicRef) {
      focusedElement.scrollIntoView(scrollIntoViewOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedElement]);

  const handleClick = () => {
    actionHandler(label, itemId);
    replaceHistoryStateOptionsOnClick(
      historyStateOptionAttributes.latestSelectedPageYOffset,
      root.pageYOffset
    );
  };

  const handleNavigateToPDP = (event: React.MouseEvent) => {
    event.preventDefault();
    handleClick();
    void router.push(properties.seoUrl);
  };

  const handleAddtoCartCtaClick = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (addedToCart) {
      return;
    }
    if (showAddtoCartCTA && !isCart && !isVehicleDetails) {
      return handleAddtoCartNewCtaClick();
    }
    setShowLoadingIndicators(true);
    if (!addToCartMutation.isLoading) {
      const addToCartRequest = {
        skuId: String(addToCartData?.skuId),
        productId: addToCartData?.productId,
        quantity: '1',
        fulfillmentTypeId: addToCartData?.fulFillmentId,
        vehicleId: addToCartData?.vehicleId,
        storeNumber: String(storeDetailsData?.storeNumber),
      };
      await addToCartMutation.mutateAsync(addToCartRequest);
      void afterAddTocartSuccess();
    }
  };

  const handleAddtoCartNewCtaClick = () => {
    setShowLoadingIndicators(true);
    if (addToCartStatus !== 'loading') {
      const { pageName = '', pageType = '' } = root.utag_data ?? {};

      const onSuccess = () => {
        void newAfterAddTocartSuccess();
      };

      const onSettled = () => {
        setShowLoadingIndicators(false);
      };

      const addToCartAnalyticsDataForCall = {
        price: totalPrice?.toFixed(2) ?? '',
        customLinkName: `${pageType}-Certona:Add_to_Cart` as CustomLinkName,
        pageName: pageName,
        pageType: pageType,
        productRecommendationTypeList: 'Certona',
        productBrand: addToCartData?.brandName ?? '',
        productStrikeThroughPricing: ['0'],
        corePrice: corePrice,
        fulfillmentChange: '',
        originalPartTypeId: addToCartData?.originalPartTypeId,
        productRepositoryId: addToCartData?.productRepositoryId,
        productPartNumber: addToCartData?.productPartNumber,
        productFulfillmentOptionSeenByCustomer: Number(addToCartData?.fulFillmentId),
        ...addToCartData,
      };

      const addToCartRequest: AddToCartRequestType = {
        sku_id: String(addToCartData?.skuId),
        product_id: addToCartData?.productId ?? '',
        quantity: '1',
        category_id: '',
        order_type: addToCartData?.fulFillmentId ?? ('' as OrderType),
        catalog_vehicle_id: addToCartData?.vehicleId ?? '',
        store_number: String(storeDetailsData?.storeNumber),
        orderType: '',
        requestFromCertona: true,
      };
      addToCartCall(
        addToCartRequest,
        addToCartAnalyticsDataForCall,
        catalogVehicleId,
        onSuccess,
        onSettled
      );
    }
  };

  useEffect(() => {
    const skuId = pricingInfo?.skuId || itemId;
    if (skusPresentInCart?.includes(skuId)) {
      setAddedToCart(true);
    }
  }, [skusPresentInCart, itemId, pricingInfo]);

  if (!showCard) {
    return null;
  }

  return (
    <Grid
      item
      alignContent="space-between"
      direction="column"
      className={cx(styles.newLargePartCard, {
        [styles.partCardCTAEnabled]: certonaAddToCartEnabled,
      })}
    >
      <Link
        to={properties.seoUrl}
        key={properties.seoUrl}
        onClick={() => {
          handleClick();
        }}
        data-testid="part-link"
      >
        <Grid item className={styles.imageContainer} xs={12}>
          <Image
            src={properties.imageUrl}
            alt=""
            className={styles.certonaPartImage}
            data-testid="part-img"
            width={100}
            height={100}
            loader={pureLoader}
          />
        </Grid>

        {!pricingInfo?.configurableSku && totalPrice === null ? (
          <Grid className={styles.priceUnavailable} container lg={12} xs={12} justify="center">
            <span className={styles.pricingInfo}>{priceUnavailable}</span>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <View direction="column">
            {totalPrice ? (
              <View.Item>
                <View direction="row" data-testid="total-price" className={styles.pricingContainer}>
                  {!configurableSku && typeof totalPrice === 'number' ? (
                    <View>
                      <View.Item className={styles.price}>
                        $
                        {corePrice
                          ? formatPrice(totalPrice) ?? null
                          : retailPrice
                          ? formatPrice(retailPrice)
                          : null}
                      </View.Item>
                      {totalPrice !== undefined && retailPrice !== totalPrice && !corePrice && (
                        <View.Item className={cx(azCommonStyles['az-caption'], styles.totalPrice)}>
                          {`$${formatPrice(totalPrice)}`}
                        </View.Item>
                      )}
                    </View>
                  ) : null}

                  {configurableSku && totalPrice !== null ? (
                    <span className={styles.pricingInfo}>{clickToSeePrice}</span>
                  ) : null}
                </View>
              </View.Item>
            ) : null}

            <View.Item gapBefore={1}>
              {averageRating && Number(averageRating) > 0 && totalReviews ? (
                <View direction="row" wrap={false} height="19px">
                  <View
                    direction="column"
                    justify="center"
                    height="100%"
                    className={styles.starRatingContainer}
                  >
                    <Rating
                      value={Number(averageRating)}
                      max={5}
                      showStarFractions
                      isCertonaPartCard={true}
                    />
                    <span className="sr-only">
                      {`${Number(averageRating).toFixed(1)} ${lblOutOfFiveStars}`}
                    </span>
                  </View>
                  <View.Item gapBefore={7 / 4} />
                  <View direction="column" justify="center" height="100%">
                    <Text
                      variant="subtitle-regular"
                      className={styles.starcSubtitleRegularOverride}
                    >{`${Number(averageRating).toFixed(1)} (${totalReviews})`}</Text>
                    <span className="sr-only">{`${totalReviews} ${lblTotalReviews}`}</span>
                  </View>
                </View>
              ) : null}
            </View.Item>
            <View.Item
              gapBefore={1}
              className={cx(styles.partLabel, {
                [styles.partLabelAddToCart]: certonaAddToCartEnabled,
              })}
              attributes={{ 'data-testid': 'part-label' }}
            >
              {label}
            </View.Item>
          </View>
        </Grid>
      </Link>
      {certonaAddToCartEnabled && (
        <Grid
          container
          justify="center"
          alignContent="flex-end"
          xs={10}
          lg={14}
          className={styles.addtoCartContainer}
          onClick={configurableSku ? handleNavigateToPDP : handleAddtoCartCtaClick}
        >
          <Button
            ariaLabel={addedToCart ? addedProductToCart : addProductToCart}
            variant="outlined"
            shape="pill"
            size="small"
            customClass={cx(styles.addtoCartButton, {
              [styles.addedTocartButtonstyles]: addedToCart,
            })}
          >
            {configurableSku && !showLoadingIndicators && (
              <span>{lblSeeOptions.toUpperCase()}</span>
            )}
            {!configurableSku && !addedToCart && !showLoadingIndicators && (
              <>
                <Image
                  src={'/images/addcart.svg'}
                  alt=""
                  width={14}
                  height={14}
                  loader={pureLoader}
                />
                <span className={styles.addtoCartText}> {lblAdd}</span>
              </>
            )}

            {!configurableSku && addedToCart && !showLoadingIndicators && (
              <>
                <Image
                  src={'/images/green-tick-rounded.svg'}
                  alt=""
                  width={14}
                  height={14}
                  loader={pureLoader}
                />
                <span className={styles.addtoCartText}> {lblAdded}</span>
              </>
            )}

            {showLoadingIndicators && <LoadingIndicatorDots size={12} color={'#000'} />}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
