/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { getHeaderDataFromCache, HEADER_PRIMARY_KEY } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { getAxios } from '@/lib/axios';
import { type AxiosInstance } from 'axios';
import { useMutation, useQueryClient, type QueryClient } from '@tanstack/react-query';
import { type YmmeVehicleResponse } from '../interface';
import { setPreferredVehicleCookies } from '@/utils/analytics/dataLayer/preferredVehicleCookies';
import { getKey as getVehiclesKey, selector as getVehiclesSelector } from './getVehicles';
import { useRouter } from 'next/router';
import { stringifyUrl, parseUrl } from '@/utils/urlHelpers';
import type { Locale } from '@/types/i18n';
import { dispatchEvent } from '@/utils/eventPublisher';
import { useGlobalState } from '@/hooks/useGlobalState';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { sessionStorage } from '@/utils/sessionStorage';

const URL = `${requestBaseURL}/ecomm/b2c/v2/currentUser/vehicles`;

const postVehicles = async (
  options: {
    vehicleId: string | undefined;
    previousVehicleID?: string;
  },
  axiosInstance?: AxiosInstance
) => {
  const { vehicleId, previousVehicleID } = options;
  const response = await getAxios(axiosInstance).post<YmmeVehicleResponse>(URL, {
    vehicleId,
  });
  if (previousVehicleID) {
    sessionStorage.setItem('previousPreferredVehicleId', previousVehicleID);
  }
  return getVehiclesSelector(response.data);
};

export const getKey = () => ['user', 'vehicles'];

type Variables = {
  vehicleId: string;
  isLicensePlateRequest?: boolean;
};

type MutationOptions = {
  onSuccess?: (data: YmmeVehicleResponse) => void;
};

const onVehiclesSuccess = async (queryClient: QueryClient, data: YmmeVehicleResponse) => {
  queryClient.setQueryData(getVehiclesKey(), data);
  await queryClient.refetchQueries([HEADER_PRIMARY_KEY]);

  const headerData = getHeaderDataFromCache(queryClient);
  const preferredVehicle = getPreferredVehicle(headerData);

  if (preferredVehicle) {
    setPreferredVehicleCookies({
      vehicleYear: preferredVehicle.year,
      vehicleMake: preferredVehicle.make,
      vehicleModel: preferredVehicle.model,
      vehicleEngine: preferredVehicle.engine,
      vehicleId: preferredVehicle.catalogVehicleId,
    });
  }
};

export const useVehiclesMutation = ({ onSuccess }: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const [, setSearchState] = useGlobalState('search');
  const previousPreferredVehicle = usePreferredVehicle();
  const previousVehicleID = previousPreferredVehicle?.catalogVehicleId ?? '0';

  return useMutation({
    mutationFn: ({ vehicleId }: Variables) => postVehicles({ vehicleId, previousVehicleID }),
    mutationKey: getKey(),
    onSuccess: (data: YmmeVehicleResponse) => {
      setSearchState({
        preferredVehicleChanged: true,
      });
      onSuccess?.(data);
      void onVehiclesSuccess(queryClient, data);
      if (router.query.currentPage || router.query.pageNumber) {
        const { url, query } = parseUrl(router.asPath);
        delete query.pageNumber;
        delete query.currentPage;

        const newPathName = stringifyUrl({
          url,
          query,
        });

        void router.push(newPathName);
      }
      dispatchEvent('preferredVehicleUpdated', { vehicleSet: true });
    },
  });
};

export const mutateVehicles = async (
  queryClient: QueryClient,
  options: { vehicleId: string; locale: Locale },
  axiosInstance?: AxiosInstance
) => {
  const data = await postVehicles(options, axiosInstance);
  await onVehiclesSuccess(queryClient, data);
};
