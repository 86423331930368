/**
 * Copyright 2019-2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { pathToRegexp } from 'path-to-regexp';
const protectedRoutes = [
  '/checkRewardAccount',
  '/myWarranties',
  '/myWarranties/:warrantyId',
  '/myWarranties/:warrantyId/:warrantyItemId',
  '/user/addServiceHistory/:vehicleId',
  '/user/addServiceHistory/:vehicleId/:serviceItemId',
  '/user/addVehicleDetails/:vehicleId',
  '/user/maintenanceIntervals/:vehicleId',
  '/user/myAccount',
  '/user/myProfile',
  '/user/rewards/requestCredit',
  '/user/serviceHistory/:vehicleId',
  '/user/vehicleDetails/:vehicleId',
  '/user/orderHistory',
];
const regexps = protectedRoutes.map((path) => pathToRegexp(path));
const cache: { [key: string]: boolean } = {};

export function isRouteProtected(pathname: string): boolean {
  if (cache[pathname]) {
    return cache[pathname];
  }

  const result = regexps.some((regexp) => regexp.test(pathname));
  cache[pathname] = result;
  return result;
}
