/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { countryCodes, defaultStoreUS } from '@/constants/locale';
import { useLocale } from '@/hooks/useLocale';
import { useContext, useState, type ReactNode } from 'react';
import { type TabIndexes } from '../SignInModalV2/interface';
import { useFeatureFlag } from '@/features/globalConfig';
import { SignInFlowTypeContext } from '../SignInFlowTypeContext';
import { Actionable } from '@az/starc-ui';
import { useSelector } from 'react-redux';
import { getUserAuthenticated } from '@/constants/selectors';
import dynamic from 'next/dynamic';
import ProgressModal from '../AZCustomComponent/ProgressModal';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';

const LazySignInModalV2 = dynamic(() => import('@/components/SignInModalV2'), {
  loading: () => <ProgressModal noScroll />,
});

const LazySignInModal = dynamic(() => import('@/components/SignInModal'), {
  loading: () => <ProgressModal noScroll />,
});

const LazySearchStoreModal = dynamic(
  () =>
    import('@/components/Store/StoreModal/SearchStoreModal').then((mod) => mod.SearchStoreModal),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

const LazyMyStoreModal = dynamic(
  () => import('@/components/Store/StoreModal/MyStoreModal').then((mod) => mod.MyStoreModal),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

type ModalLinkProps = {
  modalId: string;
  className?: string;
  children: ReactNode;
};

export const ModalLink = ({ modalId, className, children }: ModalLinkProps) => {
  const locale = useLocale();
  const [storeModalOpen, setStoreModalOpen] = useState(false);

  const { data: storeData } = useStoreDetailsData();
  const storeNumber = storeData?.storeNumber ?? defaultStoreUS;

  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const isMxSite = locale === countryCodes.mx;
  const isSignInV2Enabled = useFeatureFlag('ENABLE_STREAMLINE_SIGNIN') === 'true' && !isMxSite;
  const [activeTab, setActiveTab] = useState<TabIndexes>(0);
  const { setFlowTypeFn } = useContext(SignInFlowTypeContext);
  const isLoggedIn = useSelector(getUserAuthenticated);

  const handleModalOpen = (id: string) => {
    switch (id) {
      case '[search_store_modal]':
        setStoreModalOpen(true);
        break;
      case '[sign_in_modal]':
      case '[create_account_modal]':
        if (isLoggedIn) {
          break;
        }

        if (isSignInV2Enabled) {
          setFlowTypeFn('login');
        }
        setActiveTab(id === '[sign_in_modal]' ? 0 : 1);
        setSignInModalOpen(true);
        break;
      case '[cookie_preference_center_modal]':
        window.OneTrust?.ToggleInfoDisplay();
        break;
    }
  };

  const isSignInModal = modalId === '[sign_in_modal]' || modalId === '[create_account_modal]';
  const SignInModal = isSignInV2Enabled ? LazySignInModalV2 : LazySignInModal;
  const StoreModal = storeNumber !== defaultStoreUS ? LazyMyStoreModal : LazySearchStoreModal;

  return (
    <>
      <Actionable className={className} onClick={() => handleModalOpen(modalId)}>
        {children}
      </Actionable>
      {isSignInModal && (
        <SignInModal
          isOpen={signInModalOpen}
          onClose={() => setSignInModalOpen(false)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}
      {storeModalOpen && <StoreModal closeStoreModal={() => setStoreModalOpen(false)} />}
    </>
  );
};
