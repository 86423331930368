/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { ContactUsOption } from './ContactUsOption';
import AZLogo from '../../../components/AZCustomComponent/AZLogo';
import styles from './ContactUsModal.module.scss';
import { routePaths } from '../../../constants/routePaths';
// import { useOpenChatWindow } from '../../proactiveChat/hooks/useOpenChatWindow';
import { openChatWindow } from '@/features/proactiveChat';
import { type ReduxState } from '../../../types';
import { useFeatureFlag } from '../../globalConfig';
import { countryCodes } from '@/constants/locale';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import Skeleton from '@/components/Skeleton/Skeleton';

const chatBubblesBlackIcon = {
  width: 22,
  height: 18,
  src: '/images/chat-bubbles-black.svg',
};
const composeMessageIcon = {
  width: 16,
  height: 16,
  src: '/images/compose-message-black.svg',
};
const emailEnvelopeIcon = {
  width: 20,
  height: 14,
  src: '/images/email-envelope-black.svg',
};
const storeIcon = {
  width: 25,
  height: 19,
  src: '/images/contact_us_store.svg',
};

type Props = {
  onSelectQualtrics: () => void;
  onCloseModal: () => void;
};

const labelMap = {
  lblWhatWouldYouLikeToDo: 'label_contact_us_modal_what_would_you_like_to_do',
  lblContactUs: 'label_contact_us_modal_contact_us',
  lblLiveChat: 'label_contact_us_modal_live_chat',
  lblLeaveWebsiteFeedback: 'label_contact_us_modal_leave_website_feedback',
  lblLeaveStoreFeedback: 'label_contact_us_modal_leave_store_feedback',
};

//Each key in the object will be transformed into a pair of Skeletons
const menuLabelMap = {
  lblHaveAquestionOrComment: 'label_contact_us_modal_have_a_question_or_comment',
  lblSpeakWithChatRepresentative: 'label_contact_us_modal_speak_with_chat_representative',
  lblLeaveFeedbackOnYourWebsiteExperience:
    'label_contact_us_modal_leave_feedback_on_your_website_experience',
  lblLeaveFeedbackOnYourStoreExperience:
    'label_contact_us_modal_leave_feedback_on_your_in_store_experience',
};

export function ContactUsModalBody({ onSelectQualtrics, onCloseModal }: Props) {
  const { isChatAgentAvailable } = useSelector(({ appData }: ReduxState) => appData);
  const labels = useLabels(labelMap);
  const menuLabels = useLabels(menuLabelMap);
  const isQualtricsEnabled = useFeatureFlag('CONTACT_US_MODAL_QUALTRICS_ENABLED') === 'true';
  const isChatForMobileEnabled = useFeatureFlag('CHAT_ON_MOBILE_ENABLED') === 'true';
  const chatMobileEnabled = useFeatureFlag('CHAT_ON_MOBILE_ENABLED') === 'true';
  const isV2ChatAgentAvailable = useFeatureFlag('CHECK_AGENT_AVAILABILITY_V2') === 'true';
  const deviceType = useSelector(({ appData }: ReduxState) => appData.deviceType);

  const isMxSite = useLocale() === countryCodes.mx;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));

  const handleOpenChat = () => {
    onCloseModal();
    openChatWindow();
  };

  const chatAgentRequestStatus = useSelector(
    (state: ReduxState) => state.appData.chatAgentRequestStatus
  );
  const isABot = deviceType === 'bot';

  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const chatAvailabilityCallEnabled =
    useFeatureFlag('GENESYS_AVAILABILITY_CALL_ENABLED') === 'true' && !isABot;

  if (
    chatAvailabilityCallEnabled &&
    !isMxSite &&
    isV2ChatAgentAvailable &&
    chatAgentRequestStatus === 'loading'
  ) {
    if (!matchesMobile || (matchesMobile && chatMobileEnabled)) {
      return (
        <div role="progressbar" className={styles.contactUsModalBody}>
          {Object.keys(menuLabelMap).map((key) => (
            <React.Fragment key={key}>
              <Skeleton height={27} width={360} />
              <Skeleton height={27} width={288} />
            </React.Fragment>
          ))}
        </div>
      );
    }
  }

  return (
    <div className={styles.contactUsModalBody} data-testid="menu">
      <div className={styles.azLogoWrapper}>
        <AZLogo variant="Full" />
      </div>
      <h2 className={styles.modalHeader}>{labels.lblWhatWouldYouLikeToDo}</h2>
      <ContactUsOption
        icon={chatBubblesBlackIcon}
        onClick={handleOpenChat}
        title={labels.lblLiveChat}
        subtext={menuLabels.lblSpeakWithChatRepresentative}
        isDisabled={
          isMxSite || !isChatAgentAvailable || ((isMobile || isTablet) && !isChatForMobileEnabled)
        }
      />
      <ContactUsOption
        icon={composeMessageIcon}
        onClick={onSelectQualtrics}
        title={labels.lblLeaveWebsiteFeedback}
        subtext={menuLabels.lblLeaveFeedbackOnYourWebsiteExperience}
        isDisabled={!isQualtricsEnabled}
      />
      <ContactUsOption
        icon={storeIcon}
        onClick={onCloseModal}
        title={labels.lblLeaveStoreFeedback}
        subtext={menuLabels.lblLeaveFeedbackOnYourStoreExperience}
        linkTo={routePaths.storeExperience}
      />
      <ContactUsOption
        icon={emailEnvelopeIcon}
        title={labels.lblContactUs}
        onClick={onCloseModal}
        subtext={menuLabels.lblHaveAquestionOrComment}
        linkTo={routePaths.contactUs}
      />
    </div>
  );
}
