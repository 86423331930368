/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import fetchMiniCartData from '@/actions/miniCart';
import { cartConstants } from '@/constants/cart';
import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { type OrderType } from '@/constants/orderTypes';
import { sessionStorage } from '@/utils/sessionStorage';
import { useAddToCart } from '@/features/orders/api/postAddToCart';
import { getFulfillmentOptionsByGroup } from '@/utils/itemHelpers/fulfillmentHelpers/getFulfillmentOptionsByGroup';
import { siteSections } from '@/utils/siteSections';
import { useRouter } from 'next/router';
import { useState, useCallback } from 'react';
import { useDispatch } from '@/hooks/redux/useDispatch';
import type {
  UseAddToCartWithMiniCartResult,
  AddToCartCallValues,
  AddToCartAnalyticsData,
} from '@/types/reduxStore/cartUpdate';
import { getErrorMessage } from '@/utils/errorsHandling';
import { useQueryClient } from '@tanstack/react-query';
import type { FulfillmentGroup } from '@/types/reduxStore/skuDetails';
import { useFeatureFlag } from '@/features/globalConfig';
import { routePaths } from '@/constants/routePaths';
import type { CustomLinkName } from '@/types/analytics';
import logger from '@/utils/logger';

type AddToCart = {
  eventType: string;
  productIdList: string[];
  productFulfillmentTypeList: string[];
  customLinkName: CustomLinkName;
  productBrandList: string[];
  productPriceList: string[];
  originalPartTypeIdList: string[];
  productRepositoryIdList: string[];
  productPartNumberList: string[];
  pageName: string;
  pageType: string;
  fulfillmentChange?: string;
  productCorePriceList?: string[];
  productAvailableQuantityList?: number[];
  productRecommendationTypeList?: string;
  productStrikeThroughPricing: string[];
  productFulfillmentOptionSeenByCustomer?: string[];
  pfmLocation?: string;
  productFindingMethod?: string;
};

const trackAddToCartPDP = (
  valuesParm: AddToCartCallValues,
  analyticsData: AddToCartAnalyticsData,
  productAvailableQuantity?: number
) => {
  const productFulfillmentTypeValue =
    typeof valuesParm.order_type === 'number'
      ? String(valuesParm.order_type)
      : valuesParm.orderType;

  const data: AddToCart = {
    eventType: 'addToCart',
    productIdList: [valuesParm.sku_id],
    productFulfillmentTypeList: [productFulfillmentTypeValue],
    customLinkName: analyticsData.customLinkName,
    productBrandList: [analyticsData.productBrand],
    productPriceList: [String(analyticsData.price)],
    originalPartTypeIdList: [String(analyticsData.originalPartTypeId)],
    productRepositoryIdList: [analyticsData.productRepositoryId ?? ''],
    productPartNumberList: [analyticsData.productPartNumber ?? ''],
    pageName: analyticsData.pageName,
    pageType: analyticsData.pageType,
    productRecommendationTypeList: analyticsData.productRecommendationTypeList,
    productStrikeThroughPricing: analyticsData.productStrikeThroughPricing,
    productFulfillmentOptionSeenByCustomer: [
      String(analyticsData.productFulfillmentOptionSeenByCustomer),
    ],
  };

  if (analyticsData.pfmLocation) {
    data.pfmLocation = analyticsData.pfmLocation;
  }

  if (analyticsData.productFindingMethod) {
    data.productFindingMethod = analyticsData.productFindingMethod;
  }

  if (analyticsData.fulfillmentChange !== '') {
    data.fulfillmentChange = analyticsData.fulfillmentChange;
  }

  if (analyticsData.corePrice !== 0) {
    data.productCorePriceList = [String(analyticsData.corePrice)];
  }

  if (productAvailableQuantity !== undefined) {
    data.productAvailableQuantityList = [productAvailableQuantity];
  }

  const isAffinityRecommendation = sessionStorage.getItem('pdpUtagLinkAffinity') === 'true';
  if (isAffinityRecommendation) {
    clickTrack({
      ...data,
      productRecommendationTypeList: ['Affinity'],
      productFindingMethod: ['Affinity'],
      pfmLocation: 'Affinity:catalog_product_detail',
      customLinkName: 'catalog_product_detail:Affinity:Cart Addition',
      siteSection: siteSections.siteSectionCatalog,
    });
    sessionStorage.removeItem('pdpUtagLinkAffinity');
  } else {
    clickTrack(data);
  }
};

export const useAddToCartWithMiniCart = (inDrawer = false): UseAddToCartWithMiniCartResult => {
  const { reset, status, mutate, error, data } = useAddToCart();
  const dispatch = useDispatch();
  const [cartSuccessNote, setCartSuccessNote] = useState(false);
  const [storeAvailability, setStoreAvailability] = useState(false);
  const [quantityInCart, setQuantityInCart] = useState(0);
  const [quantity, setQuantity] = useState('0');
  const [fulfillmentTypeId, setFulfillmentTypeId] = useState<OrderType | number | undefined>(
    undefined
  );
  const [availabilityInfo, setAvailabilityInfo] = useState<FulfillmentGroup[]>([]);
  const queryClient = useQueryClient();
  const router = useRouter();
  const isCart = [routePaths.cart].includes(router.asPath);
  const isPDP = router.pathname === '/products/[...slug]';
  const isHome = router.pathname === '/';
  const isShelf = router.pathname === '/[...seoUrlPath]';
  const isSearchPage = router.pathname.includes('search');
  const isRecommendationsLandingPage = router.asPath.includes(
    routePaths.landingPageRecommendations
  );
  const isPdpCertonaAddToCartEnabled =
    useFeatureFlag('IS_PDP_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const isHomePageCertonaAddToCartEnabled =
    useFeatureFlag('IS_HOME_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const isShelfPageCertonaAddToCartEnabled =
    useFeatureFlag('IS_SHELF_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const isSearchPageCertonaAddToCartEnabled =
    useFeatureFlag('IS_SEARCH_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const miniCartEnabledForCertonaRecommendations =
    isCart ||
    inDrawer ||
    isRecommendationsLandingPage ||
    (isPDP && isPdpCertonaAddToCartEnabled) ||
    (isHome && isHomePageCertonaAddToCartEnabled) ||
    (isShelf && isShelfPageCertonaAddToCartEnabled) ||
    (isSearchPage && isSearchPageCertonaAddToCartEnabled);

  const addToCartCall = (
    values: AddToCartCallValues,
    analyticsData: AddToCartAnalyticsData | null,
    catalogVehicleId: string | undefined,
    onSuccess?: () => void,
    onSettled?: () => void
  ): void => {
    const orderType =
      typeof values.order_type === 'number'
        ? values.order_type
        : values.order_type === cartConstants.ONLINEORDER
        ? FULFILLMENT_METHODS.ONLINEORDER
        : FULFILLMENT_METHODS.STOREORDER;
    const orderTypev2 = values.orderType;
    const orderRequest = {
      skuId: values.sku_id,
      productId: values.product_id,
      quantity: String(values.quantity),
      fulfillmentTypeId:
        miniCartEnabledForCertonaRecommendations && values.requestFromCertona
          ? orderType
          : orderTypev2,
      vehicleId: catalogVehicleId,
      storeNumber: values.store_number,
      configData: values.configData,
      searchedTerm:
        typeof router.query.searchText === 'string'
          ? router.query.searchText
          : typeof router.query.filterByKeyWord === 'string'
          ? router.query.filterByKeyWord
          : undefined,
      xFusionQueryId: values.xFusionQueryId,
    };

    mutate(orderRequest, {
      onError: () => {
        setQuantity(values.quantity);
        setFulfillmentTypeId(values.order_type);
      },
      onSuccess: async (data) => {
        const availabilityInfo = getFulfillmentOptionsByGroup(
          data.lineItemFulfillmentOptions.find((items) => items.skuId === values.sku_id)
            ?.fulfillmentOptions
        );

        if (!values.isConfigureTechSkuToUseItem) {
          try {
            await dispatch(fetchMiniCartData(queryClient));
          } catch (error) {
            logger.error({
              message: 'Error fetching mini cart data',
              meta: {
                error,
              },
            });
          }
        }

        setAvailabilityInfo(availabilityInfo);
        setQuantity(values.quantity);
        setFulfillmentTypeId(values.order_type);
        setCartSuccessNote(true);

        if (onSuccess) {
          onSuccess();
        }

        if (values.isConfigureTechSkuToUseItem) {
          void router.push('/cart?fromSku=true');
        } else {
          const productAvailableQuantity =
            availabilityInfo.find((option) => option.selected)?.maxAvailableQty ?? 0;

          if (analyticsData) {
            trackAddToCartPDP(values, analyticsData, productAvailableQuantity);
          }
        }
      },
      onSettled: onSettled,
    });
  };

  const cartSuccess = data
    ? {
        ...data,
        availabilityInfo,
        orderType: fulfillmentTypeId,
        quantity: quantity,
      }
    : undefined;

  const productData = {
    readyStatus: status,
    err: error,
    cartSuccess,
    cartSuccessNote,
    storeAvailability,
    quantity,
    fulfillmentTypeId,
    quantityInCart,
  };
  const clearProductPageData = useCallback(() => {
    reset();
    setCartSuccessNote(false);
  }, [reset]);

  const changeFindProduct = () => {
    setStoreAvailability(true);
  };

  return {
    addToCartCall,
    emptyCartData: reset,
    clearProductPageData,
    changeFindProduct,
    addingToCartStatus: status,
    productData,
    err: error ? getErrorMessage(error) : null,
    setQuantityInCart,
    cartSuccess,
  };
};
