/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { orderTypes } from '../../constants/orderTypes';
import { validateEmail } from '@/utils/validator/validateEmail';
import { validateCityName } from '@/utils/validator/validateCityName';
import { validateFirstName, validateLastName } from '@/utils/validator/nameValidator';
import { validateRequired } from '@/utils/validator/validateRequired/validateRequired';
import { validateGlobalPhoneNumber } from '@/utils/validator/validateGlobalPhoneNumber';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import type { Dispatch } from '@/types';
import type { LineItemFromState, OrderItemsFromState } from '@/types/reduxStore/orders';
import type { Locale } from '@/types/i18n';
import { type QueryClient } from '@tanstack/react-query';
import { type InvokeFedxValidationFunction } from '@/actions/addressDetails';
import { type MyProfileData } from '../myProfile/interface';
import { type useUpdateMyProfile } from '../myProfile/api/updateMyProfile';

type FedexValidatorInput = {
  zipCode: any;
  invokeFedxValidation: InvokeFedxValidationFunction;
  formValues: any;
  userAuthenticated: any;
  checkoutData: any;
};

type Values = {
  address1: any;
  city: any;
  state: any;
  profileAddressAvailable: any;
  billingAvailable?: any;
  fedExValidatorInput: FedexValidatorInput;
  billingCall: boolean;
  bopusOnly?: any;
};

export const getSthOrders = (
  orders: OrderItemsFromState
): {
  count: number;
  orders: {
    freight: LineItemFromState[];
    instore: LineItemFromState[];
    nxtDayDelivery: LineItemFromState[];
    regular: LineItemFromState[];
  };
} => {
  let count = 0;
  const data1: {
    nxtDayDelivery: LineItemFromState[];
    regular: LineItemFromState[];
    freight: LineItemFromState[];
    instore: LineItemFromState[];
  } = {
    nxtDayDelivery: [],
    regular: [],
    freight: [],
    instore: [],
  };
  if (orders?.length > 0) {
    const sthOrder = orders?.filter((item) => item.orderType === orderTypes.onlineOrder);
    if (sthOrder?.length > 0) {
      sthOrder[0].shipmentInfoList.forEach((item) => {
        count += item?.lineItemList?.length;
        switch (item?.shipmentType.toLowerCase()) {
          case 'nextday':
            data1.nxtDayDelivery.push(...item.lineItemList);
            break;
          case 'regular':
            data1.regular.push(...item.lineItemList);
            break;
          case 'freight':
            data1.freight.push(...item.lineItemList);
            break;
          default:
            data1.instore.push(...item.lineItemList);
            break;
        }
      });
    }
  }
  return { orders: data1, count };
};

export const findTypeOfAddressAndSave = (
  fedExValidatorInput: FedexValidatorInput,
  type: string,
  bilingValidationType = false,
  isMexico = false,
  addressV2Enabled: boolean,
  dispatch: Dispatch,
  queryClient: QueryClient,
  myProfile: MyProfileData | undefined,
  updateMyProfileMutation: ReturnType<typeof useUpdateMyProfile>,
  isRunFedexValidation?: boolean
) => {
  const { invokeFedxValidation, formValues } = fedExValidatorInput;
  const phoneNumber = formValues.phoneNumber?.replace(/[^\d]/g, '') || '';
  const address = {
    firstName: formValues.firstName || '',
    lastName: formValues.lastName || '',
    address1: formValues.address1 || '',
    address2: formValues.address2 || '',
    city: formValues.city || '',
    state: formValues.state || '',
    postalCode: formValues.zipCode?.slice(0, 5) || '',
    phoneNumber,
    email: formValues.email || '',
    actionType: type,
  };

  dispatch(
    invokeFedxValidation(
      address,
      type,
      bilingValidationType,
      addressV2Enabled,
      queryClient,
      myProfile,
      updateMyProfileMutation,
      false,
      isMexico,
      isRunFedexValidation
    )
  );
};

export const preFedEx = (
  values: Values,
  addressV2Enabled: boolean,
  dispatch: Dispatch,
  queryClient: QueryClient,
  myProfile: MyProfileData | undefined,
  updateMyProfileMutation: ReturnType<typeof useUpdateMyProfile> & {
    updateProfileAddress?: boolean;
  },
  isMexico?: boolean,
  isRunFedexValidation?: boolean
) => {
  if (
    !values.billingCall &&
    values.address1 &&
    values.city &&
    values.state &&
    (values.profileAddressAvailable || values.billingAvailable)
  ) {
    findTypeOfAddressAndSave(
      values.fedExValidatorInput,
      'shipping',
      undefined,
      undefined,
      addressV2Enabled,
      dispatch,
      queryClient,
      myProfile,
      updateMyProfileMutation,
      isRunFedexValidation
    );
  } else if (
    (values.address1 && values.city && values.state && !values.billingCall) ||
    (values.billingCall && values.bopusOnly)
  ) {
    findTypeOfAddressAndSave(
      values.fedExValidatorInput,
      'shippingBilling',
      !!(values.billingCall && values.bopusOnly),
      isMexico,
      addressV2Enabled,
      dispatch,
      queryClient,
      myProfile,
      updateMyProfileMutation,
      isRunFedexValidation
    );
  } else if (values.billingCall && !values.bopusOnly) {
    findTypeOfAddressAndSave(
      values.fedExValidatorInput,
      'billing',
      true,
      isMexico,
      addressV2Enabled,
      dispatch,
      queryClient,
      myProfile,
      updateMyProfileMutation,
      isRunFedexValidation
    );
  }
};

export const getBopusOrders = (orders: OrderItemsFromState) => {
  const bopusOrderData: (LineItemFromState & { deliveryDate: string | undefined })[] = [];
  if (orders?.length > 0) {
    const storeOrder = orders?.filter((item) => item.orderType === orderTypes.storeOrder);

    if (storeOrder?.length > 0) {
      storeOrder[0]?.shipmentInfoList?.forEach((item) => {
        item?.lineItemList.forEach((lineItemList) => {
          bopusOrderData.push({ ...lineItemList, deliveryDate: item?.estimatedDeliveryDate });
        });
      });
    }
  }
  return bopusOrderData;
};

export const validateFormForPrefill = (
  formValues: any,
  formatPhone?: boolean,
  locale?: Locale
): boolean => {
  if (!formValues || Object.keys(formValues).length === 0) {
    return true;
  }

  // /* PhoneNumber shoud be in format "(XXX) XXX-XXXX" to pass validation. Ensuring formatting could
  // done explicitly with formatPhone parameter or deducted searching for ( */
  const phoneNumberValue =
    formatPhone || !formValues.phoneNumber.includes('(')
      ? formatPhoneNumber(formValues.phoneNumber)
      : formValues.phoneNumber;

  const phoneValidatorFunction = validateGlobalPhoneNumber('ERROR');

  /* Validation functions returns null or undefined if no mistakes where found */
  const validationResults = {
    firstNameHasErrors: Boolean(validateFirstName(formValues.firstName, locale)),
    lastNameHasErrors: Boolean(validateLastName(formValues.lastName, locale)),
    phoneNumberHasErrors: phoneValidatorFunction(phoneNumberValue) === 'ERROR',
    emailHasErrors: Boolean(validateEmail(formValues.email)),
    address1HasErrors: Boolean(validateRequired(formValues.address1, locale)),
    cityHasErrors:
      Boolean(validateRequired(formValues.city)) ||
      Boolean(validateCityName(formValues.city, locale)),
    stateHasErrors: Boolean(validateRequired(formValues.state)),
    zipCode: Boolean(validateRequired(formValues.zipCode)),
  };

  if (Object.values(validationResults).includes(true)) {
    return false;
  }
  return true;
};

export const onlyNumbers = (value: string): string => {
  return value.replace(/[^\d]/g, '');
};
