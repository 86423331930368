/**
 * Copyright 2019-2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState, useRef, useCallback } from 'react';
import styles from '../../styles.module.scss';
import { Fade } from '@/components/Fade/Fade';
import { MenuNavButton } from './MenuNavButton';
import type { Props as MenuDrawerProps } from './MenuDrawer';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import ProgressModal from '@/components/AZCustomComponent/ProgressModal';

const LazyMenuDrawer = dynamic<MenuDrawerProps>(() => import('./MenuDrawer'), {
  loading: () => <ProgressModal noScroll />,
});

type Props = {
  scrolled?: boolean;
  dataTestIdBtn?: string;
  onToggle?: (open: boolean) => void;
  id?: string;
  isFixedHeader?: boolean;
};

export function MenuNav({ scrolled, dataTestIdBtn, onToggle, id, isFixedHeader }: Props) {
  const [open, setOpen] = useState(false);

  const menuNavRef = useRef<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen(true);
    onToggle && onToggle(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    onToggle && onToggle(false);
  }, [onToggle]);

  return (
    <div
      className={cx(
        styles.navBarNav,
        { [styles.navBarNavSticky]: isFixedHeader },
        styles.navMenuContainer
      )}
      data-testid="mobile-menu-nav-button"
      ref={menuNavRef}
    >
      <MenuNavButton
        id={id}
        open={open}
        handleClick={handleClick}
        handleClose={handleClose}
        dataTestIdBtn={dataTestIdBtn}
        isFixedHeader={isFixedHeader}
      />
      <Fade open={open}>
        <LazyMenuDrawer scrolled={scrolled} open={open} onClose={handleClose} />
      </Fade>
    </div>
  );
}
