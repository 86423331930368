/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState, useEffect } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import Image, { pureLoader } from '@/components/NextImage';
import root from 'window-or-global';
import type { CertonaPricing, AddToCartData } from '@/types';
import { replaceHistoryStateOptionsOnClick } from '@/features/common/utils/replaceHistoryStateOptionsOnClick';
import { historyStateOptionAttributes } from '@/constants/historyStateOption';
import { useActiveElement } from '@/hooks/useActiveElement';
import { useLabels } from '@/hooks/useLabels';
import { Button } from '@/components/Button';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useAddToCart } from '@/features/orders/api/postAddToCart';
import { useAddToCartWithMiniCart } from '../../hooks/useAddToCartWithMiniCart';
import type { AddCertonaItemObjectType, CtjAnalyticsType } from '../../interface';
import { SmartLink as Link } from '@/utils/smartLink';
import { Rating } from '@/components/Rating/Rating';
import Hidden from '@/components/Hidden';
import { LoadingIndicatorDots } from '@/components/LoadingIndicatorDots';
import { sessionStorage } from '@/utils/sessionStorage';
import { trackLinkFromCertonaCartridge } from '@/utils/analytics/track/trackLinkFromCertonaCartridge';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useDeviceType } from '@/utils/useDeviceType';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { fetchPaymentOptions } from '@/actions/checkout';
import { formatPrice } from '@/utils/validator/formatPrice';
import { type OrderType } from '@/constants/orderTypes';
import type { CustomLinkName } from '@/types/analytics';
import { View, Text } from '@az/starc-ui';

type AddToCartRequestType = {
  sku_id: string;
  product_id: string;
  quantity: string;
  category_id: string;
  order_type: OrderType | number;
  catalog_vehicle_id: string;
  store_number: string;
  orderType: string;
  requestFromCertona: boolean;
};

type Props = {
  part: {
    properties: {
      seoUrl: string;
      imageUrl: string;
      refPageType: string;
      makeModelYearPath: string | undefined;
    };
    label: string;
    rating?: string;
    totalReviews?: string;
    averageRating?: string;
  };
  actionHandler: (b: string, a: string) => void;
  pricingInfo?: CertonaPricing | null;
  addToCartData?: AddToCartData | null;
  itemId?: string;
  dynamicRef?: HTMLElement;
  onkeydown?: boolean;
  showCard?: boolean;
  addToCartAnalyticsandSuccessMessage?: (cartData: AddToCartData) => void;
  updateAfterAddtoCartClick?: (item?: AddToCartData) => void;
  showAddtoCartCTA?: boolean;
  isOos?: boolean;
  doesNotFit?: boolean;
  lastIndex?: boolean;
  partsListLength?: number;
  shelfSeoUrl?: string;
  setAddedCertonaItemObject?: React.Dispatch<React.SetStateAction<AddCertonaItemObjectType | null>>;
  inDrawer?: boolean;
  cardStyle?: 'large_stacked' | 'large_scrolled' | 'horizontal_stacked' | 'horizontal_scrolled';
  isCompleteTheJob?: boolean;
  getCtjAnalytics?: (skuId: string) => CtjAnalyticsType;
};

const labelMap = {
  priceUnavailable: 'label_ProductDisplayPage_body_PriceUnavailable',
  clickToSeePrice: 'label_ProductDisplayPage_body_ClickToSeePrice',
  lblCarouselImage: 'label_Carousel_Image',
  lblAdd: 'label_MyAccountVehicle_maintenanceInterval_Add',
  lblToCart: 'label_ProductDisplayPage_body_ToCart',
  lblAddToCart: 'label_add_to_cart_certona',
  lblAdded: 'label_added_to_cart_certona',
  lblTotalReviews: 'label_Carousel_TotalReviews',
  lblOutOfFiveStars: 'label_Carosuel_OutOfFiveStars',
  lblOptions: 'label_certonaOptions',
};

export const HorizontalPartCard = (props: Props) => {
  const {
    part: { properties, label: productLinkName, totalReviews, averageRating },
    actionHandler,
    itemId = '',
    pricingInfo,
    dynamicRef,
    onkeydown,
    addToCartAnalyticsandSuccessMessage,
    showCard = true,
    addToCartData,
    showAddtoCartCTA,
    isOos,
    doesNotFit,
    setAddedCertonaItemObject,
    updateAfterAddtoCartClick,
    inDrawer,
    cardStyle,
    isCompleteTheJob,
    getCtjAnalytics,
  } = props;

  const {
    priceUnavailable,
    clickToSeePrice,
    lblAdd,
    lblToCart,
    lblAdded,
    lblTotalReviews,
    lblOutOfFiveStars,
    lblOptions,
  } = useLabels(labelMap);
  const router = useRouter();
  const dispatch = useDispatch();
  const isOosOrDoesNotFit = isOos || doesNotFit;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const deviceType = useDeviceType();
  const isBot = deviceType === 'bot';
  const certonaAddToCartEnabled = !isBot && showAddtoCartCTA;
  const [showLoadingIndicators, setShowLoadingIndicators] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);
  const { addToCartCall, addingToCartStatus, cartSuccess } = useAddToCartWithMiniCart(inDrawer);
  const addProductToCart = `${lblAdd} ${productLinkName} ${lblToCart}`;
  const { corePrice, retailPrice, totalPrice, configurableSku } = pricingInfo ?? {};
  const focusedElement = useActiveElement();
  const isCart = router.asPath === routePaths.cart;

  const scrollIntoViewOptions: ScrollIntoViewOptions = {
    block: 'nearest',
    inline: 'nearest',
    behavior: 'auto',
  };
  const { data: storeDetailsData } = useStoreDetailsData();
  const addToCartMutation = useAddToCart();

  const addCertonaItemObject: AddCertonaItemObjectType = {
    productImage: properties?.imageUrl ?? '',
    cartSuccessData: cartSuccess,
    productDetails: {
      productName: {
        productBrand: addToCartData?.brandName ?? '',
        productPart: addToCartData?.productPartNumber ?? '',
        quickNote: addToCartData?.productRepositoryId ?? '',
      },
      skuId: addToCartData?.skuId,
      partType: addToCartData?.originalPartTypeId?.toString(),
      productId: addToCartData?.productId,
    },
    submitToCart: () => void router.push(routePaths.cart),
    productTitle: productLinkName ?? '',
    pageName: 'pdp',
  };

  const afterAddTocartSuccess = () => {
    setShowLoadingIndicators(false);
    setAddedToCart(true);
    const addToCartAnalyticsData = {
      price: totalPrice?.toFixed(2),
      imageUrl: properties.imageUrl,
      label: productLinkName,
      ...addToCartData,
    };
    addToCartAnalyticsandSuccessMessage &&
      addToCartAnalyticsandSuccessMessage(addToCartAnalyticsData);
  };

  const newAfterAddToCartSuccess = async () => {
    setShowLoadingIndicators(false);
    setAddedToCart(true);
    await dispatch(fetchPaymentOptions());
    const addToCartAnalyticsData = {
      price: totalPrice?.toFixed(2),
      imageUrl: properties.imageUrl,
      label: productLinkName,
      ...addToCartData,
    };
    if (inDrawer) {
      updateAfterAddtoCartClick?.(addToCartAnalyticsData);
    } else {
      addToCartAnalyticsandSuccessMessage &&
        addToCartAnalyticsandSuccessMessage(addToCartAnalyticsData);
    }
    setAddedCertonaItemObject?.(addCertonaItemObject);
  };

  useEffect(() => {
    if (onkeydown && focusedElement === dynamicRef) {
      focusedElement.scrollIntoView(scrollIntoViewOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedElement]);

  const handleClick = () => {
    actionHandler(productLinkName, itemId);
    replaceHistoryStateOptionsOnClick(
      historyStateOptionAttributes.latestSelectedPageYOffset,
      root.pageYOffset
    );
  };

  const handleNavigateToPDP = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    handleClick();
    void router.push(properties.seoUrl);
  };

  const handleAddtoCartCtaClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (addedToCart) {
      return;
    }

    if (showAddtoCartCTA && ![routePaths.cart].includes(router.asPath)) {
      return handleAddtoCartNewCtaClick();
    }

    setShowLoadingIndicators(true);
    const addToCartRequest = {
      skuId: String(addToCartData?.skuId),
      productId: addToCartData?.productId,
      quantity: '1',
      fulfillmentTypeId: addToCartData?.fulFillmentId,
      vehicleId: addToCartData?.vehicleId,
      storeNumber: String(storeDetailsData?.storeNumber),
    };
    if (!addToCartMutation.isLoading) {
      await addToCartMutation.mutateAsync(addToCartRequest);
      afterAddTocartSuccess();
    }
  };

  const handleAddtoCartNewCtaClick = () => {
    setShowLoadingIndicators(true);
    if (addingToCartStatus !== 'loading') {
      const { pageName = '', pageType = '' } = root.utag_data ?? {};

      const onSuccess = () => {
        void newAfterAddToCartSuccess();
      };

      const onSettled = () => {
        setShowLoadingIndicators(false);
      };

      let addToCartAnalyticsDataForCall = {
        price: totalPrice?.toFixed(2) ?? '',
        pageName: pageName,
        customLinkName: `${
          isOos || doesNotFit ? `PDP${isOos ? ' Out of Stock' : " Doesn't fit"}` : pageType
        }-Certona:Add_to_Cart` as CustomLinkName,
        pageType: pageType,
        productRecommendationTypeList: isCompleteTheJob ? 'Affinity' : 'Certona',
        productBrand: addToCartData?.brandName ?? '',
        productStrikeThroughPricing: ['0'],
        corePrice: corePrice,
        fulfillmentChange: '',
        originalPartTypeId: addToCartData?.originalPartTypeId,
        productRepositoryId: addToCartData?.productRepositoryId,
        productPartNumber: addToCartData?.productPartNumber,
        productFulfillmentOptionSeenByCustomer: Number(addToCartData?.fulFillmentId),
        ...addToCartData,
      };

      const ctjAnalytics =
        isCompleteTheJob && getCtjAnalytics ? getCtjAnalytics(addToCartData?.skuId || '') : {};

      addToCartAnalyticsDataForCall = {
        ...addToCartAnalyticsDataForCall,
        ...ctjAnalytics,
      };

      const addToCartRequest: AddToCartRequestType = {
        sku_id: String(addToCartData?.skuId),
        product_id: addToCartData?.productId ?? '',
        quantity: '1',
        category_id: '',
        order_type: addToCartData?.fulFillmentId ?? ('' as OrderType),
        catalog_vehicle_id: addToCartData?.vehicleId ?? '',
        store_number: String(storeDetailsData?.storeNumber),
        orderType: '',
        requestFromCertona: true,
      };

      addToCartCall(
        addToCartRequest,
        addToCartAnalyticsDataForCall,
        catalogVehicleId,
        onSuccess,
        onSettled
      );
    }
  };

  const { data: headerData } = useHeaderData();
  const skusPresentInCart = headerData?.analyticsData.cartProductSkus;
  const catalogVehicleId = headerData?.vehicleMap.catalogVehicleId ?? '';

  useEffect(() => {
    const skuId = pricingInfo?.skuId || itemId;
    if (skusPresentInCart?.includes(skuId)) {
      setAddedToCart(true);
    }
  }, [skusPresentInCart, itemId, pricingInfo]);

  if (!showCard) {
    return null;
  }

  return (
    <div className={isOosOrDoesNotFit ? styles.horizontalPartCardContainer : ''}>
      <div
        className={cx(styles.horizontalPartCard, {
          [styles.horizontalPartCardForOos]: isOosOrDoesNotFit,
          [styles.horizontalPartCardForCart]: isCart,
          [styles.horizontalScrolled]: cardStyle === 'horizontal_scrolled',
          [styles.horizontalStacked]: cardStyle === 'horizontal_stacked' && !inDrawer,
        })}
      >
        <Link
          to={properties.seoUrl}
          key={properties.seoUrl}
          data-testid="part-link"
          onClick={() => {
            handleClick();
            if (!isCart) {
              trackLinkFromCertonaCartridge(productLinkName);
              sessionStorage.setItem(
                'productFindingMethod',
                isCompleteTheJob ? 'Affinity' : 'Certona Clicks'
              );
            }
          }}
          className={cx(styles.gridContainerPartCard, {
            [styles.gridContainerPartCardWithoutCta]: !certonaAddToCartEnabled,
          })}
        >
          <div className={styles.certonaPartImgContainer}>
            <Image
              src={properties.imageUrl}
              alt=""
              data-testid="part-img"
              width={64}
              height={64}
              loader={pureLoader}
            />
          </div>
          <div className={styles.productInfo}>
            <View direction="row" align="center" height="26px">
              <View direction="column" justify="center" height="100%">
                <Text variant="large-body" weight="bold" className={styles.starcLargeBodyOverride}>
                  {!pricingInfo?.configurableSku && totalPrice === null ? (
                    <span className={styles.pricingInfo}>{priceUnavailable}</span>
                  ) : null}
                  {!configurableSku && typeof totalPrice === 'number'
                    ? `$${corePrice ? formatPrice(totalPrice) : formatPrice(retailPrice)}`
                    : null}
                  {configurableSku && totalPrice !== null ? (
                    <span className={styles.pricingInfo}>{clickToSeePrice}</span>
                  ) : null}
                </Text>
              </View>
              <View.Item gapBefore={{ s: 2, m: 1, l: 1 }} />
              {averageRating && Number(averageRating) > 0 && totalReviews ? (
                <>
                  <View direction="column" justify="center" height="100%">
                    <Rating
                      value={Number(averageRating)}
                      max={5}
                      isOosOrDoesNotFit={isOosOrDoesNotFit}
                      showStarFractions
                      isCertonaPartCard={true}
                    />
                    <span className="sr-only">
                      {`${Number(averageRating).toFixed(1)} ${lblOutOfFiveStars}`}
                    </span>
                  </View>
                  <View.Item gapBefore={{ s: 1, m: 2, l: 2 }} />
                  <View direction="column" justify="center" height="100%">
                    <Text
                      variant="subtitle-regular"
                      className={styles.starcSubtitleRegularOverride}
                    >{`${Number(averageRating).toFixed(1)} (${totalReviews})`}</Text>
                    <span className="sr-only">{`${totalReviews} ${lblTotalReviews}`}</span>
                  </View>
                </>
              ) : null}
            </View>
            <div className={styles.nameAndCTAContainer}>
              <div
                className={cx(
                  styles.partLabel,
                  styles.certonaPartLabel,
                  styles.partLabelRecommendation,
                  {
                    [styles.certonaPartLabelForOos]: isOosOrDoesNotFit,
                  }
                )}
                data-testid="part-label"
              >
                {productLinkName}
              </div>
              {certonaAddToCartEnabled && (
                <div
                  onClick={(e) => e.stopPropagation() /*prevents <Link/> ancestor onClick */}
                  className={styles.addToCartButtonGridArea}
                >
                  <Hidden smDown implementation="js">
                    {isOosOrDoesNotFit ? (
                      <Button
                        ariaLabel={addProductToCart}
                        variant="outlined"
                        shape={isTablet ? 'rounded' : 'pill'}
                        size="small"
                        customClass={cx(
                          isTablet ? styles.addtoCartButtonMobileForOos : styles.addtoCartButton,
                          {
                            [styles.configSkuMobileCTA]: configurableSku && !isTablet,
                            [styles.addedTocartButtonstyles]: addedToCart && !isTablet,
                          }
                        )}
                        onClick={configurableSku ? handleNavigateToPDP : handleAddtoCartCtaClick}
                      >
                        {!showLoadingIndicators && configurableSku && isDesktop && (
                          <span>{lblOptions.toUpperCase()}</span>
                        )}
                        {!showLoadingIndicators &&
                          (!configurableSku || (configurableSku && isTablet)) &&
                          !addedToCart && (
                            <>
                              <Image
                                src={'/images/addcart.svg'}
                                alt=""
                                width={20}
                                height={20}
                                loader={pureLoader}
                              />
                              {!showLoadingIndicators &&
                                !addedToCart &&
                                !configurableSku &&
                                !isTablet && (
                                  <span className={styles.addtoCartText}> {lblAdd}</span>
                                )}
                            </>
                          )}
                        {!showLoadingIndicators &&
                          (!configurableSku || (configurableSku && isTablet)) &&
                          addedToCart && (
                            <>
                              <Image
                                src={'/images/addedtocart.svg'}
                                alt=""
                                width={20}
                                height={20}
                                loader={pureLoader}
                              />
                              {!showLoadingIndicators &&
                                addedToCart &&
                                !configurableSku &&
                                !isTablet && (
                                  <span className={styles.addtoCartText}> {lblAdded}</span>
                                )}
                            </>
                          )}
                        {showLoadingIndicators && <LoadingIndicatorDots size={8} color={'#000'} />}
                      </Button>
                    ) : (
                      <Button
                        ariaLabel={addProductToCart}
                        variant="outlined"
                        shape="pill"
                        size="small"
                        customClass={cx(styles.addtoCartButton, {
                          [styles.addedTocartButtonstyles]: addedToCart,
                        })}
                        onClick={configurableSku ? handleNavigateToPDP : handleAddtoCartCtaClick}
                      >
                        {!showLoadingIndicators && configurableSku && (
                          <span>{lblOptions.toUpperCase()}</span>
                        )}
                        {!showLoadingIndicators && !addedToCart && !configurableSku && (
                          <>
                            <Image
                              src={'/images/addcart.svg'}
                              alt=""
                              width={15}
                              height={15}
                              loader={pureLoader}
                            />
                            <span className={styles.addtoCartText}> {lblAdd}</span>
                          </>
                        )}
                        {!showLoadingIndicators && addedToCart && !configurableSku && (
                          <>
                            <Image
                              src={'/images/green-tick-rounded.svg'}
                              alt=""
                              width={12}
                              height={12}
                              loader={pureLoader}
                            />
                            <span className={styles.addtoCartText}> {lblAdded}</span>
                          </>
                        )}
                        {showLoadingIndicators && <LoadingIndicatorDots size={12} color={'#000'} />}
                      </Button>
                    )}
                  </Hidden>
                  <Hidden mdUp implementation="js">
                    <Button
                      ariaLabel={addProductToCart}
                      variant="outlined"
                      shape={isMobile ? 'rounded' : 'pill'}
                      size="small"
                      customClass={cx(styles.addtoCartButtonMobile, {
                        [styles.configSkuMobileCTA]: configurableSku && !isOosOrDoesNotFit,
                      })}
                      onClick={configurableSku ? handleNavigateToPDP : handleAddtoCartCtaClick}
                    >
                      {!showLoadingIndicators && configurableSku && !isOosOrDoesNotFit && (
                        <span>{lblOptions.toUpperCase()}</span>
                      )}
                      {!showLoadingIndicators &&
                        (!configurableSku || (configurableSku && isOosOrDoesNotFit)) &&
                        !addedToCart && (
                          <Image
                            src={'/images/addcart.svg'}
                            alt=""
                            width={20}
                            height={20}
                            loader={pureLoader}
                          />
                        )}
                      {!showLoadingIndicators &&
                        (!configurableSku || (configurableSku && isOosOrDoesNotFit)) &&
                        addedToCart && (
                          <Image
                            src={'/images/addedtocart.svg'}
                            alt=""
                            width={20}
                            height={20}
                            loader={pureLoader}
                          />
                        )}
                      {showLoadingIndicators && <LoadingIndicatorDots size={8} color={'#000'} />}
                    </Button>
                  </Hidden>
                </div>
              )}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
