/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { getAxiosForClient } from '@/lib/axios';
import {
  type QueryClient,
  type UseQueryOptions,
  useQuery,
  type QueryFunctionContext,
} from '@tanstack/react-query';
import type { FormattedContentStackCategoryPages } from '@/features/contentstack/interface';
import { getCategoryContent } from '@/features/contentstack/lib/category/getCategoryContent'; // eslint-disable-line no-restricted-imports
import { type PageContextType } from '@/next-types';
import { usePageType } from '../api/getPageType';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';
import { pageTypes } from '@/constants/page';
import { useSegments } from '@/features/header/api/getSegments';

const URL = `/api/contentstack/category`;

const getCMSCategoryData = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCategoryDataKey>>) => {
  const [, , { segments, taxonomyPath, pageType }] = queryKey;
  const { data } = await getAxiosForClient().get<FormattedContentStackCategoryPages>(URL, {
    params: { taxonomyPath, pageType, segments: segments.join(',') },
  });

  return selector(data);
};

const selector = (data: FormattedContentStackCategoryPages): FormattedContentStackCategoryPages =>
  data;

const getCategoryDataKey = (taxonomyPath: string, pageType: string, segments: string[]) =>
  ['cms', 'categorypage', { pageType, segments, taxonomyPath }] as const;

export const useCMSCategoryData = () => {
  const { data: pageTypeData } = usePageType();

  const isCategoryPage = pageTypeData?.pageType === pageTypes.CategoryGrouping;

  const enabled = useMonetateDecisionFlag('cmsCategoryEnabled') && isCategoryPage;

  const taxonomyPath = `${pageTypeData?.taxonomyPath ?? ''}${
    pageTypeData?.makeModelYearPath ?? ''
  }`;
  const pageType = pageTypeData?.subPageType ?? '';

  const data = useGetCMSCategoryData({
    enabled,
    taxonomyPath,
    pageType,
  });

  return {
    enabled,
    ...data,
  };
};

const useGetCMSCategoryData = (
  options: UseQueryOptions & { taxonomyPath: string; pageType: string }
) => {
  const { data: segments, isSuccess } = useSegments();
  return useQuery({
    queryKey: getCategoryDataKey(options.taxonomyPath, options.pageType, segments?.sort() ?? []),
    queryFn: getCMSCategoryData,
    enabled: options.enabled && isSuccess,
  });
};

export const prefetchCMSCategoryData = (
  queryClient: QueryClient,
  taxonomyPath: string,
  pageType: string,
  context: PageContextType,
  userSegments: string[]
) => {
  return queryClient.prefetchQuery({
    queryKey: getCategoryDataKey(taxonomyPath, pageType, userSegments),
    queryFn: () =>
      getCategoryContent(context.req, { ...context.query, taxonomyPath, pageType }, userSegments),
  });
};
