/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React from 'react';
import { type ContentStackBanner, type ContentStackBannerSplit } from '../../interface';
import { CMSBanner, type CMSBannerProps } from '../CMSBanner/CMSBanner';
import { Grid } from '@/components/Grid';
import { type Props as HeadlineProps } from '@/components/Headline/Headline';
import { CMSHeadline } from '../CMSHeadline/CMSHeadline';
import styles from './CMSBannerSplit.module.scss';

type Props = {
  content: ContentStackBannerSplit;
  headlineOptions?: HeadlineProps;
  cmsBannerOptions?: Partial<CMSBannerProps>;
  priority?: boolean;
};

export function CMSBannerSplit({
  content,
  headlineOptions,
  cmsBannerOptions,
  priority = false,
}: Props) {
  const { banner_split, headline, drop_shadow, layout, asset_size } = content;
  if (!banner_split) return null;

  return (
    <Grid container spacing={0}>
      {headline && <CMSHeadline {...headlineOptions}>{headline}</CMSHeadline>}
      <Grid container spacing={2}>
        {banner_split.map((banner, i) => (
          <Grid item key={i} xs={12} className={styles[`grid-size-${layout.toLowerCase()}`]}>
            <CMSBanner
              priority={i === 0 ? priority : undefined}
              loading={i !== 0 && priority ? 'eager' : undefined}
              content={{ ...banner, asset_size, title: '' } as ContentStackBanner}
              dropShadowSize={drop_shadow}
              {...cmsBannerOptions}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
