/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxiosForClient } from '@/lib/axios';
import type { FormattedContentStackPremiumPDP } from '@/features/contentstack/interface';
import { getContentStackPremiumPDPContent } from '@/features/contentstack/lib/productDetailsPage/getPremiumContent'; // eslint-disable-line no-restricted-imports
import { type PageContextType } from '@/next-types';
import {
  type QueryClient,
  useQuery,
  type QueryFunctionContext,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { useSegments } from '@/features/header/api/getSegments';

const URL = `/api/contentstack/productDetailsPages/premium`;

type Options = {
  taxonomyPath: string;
  brandName: string;
};

const getContentStackPremiumPdpContent = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getPremiumPDPKey>>) => {
  const [, , , { segments, taxonomyPath, brandName }] = queryKey;
  // NOTE: this is needed due to https://github.com/TanStack/query/issues/1196 (despite setting the enabled option to false, useQuery still initiates a fetch)
  if (!taxonomyPath) return null;
  const response = await getAxiosForClient().get<FormattedContentStackPremiumPDP>(URL, {
    params: {
      segments: segments.join(','),
      taxonomyPath,
      brandName,
    },
  });
  return selector(response.data);
};

const selector = (data: FormattedContentStackPremiumPDP) => {
  return data;
};

export const getPremiumPDPKey = (taxonomyPath: string, segments: string[], brandName: string) =>
  ['cms', 'premium', 'pdp', { segments, taxonomyPath, brandName }] as const;

export const useContentStackPremiumPDPContent = (
  options: Omit<
    UseQueryOptions<
      FormattedContentStackPremiumPDP,
      unknown,
      FormattedContentStackPremiumPDP,
      ReturnType<typeof getPremiumPDPKey>
    >,
    'queryKey' | 'queryFn'
  > &
    Options
) => {
  const { data: segments, isSuccess } = useSegments();
  const isEnabled = options.enabled && !!options.taxonomyPath && isSuccess;
  const queryResult = useQuery({
    queryFn: getContentStackPremiumPdpContent,
    queryKey: getPremiumPDPKey(options.taxonomyPath, segments?.sort() ?? [], options.brandName),
    enabled: isEnabled,
  });

  return {
    ...queryResult,
    isLoading: isEnabled ? queryResult.isLoading : false,
  };
};

export const prefetchContentStackPremiumPDPContent = (
  queryClient: QueryClient,
  taxonomyPath: string,
  context: PageContextType,
  userSegments: string[],
  brandName: string
) => {
  return queryClient.prefetchQuery({
    queryKey: getPremiumPDPKey(taxonomyPath, userSegments, brandName),
    queryFn: () =>
      getContentStackPremiumPDPContent(
        context.req,
        {
          ...context.query,
          taxonomyPath,
          brandName,
        },
        userSegments
      ),
  });
};

export const getContentStackPremiumPDPContentFromCache = (
  queryClient: QueryClient,
  taxonomyPath: string,
  userSegments: string[],
  brandName: string
) => {
  return queryClient.getQueryData<FormattedContentStackPremiumPDP>(
    getPremiumPDPKey(taxonomyPath, userSegments, brandName)
  );
};
