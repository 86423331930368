/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { CartAnalytics } from './types';
import { type OrderDetailsData } from '@/types/index';

export default function cartAnalyticsAdapter(orderDetails: OrderDetailsData): CartAnalytics {
  const orders = orderDetails?.orders || [];
  const lineItems = getLineItemsFromOrderDetails(orders);
  return lineItems.reduce(
    (result: CartAnalytics, lineItem = {}) => {
      // @ts-expect-error refine type
      const { lineItemPriceInfo = {}, productInfo = {} } = lineItem || {};
      const { corePrice, discountPrice, unitPrice } = lineItemPriceInfo || {};
      const { partNumber, skuId, skuDisplayName, brand } = productInfo || {};
      return {
        cartCorePrice: [...result.cartCorePrice, corePrice],
        cartDiscountPriceList: [...result.cartDiscountPriceList, discountPrice],
        cartProductPartIds: [...result.cartProductPartIds, partNumber],
        cartProductSkus: [...result.cartProductSkus, skuId],
        cartProductTitles: [...result.cartProductTitles, skuDisplayName],
        cartProductVendors: [...result.cartProductVendors, brand],
        cartUnitPrice: [...result.cartUnitPrice, unitPrice],
      };
    },
    {
      cartCorePrice: [],
      cartDiscountPriceList: [],
      cartProductPartIds: [],
      cartProductSkus: [],
      cartProductTitles: [],
      cartProductVendors: [],
      cartUnitPrice: [],
    }
  );
}

function getLineItemsFromOrderDetails(orders: Array<any>) {
  return orders.reduce((result, order) => {
    const shipmentInfoList = order.shipmentInfoList || [];
    let lineItemList: any[] = [];
    shipmentInfoList.forEach((shipmentInfo = {}) => {
      // @ts-expect-error refine type
      if (shipmentInfo.lineItemList) {
        // @ts-expect-error refine type
        lineItemList = [...lineItemList, ...shipmentInfo.lineItemList];
      }
    });
    return [...result, ...lineItemList];
  }, []);
}
