/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { Fragment } from 'react';
import { type ContentStackSeo } from '../../interface';
import Head from 'next/head';

type Props = {
  seoData: ContentStackSeo;
};

export function CMSSeoTags({ seoData }: Props) {
  const hasAlternateOgTitle =
    seoData.attributes?.some((attribute) => attribute.meta_name === 'og:title') ?? false;
  return (
    <Head>
      <title>{seoData.page_title}</title>
      <link key="linkCanonical" rel="canonical" href={seoData.canonical_url} />
      <meta key="metaDescription" name="description" content={seoData.meta_description} />
      {!hasAlternateOgTitle && <meta property="og:title" content={seoData.page_title} />}
      {seoData.attributes?.length &&
        seoData.attributes.map((attribute) => (
          <Fragment key={attribute.meta_name}>
            {attribute.meta_name === 'robots' ? (
              <meta name={attribute.meta_name} content={attribute.meta_value} />
            ) : (
              <meta property={attribute.meta_name} content={attribute.meta_value} />
            )}
          </Fragment>
        ))}
    </Head>
  );
}
