/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import styles from './CMSTermsAndConditions.module.scss';
import { SmartLink } from '@/utils/smartLink';
import { Accordion, View } from '@az/starc-ui';
import { useRouter } from 'next/navigation';
import { useState } from 'react';

export type Group = {
  link: Array<{
    title: string;
    href: string;
  }>;
  menu_heading: string;
  section_id: string;
};

const CMSSubsectionGroup = ({ link, menu_heading, section_id }: Group) => {
  const router = useRouter();
  const hasLinks = link && link.length > 0;
  const [isOpen, setOpen] = useState(false);

  const headerClassName = `${styles.mainTopicHeader} ${!hasLinks ? styles.noLinksSection : ''}`;
  return (
    <Accordion
      headerOptions={{
        headerElement: (
          <div
            className={styles.topicHeaderText}
            onClick={() => {
              router.push(section_id);
            }}
          >
            {menu_heading}
          </div>
        ),
      }}
      className={headerClassName}
      onToggle={(open) => setOpen(open && hasLinks)}
      open={isOpen}
    >
      {hasLinks &&
        link.map((link, index) => (
          <View key={`${menu_heading}-${index}`} className={styles.subsectionLinkContainer}>
            <SmartLink
              className={styles.subsectionLink}
              to={link.href}
              key={`${link.href}-${link.title}`}
              title={link.title}
            >
              {link.title}
            </SmartLink>
          </View>
        ))}
    </Accordion>
  );
};

export default CMSSubsectionGroup;
