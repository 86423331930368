/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import { type Context } from './FormControlContext';

export type State =
  | 'variant'
  | 'margin'
  | 'disabled'
  | 'error'
  | 'filled'
  | 'focused'
  | 'required'
  | 'color'
  | 'hiddenLabel';

type Options = {
  props: { [key in State | string]: any };
  states: Array<State>;
  muiFormControl?: Context;
};

export function formControlState({ props, states, muiFormControl }: Options) {
  return states.reduce((acc, state) => {
    // @ts-expect-error refine type
    acc[state] = props[state];

    if (muiFormControl) {
      if (typeof props[state] === 'undefined') {
        // @ts-expect-error refine type
        acc[state] = muiFormControl[state];
      }
    }

    return acc;
  }, {} as Context);
}
