/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { isAxiosError } from './isAxiosError';

export function getErrorCode(error: unknown): string | undefined {
  if (isAxiosError(error)) {
    if (
      Array.isArray(error.response?.data.errorDetails) &&
      error.response?.data.errorDetails.length > 0
    ) {
      const firstDetail = error.response?.data.errorDetails[0];
      return firstDetail.errorCode; // Return the errorCode from the first detail if it exists
    }
    return error.response?.data.errorCode;
  }
}
