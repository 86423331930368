/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const routePaths = {
  blankURL: '/',
  addServiceHistory: '/user/addServiceHistory',
  cart: '/cart',
  chat: '/chat',
  checkout: '/checkout',
  circular: '/circular',
  clearance: '/deals/clearance',
  coupons: '/coupons',
  create: '/create',
  parts: '/parts',
  myWarranties: '/myWarranties',
  serviceHistory: '/user/serviceHistory',
  searchResult: '/searchresult',
  orderConfirmation: '/orderConfirmation',
  signIn: '/signin',
  getWarranties: '/ecomm/b2c/v1/customers/warranties',
  signInCheckout: '/signincheckout',
  signInNotCheckout: '/signinnotcheckout',
  store: '/store',
  termsAndConditionPrivacy: '/lp/termsAndConditions?id=privacyPolicy',
  termsAndConditionPrivacyMX: '/lp/terminos-y-condiciones?id=Politicadeprivacidad',
  termsCondition: '/lp/termsAndConditions',
  termsConsidtionProgramRules: '/lp/termsAndConditions?id=rulesAndRegulations',
  contactusOrderQuestions: '/contactus/orderQuestions',
  contactusStoreExperience: '/contactus/storeExperience',
  contactusRewards: '/contactus/rewards',
  contactusProductQuestions: '/contactus/productQuestions',
  contactusGeneralComments: '/contactus/generalComments',
  termsConditionReturnsPolicy: '/lp/termsAndConditions#returnsPolicy',
  termCond: '/termsCondition',
  termCondSecureShop: '/lp/termsAndConditions?id=secureShopping',
  termsConditionsRewardsProgramRules: '/lp/termsAndConditions?id=rewardsProgramRules',
  orderDetails: '/user/orderHistory/:orderId',
  faqs: '/lp/faqs',
  faqsRewards: '/lp/faqs#rewards',
  contactusWebsiteIssues: '/contactus/websiteIssues',
  customerInfo: '/customerinfo',
  userMyVehicle: '/user/myVehicle',
  programRules: '/programRules',
  userMyAccount: '/user/myAccount',
  userProgramRules: '/user/programRules',
  userOrderHistory: '/user/orderHistory',
  giftCardBalance: '/giftCard/cardBalance',
  giftCardLanding: '/giftCard',
  community: '/community',
  trackOrder: '/trackOrder',
  userRewardsRequestCredit: '/user/rewards/requestCredit',
  contactUs: '/contactus',
  mergeConfirmation: '/mergeConfirmation',
  updateRewardsAccount: '/updateRewardsAccount',
  checkRewardAccount: '/checkRewardAccount',
  userMyProfile: '/user/myProfile',
  createRewardsAccount: '/createRewardsAccount',
  myVehicleManage: '/user/myVehicle/manage/',
  addVehicleDetails: '/user/addVehicleDetails/',
  vehicleDetails: '/user/vehicleDetails/',
  fetchServiceHistory: '/serviceHistory?serviceType=ALL&dateRange=Last%2090%20Days&pageNumber=1',
  serviceType: '/serviceHistory?serviceType=',
  dateRange: '&dateRange=',
  pageNumber: '&pageNumber=',
  mapURL: 'https://maps.google.com/maps/dir/?api=1&',
  images: '/images/',
  imagesRewards: '/images/rewards/',
  repairGuides: '/user/repairGuides',
  repairguideContent: '/repairGuideContent',
  userRepairGuideContent: '/user/repairGuideContent',
  resetPassword: '/resetPassword',
  resetPasswordPathName: '/resetPassword/[code]',
  siteMap: '/siteMap',
  templates: {
    servererror: 'templates/servererror.html',
  },
  loginSuccess: '/html/successful-login.html',
  topDeals: '/deals/hotDeal',
  dealsCategory: '/deals/pages',
  dealsStoreAd: '/deals/localstoreadd',
  dealsRebate: '/deals/rebates',
  landingPageDeals: '/lp/deals',
  azVideos: 'https://www.autozone.com/videos',
  azDiy: 'https://www.autozone.com/diy',
  locations: 'https://www.autozone.com/locations/',
  faster: 'https://www.autozone.com/lp/faster',
  user: '/user',
  rewardsLandingPage: '/rewards',
  landingPageloanATool: '/lp/loan-a-tool',
  landingPageInOurStores: '/lp/in-our-stores',
  landingPageBOPUS: '/lp/store-pick-up',
  landingPageSendListToStoreMX: '/lp/recoge-en-tienda',
  landingPageStoreExpansionMX: '/lp/expansion-de-tiendas',
  landingPageMobileApp: '/lp/autozone-mobile-app',
  landingPageRepairHelp: '/lp/repair-help',
  landingPageFindARepairShop: '/lp/find-a-repair-shop',
  landingPageAffiliateProg: '/lp/affiliate-program',
  landingPageFixFinder: '/lp/fix-finder',
  landingPageInStoreServices: '/lp/store-services',
  landingPageInStoreServicesMX: '/lp/servicios-en-tiendas',
  landingPageInStoreServicesBR: '/lp/servicosgratuitos',
  landingPageCircularMX: '/lp/folleto',
  landingPageCircularBR: '/lp/folheto',
  landingPageRecommendations: '/recommendations',
  shopByModel: '/shopbymodel',
  shopByMake: '/shopbymake',
  shopByBrand: '/brands',
  vinDecoder: '/vin-decoder',
  landingPageFindARepairShopMX: 'https://shop-referral.autozonepro.com/az/findShop?v=mx',
  autozoneMechanicsLandingPage: 'https://autozonemecanicos.com',
  sms: '/sms',
  storeExperience: '/contactus/storeExperience',
  wallet: '/wallet',
  errorPage: '/errorPage',
};
