/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { CMSImage } from '@/features/contentstack/components/CMSImage/CMSImage';
import { CMSLink } from '@/features/contentstack/components/CMSLink/CMSLink';
import styles from './HorizontalCard.module.scss';
import { partOfWholeToString } from './SuperDuperHelpers';
import { type ContentStackHorizontalScrollCard } from '@/features/contentstack/interface';
import { Text } from '@az/starc-ui';

type Props = {
  index: number;
  card: ContentStackHorizontalScrollCard;
  numberOfCards: number;
};

const TITLE_CHARS_PER_LINE = 25;

const defaultSizePerDevice = {
  mobile: {
    width: 140,
    height: 102,
  },
  tablet: {
    width: 188,
    height: 148,
  },
  desktop: {
    width: 220,
    height: 148,
  },
};

const HorizontalCard = (props: Props) => {
  const { index, card, numberOfCards } = props;

  return (
    <div key={index} className={styles.card} role="listitem">
      <CMSLink
        to={card.link || ''}
        aria-labelledby={`${index}h3 ${index}h2 ${index}img ${index}cardNumber`}
      >
        <div className={styles.cardBody}>
          <div className={styles.cardText}>
            <Text className={styles.heading} maxLines={2} as="h3">
              {card.heading}
            </Text>
            <Text
              maxLines={
                !!card.heading?.length && card.heading.length > TITLE_CHARS_PER_LINE ? 1 : 2
              }
              className={styles.subheading}
            >
              {card.sub_heading}
            </Text>
          </div>
          {card.image && (
            <div className={styles.imageContainer}>
              <CMSImage image={card.image} sizePerDevice={defaultSizePerDevice} />
            </div>
          )}
          <div className={styles.srOnly} id={`${index}cardNumber`}>
            {`Horizontal Card ${partOfWholeToString(index + 1, numberOfCards)}`}
          </div>
        </div>
      </CMSLink>
    </div>
  );
};

export default HorizontalCard;
