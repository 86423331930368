/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { ContentStackExperienceInjector } from '../../interface';
import { type MonetateFlagName } from '@/features/kibo';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';

type Props = {
  content: ContentStackExperienceInjector;
};

export function CMSExperienceInjector({ content }: Props) {
  const innerHTMLFromTest = useMonetateDecisionFlag(
    (content.experience_key ?? '') as MonetateFlagName
  );
  if (!content?.experience_key || !innerHTMLFromTest) {
    return null;
  }
  return <div dangerouslySetInnerHTML={{ __html: String(innerHTMLFromTest) }} />;
}
