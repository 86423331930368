/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import environmentConfig from '@/config/environment';
import { type AxiosRequestConfig } from 'axios';
import { getCitrusAPIKey } from '../utils/getCitrusAPIKey';

type Configuration = {
  baseURL: string;
  axiosConfig: AxiosRequestConfig<unknown>;
};

export function getCitrusProxyConfiguration(): Configuration {
  let baseURL = 'https://integration.pre-us.citrusad.com';
  let axiosConfig: Configuration['axiosConfig'] = { withCredentials: false };

  const isEnvBehindAkamai =
    !__IS_SERVER__ &&
    (window.location.origin.includes('staging') || environmentConfig.TARGET_ENV === 'production');

  if (isEnvBehindAkamai) {
    baseURL = `${window.location.origin}/citrus`;
  } else {
    axiosConfig = {
      ...axiosConfig,
      headers: {
        Authorization: `Basic ${getCitrusAPIKey(false)}`,
      },
    };
  }

  return {
    baseURL,
    axiosConfig,
  };
}
