/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
// eslint-disable-next-line no-restricted-imports
import Image, { type ImageProps } from 'next/image';

// TODO: Can we use optimized Next image?
const NextImage = ({
  src,
  alt = '',
  unoptimized = true,
  ...rest
}: Omit<ImageProps, 'alt'> & { alt?: string }) => {
  return src ? <Image src={src} alt={alt} unoptimized={unoptimized} {...rest} /> : null;
};

export default NextImage;

type AkamaiLoaderOptions = {
  src: string;
  width: number;
};

type PureLoaderOptions = { src: string };

const akamaiLoader = ({ src, width }: AkamaiLoaderOptions): string => {
  return `${src.trim()}?imwidth=${width}`;
};

const pureLoader = ({ src }: PureLoaderOptions): string => src;

export const blurDataURLPlaceholder: string =
  'data:image/octet-stream;base64,UklGRiACAABXRUJQVlA4WAoAAAAAAAAAYwAAYwAAVlA4IKwBAADwCQCdASpkAGQAAAAAJaQAOeJZHKp4AaWWaL5FqwbRj5k8ZaxypN+d9cywoGpSySja8UwIAE3tZt8sttHDFXo6ViFLPk6aLyRSFF8NUY2E0bjTaJjTaJUAAP7/3tzwABz9H9Bf281Q5//V94oKmf7jeiLfzCjSHpwbOvhp4tNrLWBFu/ng4YHb9MJHChPbyD9HRHdviZiIeQgX0UbhienlqEWeF2sBeg/feAt0VaxuaMP1w61BZd86C/qJA35KXETN5HsxuIL8kc7wsSR4o9CzPy93FURvbOcXxZpBawvHI7w5lEp09fUv7wI4JkITCOuVxcMeZgcANFZ2xA7jKsAyrnAxC5cImChL3AYVeHhd15jIuN5ibAJcDRUCOF0Fhy6Fx/EXsiGA8RHSVXPQPtYNvHqrBIbb1sAHZ4eJrsY6kIbEocGYJOE4izqUOnmiDk6t7QXkZBC57TGjWXTaTVLeuOrdqrqfuU73m4E7GmOik+8i7FgxqmlPba4MbrQ19BH9rmByYsFqyTXPpnLrorY+iJOL2nwPglSZ9B7SznxMfXhPQVeiUAAAAAAAAFBTQUlOAAAAOEJJTQPtAAAAAAAQASwAAAABAAEBLAAAAAEAAThCSU0EKAAAAAAADAAAAAI/8AAAAAAAADhCSU0EQwAAAAAADlBiZVcBEAAGAGQAAAAA';

export { akamaiLoader, pureLoader };
