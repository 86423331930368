/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Grid } from '@/components/Grid';
import { clickTrack } from '@/utils/analytics/clickTrack';
import styles from './styles.module.scss';
import type { ContentStackParttypeGrid } from '../../interface';
import { type Props as HeadlineProps } from '@/components/Headline/Headline';
import { CMSGridItem } from './CMSGridItem/CMSGridItem';
import cx from 'classnames';
import { CMSHeadline } from '../CMSHeadline/CMSHeadline';

type Props = {
  content: ContentStackParttypeGrid;
  headlineOptions?: HeadlineProps;
};

const trackHomeLinkInteractionBySection = (linkName: string, section: string, id: string) => {
  clickTrack({
    homeLinkName: linkName,
    linkBySection: section,
    clickedProductId: id,
    eventType: 'homeInteractionsBySection',
  });
};

export const CMSGrid = ({ content, headlineOptions }: Props) => {
  const { headline, grid_content: gridContent, layout, grid_lines: gridLines } = content;

  const handleLinkClick = (linkName: string | undefined, link: string) => {
    const sectionName =
      (headline ?? '')
        .replace(/<[^>]+>/gi, '')
        .replace(/&nbsp;/gi, '')
        .trim() || '';
    trackHomeLinkInteractionBySection(linkName ?? link, sectionName, '');
  };

  return (
    <Grid container spacing={0}>
      <CMSHeadline underline={false} {...headlineOptions}>
        {headline}
      </CMSHeadline>
      <Grid
        container
        spacing={0}
        className={cx(styles.gridItemsContainer, gridLines ? styles.gridItemsContainerLines : null)}
      >
        {gridContent?.map((item, i) => (
          <CMSGridItem
            key={i}
            item={item}
            onLinkClick={handleLinkClick}
            layout={layout}
            gridLines={gridLines}
          />
        ))}
      </Grid>
    </Grid>
  );
};
