/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { createContext, useState, type ReactNode, useEffect, useContext } from 'react';
import { usePostAcknowldegeMutation } from '@/features/rewards/api/postRewardsAcknowledge';
import { useMyProfile } from '@/features/myProfile/api/getMyProfile';
import { sessionStorage } from '@/utils/sessionStorage';

type ModalTCPromptProvider = {
  setModalOpen: (open: boolean) => void;
  modalOpen: boolean;
  submitAcknowledgment: () => void;
};

const ModalTCPromptContext = createContext<ModalTCPromptProvider>({} as ModalTCPromptProvider);

export const useModalTCPromptContext = () => {
  const context = useContext(ModalTCPromptContext);
  if (context === undefined) {
    throw new Error('useModalTCPromptContext must be used within a ModalTCPromptProvider');
  }
  return context;
};

type ModalTCProviderProps = {
  children: ReactNode;
};

export const ModalTCPromptProvider = ({ children }: ModalTCProviderProps) => {
  const { data: myProfile, refetch } = useMyProfile();
  const { mutate: acknowledgementMutation } = usePostAcknowldegeMutation({
    onSuccess: async () => {
      setModalOpen(false);
      await refetch();
    },
  });

  const loyaltyAccountAlertPromptObject = myProfile?.loyaltyAccountAlerts?.find(
    (alert) => alert.alertType === 'TC'
  );
  const isPrompt = loyaltyAccountAlertPromptObject?.status === 'PROMPT';
  const [modalOpen, setModalOpen] = useState(false);
  const tcPilotPrompt = sessionStorage.getItem('tcPilotPrompt'); // needed for cart page sign-in auto-refresh

  const handlePostRequest = () => acknowledgementMutation({ alertType: 'TC' });

  const submitAcknowledgment = () => {
    sessionStorage.removeItem('tcPilotPrompt');
    handlePostRequest();
  };

  useEffect(() => {
    switch (true) {
      case tcPilotPrompt === 'true':
        setModalOpen(true);
        break;
      case isPrompt && tcPilotPrompt !== 'true':
        sessionStorage.setItem('tcPilotPrompt', 'true');
        setModalOpen(true);
        break;
      default:
        setModalOpen(false);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrompt]);

  return (
    <ModalTCPromptContext.Provider
      value={{
        setModalOpen,
        modalOpen,
        submitAcknowledgment,
      }}
    >
      {children}
    </ModalTCPromptContext.Provider>
  );
};
