/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button } from '@/components/Button';
import { ClickAwayListener } from '@/components/ClickAwayListener/ClickAwayListener';
import { FormControl } from '@/components/FormControl/FormControl';
import { Input } from '@/components/Input/Input';
import { InputLabel } from '@/components/InputLabel/InputLabel';
import { Select as NativeSelect } from '@/components/Select/Select';
import { eventConstants } from '@/constants/event';
import cx from 'classnames';
import * as React from 'react';
import autoCompleteStyles from './AutoComplete.module.scss';
import iconStyles from '@/theme/iconStyles.module.scss';
import SelectDownArrowIcon from '@/public/images/select-chevron-down.svg';
import { isMobile } from '@/utils/common';
import { useRef, useState, useEffect, useCallback } from 'react';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { AutoCompleteItems } from './AutoCompleteItems';
import { type Props, type Item } from './types';
import usePrevious from '@/utils/usePrevious';

const constants = {
  shopByMake: 'shopByMake',
  make: 'Make',
  year: 'Year',
};

const compareArrays = (array1: Item[], array2: Item[]) => {
  let arrayComp = false;
  if (array1.length === array2.length) {
    arrayComp = array1.every((a, index) => a.value === array2[index].value);
  }
  return arrayComp;
};

type IconProps = {
  className: string;
};
const Icon = ({ className }: IconProps) => {
  return (
    <SelectDownArrowIcon
      aria-hidden
      className={cx(iconStyles.defaultIconStyle, autoCompleteStyles.mobileArrowDownIcon, className)}
    />
  );
};

export default function AutoComplete(props: Props) {
  const textInput = useRef<HTMLElement>(null);
  const autoCompleteItemRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [textInputDirty, setTextInputDirty] = useState(false);
  const [focusedListItem, setFocusedListItem] = useState(false);
  const [cursor, setCursor] = useState<number | null>(null);
  const [tempValue, setTempValue] = useState('');
  const [filteredList, setFilteredList] = useState(() => {
    return props.list || [];
  });
  const [prevList, setPrevList] = useState<Item[]>([]);
  const {
    label,
    disabled,
    labelNumber,
    selectedItem = '',
    name,
    id,
    handleClick,
    maxLength,
    resetAll,
    resetFocus,
    focusElement,
    list,
  } = props;

  const ariaLabel = `${name} arrow`;
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const labelName =
    !selectedItem && !showMenu && labelNumber ? `${labelNumber} | ${label ?? ''}` : label;
  const arrowButtonClass = showMenu
    ? autoCompleteStyles.arrowUpPosition
    : autoCompleteStyles.arrowDownPosition;
  const prevFocusElement = usePrevious(focusElement);
  const prevListLength = usePrevious(list.length);
  const handleScrollTop = useCallback(() => {
    const highlightOffset = -2;
    let elementIdx;
    if (cursor === null) elementIdx = highlightOffset;
    else if (cursor >= filteredList.length) elementIdx = filteredList.length - 1;
    else if (cursor + highlightOffset <= 0) elementIdx = 0;
    else elementIdx = cursor + highlightOffset;

    const elementId = `#filterListItem${elementIdx}`;
    const element = document.querySelector(elementId);
    const scrollTo =
      element && element instanceof HTMLElement
        ? element.offsetTop
        : ((cursor ?? 0) - highlightOffset) * 37;
    if (autoCompleteItemRef.current) {
      autoCompleteItemRef.current.scrollTop = !cursor ? 0 : scrollTo;
    }
  }, [cursor, filteredList]);

  const handleFocus = useCallback(() => {
    if (textInput.current) {
      textInput.current.focus();
    }
    if (!showMenu) {
      setShowMenu(true);
      handleScrollTop();
    }
  }, [showMenu, handleScrollTop]);

  useEffect(() => {
    handleScrollTop();
  }, [cursor, handleScrollTop]);

  useEffect(() => {
    if (
      !disabled &&
      focusElement &&
      (prevFocusElement !== focusElement || prevListLength !== list.length)
    ) {
      setTimeout(() => {
        //mobile safari has weird focus behavior need timeout to get around it
        handleFocus();
      }, 100);
    }
  }, [focusElement, disabled, prevFocusElement, handleFocus, list.length, prevListLength]);

  useEffect(() => {
    if (!compareArrays(prevList, list)) {
      setPrevList(list);
      setFilteredList(list);
    }
  }, [list, prevList]);

  const filterList = (searchText: string): Item[] => {
    return list.filter(
      (listItem: Item) =>
        !listItem.isLabel &&
        !listItem.isPopular &&
        listItem.label.toUpperCase().includes(searchText.toUpperCase())
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, isMobile?: boolean) => {
    const searchData = event.target.value;
    const searchText = label === constants.year ? searchData.replace(/[^0-9]+/g, '') : searchData;
    const trimmedSearchText = searchText ? searchText.slice(0, maxLength) : searchText;

    if (!isMobile) {
      setTempValue(trimmedSearchText);
      if (!textInputDirty) {
        setTextInputDirty(true);
      }
    }

    if (searchText.length > 0) {
      setFilteredList(filterList(trimmedSearchText));
    } else {
      if (resetAll) {
        resetAll();
      } else {
        handleClick('');
        setTempValue('');
      }
      setFilteredList(list);
    }
  };

  const handleMobileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.blur();
    if (event.target.value) {
      handleClick(event.target.value);
    }
    handleChange(event, true);
  };

  const handleBlur: () => void = () => {
    resetFocus(); //TODO: manage focus via refs instead of state
    if (!focusedListItem && textInputDirty) {
      selectItemOnBlur();
      setTextInputDirty(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const filteredListLength = filteredList.length;
    let searchedItem = null;

    if (
      e.key === eventConstants.arrowUpKeyType ||
      (e.key === eventConstants.arrowDownKeyType && filteredListLength > 0)
    ) {
      e.preventDefault();
      if (e.key === eventConstants.arrowUpKeyType && (!cursor || cursor > 0)) {
        const newCursor = !cursor ? 0 : cursor - 1;
        setCursor(newCursor);
        searchedItem = filteredList[newCursor];
        setTempValue(searchedItem.label || '');
        setTextInputDirty(true);
      } else if (
        e.key === eventConstants.arrowDownKeyType &&
        (cursor == null || cursor <= filteredListLength - 1)
      ) {
        let newCursor;
        if (cursor === null) {
          newCursor = 0;
        } else if (cursor + 1 === filteredListLength) {
          newCursor = cursor;
        } else {
          newCursor = cursor + 1;
        }
        setCursor(newCursor);
        searchedItem = filteredList[newCursor];
        setTempValue(searchedItem.label || '');
        setTextInputDirty(true);
      }
    } else if (e.keyCode === eventConstants.enterKeyCode && e.key === eventConstants.enterKeyType) {
      searchedItem = filteredList[cursor ?? 0];
      if (searchedItem) {
        handleItemClick(searchedItem.label);
      }
    } else if (e.keyCode === eventConstants.escKeyCode && e.key === eventConstants.escKeyType) {
      resetFocus();
      setShowMenu(false);
    }
  };

  const handleEscapeKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.keyCode === eventConstants.escKeyCode && e.key === eventConstants.escKeyType) {
      resetFocus();
      setShowMenu(false);
    }
  };

  const handleItemClick = (value: string) => {
    handleClick(value);
    setTempValue('');
    hideMenu();
  };

  const handleArrowClick = () => {
    if (!disabled) {
      setShowMenu(!showMenu);
      !showMenu ? textInput.current?.focus() : textInput.current?.blur();
    }
  };

  const hideMenu = () => {
    setShowMenu(false);
  };

  const listScrollHandler = () => {
    if (textInput.current) {
      textInput.current.focus();
    }
  };

  const selectItemOnBlur = () => {
    let value = '';
    const selectedItemLowerCase = selectedItem.toLowerCase() || tempValue.toLowerCase();
    const matchedItem = filteredList.find(
      (listItem) => listItem.label.toLowerCase() === selectedItemLowerCase
    );

    if (matchedItem) {
      value = matchedItem.label;
    } else if (filteredList.length === 1) {
      value = filteredList[0].label;
    } else {
      setFilteredList(list);
      if (resetAll) {
        resetAll();
      }
    }

    handleItemClick(value);
  };

  if (matchesMobile) {
    return (
      <NativeSelect
        name=""
        className={autoCompleteStyles.nativeSelectRoot}
        onBlur={handleBlur}
        value={selectedItem}
        onChange={handleMobileSelect}
        id={id}
        disabled={disabled}
        disableUnderline
        inputRef={textInput}
        inputProps={{
          className: cx(autoCompleteStyles.ymmeNativeSelectInput, {
            [autoCompleteStyles.focused]: !disabled && !selectedItem,
          }),
          'data-testid': 'native-select-field',
          title: `${label ?? id}`,
        }}
        IconComponent={Icon}
      >
        <option value="" disabled>
          {labelName}
        </option>
        {list.map((item) => (
          <option
            key={`${item.value}-${item.isPopular ? 'popular' : 'all'}`}
            value={item.label}
            className={item.isLabel ? autoCompleteStyles.labelItem : ''}
            disabled={item.isLabel}
          >
            {item.label}
          </option>
        ))}
      </NativeSelect>
    );
  } else {
    return (
      <>
        <ClickAwayListener mouseEvent={'onMouseDown'} onClickAway={hideMenu}>
          <div className={autoCompleteStyles.autoComplete}>
            <FormControl className={autoCompleteStyles.inputSec}>
              <Input
                onFocus={handleFocus}
                onBlur={handleBlur}
                inputRef={textInput}
                autoComplete="off"
                onChange={handleChange}
                value={tempValue || selectedItem}
                disabled={disabled}
                name={name}
                inputProps={{ 'data-testid': `${id}-input` }}
                className={cx(autoCompleteStyles.desktopInput, {
                  [autoCompleteStyles.focused]: !disabled && !selectedItem,
                })}
                disableUnderline
                readOnly={Boolean(isMobile())}
                classes={{
                  input: autoCompleteStyles.inputStyles,
                  focused: autoCompleteStyles.focused,
                  disabled: autoCompleteStyles.disabled,
                }}
                onKeyDown={handleKeyDown}
                id={id}
                type="text"
              />
              <InputLabel
                classes={{
                  root: autoCompleteStyles.suggestLabel,
                  focused: autoCompleteStyles.labelFocused,
                  shrink: autoCompleteStyles.suggestLabelShrink,
                  disabled: autoCompleteStyles.suggestLabelDisabled,
                }}
                disabled={disabled}
                htmlFor={id}
              >
                {labelName}
              </InputLabel>
            </FormControl>
            <Button
              variant="ghost"
              customClass={cx(arrowButtonClass, autoCompleteStyles.arrowButtonBase, {
                [autoCompleteStyles.arrowButtonDisabled]: disabled,
              })}
              onClick={handleArrowClick}
              data-testid={`${id}-arrow-down-button`}
              ariaLabel={ariaLabel}
              disabled={disabled}
              onKeyDown={handleEscapeKeyDown}
            >
              <SelectDownArrowIcon
                aria-hidden
                className={cx(iconStyles.defaultIconStyle, autoCompleteStyles.desktopDropdownIcon)}
              />
            </Button>
            <AutoCompleteItems
              filteredList={filteredList}
              showMenu={showMenu}
              ref={autoCompleteItemRef}
              handleItemClick={handleItemClick}
              listScrollHandler={listScrollHandler}
              selectedItem={tempValue || selectedItem}
              onMouseDown={() => {
                setFocusedListItem(true);
              }}
              onMouseUp={() => {
                setFocusedListItem(false);
              }}
              onKeyDown={handleEscapeKeyDown}
              id={id}
            />
          </div>
        </ClickAwayListener>
      </>
    );
  }
}
