/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxios } from '@/lib/axios';
import { type QueryClient, useQuery } from '@tanstack/react-query';
import { type RewardsDetailsData, type RewardsDetailsResponse } from '../interface';
import { requestBaseURL } from '@/config/serviceAPI';
import { countryCodes } from '@/constants/locale';
import { useLocale } from '@/hooks/useLocale';
import { useSelector } from 'react-redux';
import { type ReduxState } from '@/types';
import type { AxiosInstance } from 'axios';
import { useHeaderData } from '@/features/header/api/getHeader';

const URL = `${requestBaseURL}/ecomm/b2c/v1/currentUser/rewards`;

const getRewardDetails = async (axiosInstance?: AxiosInstance) => {
  const response = await getAxios(axiosInstance).get<RewardsDetailsResponse>(URL);
  return selector(response.data);
};

const selector = (data: RewardsDetailsResponse): RewardsDetailsData => data;

const getRewardDetailsKey = (userAuthenticated: boolean) =>
  ['user', 'rewardDetails', { userAuthenticated }] as const;

export const useRewardDetails = () => {
  const { isFetching: isFetchingHeader } = useHeaderData();

  const userAuthenticated = useSelector((state: ReduxState) => state.appData.userAuthenticated);

  const locale = useLocale();

  return useQuery({
    queryKey: getRewardDetailsKey(userAuthenticated),
    queryFn: () => getRewardDetails(),
    // The query will not execute until the headerData exists
    enabled: !isFetchingHeader && userAuthenticated && locale !== countryCodes.mx,
  });
};

export const prefetchRewardDetails = async (
  axiosInstance: AxiosInstance,
  queryClient: QueryClient,
  userAuthenticated: boolean
) => {
  return queryClient.prefetchQuery({
    queryKey: getRewardDetailsKey(userAuthenticated),
    queryFn: () => getRewardDetails(axiosInstance),
  });
};

export const getRewardDetailsFromCache = (queryClient: QueryClient, userAuthenticated: boolean) => {
  return queryClient.getQueryData<RewardsDetailsData>(getRewardDetailsKey(userAuthenticated));
};
