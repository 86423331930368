/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type AxiosError } from 'axios';
import { type DefaultOptions, QueryClient } from '@tanstack/react-query';
import logger from '@/utils/logger';

export const defaultOptions: DefaultOptions = {
  queries: {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 30 * 1000, // global default to 30 seconds
  },
};

let clientSideQueryClient: QueryClient;

// We create a separate instance of QueryClient for every getQueryClient call on server so
// that it doesn't leak between the pages and users. We _could_ create a
// singleton, but that would require the cacheKey to have some user identifier,
// which we currently don't have.
export const createQueryClient = () => new QueryClient({ defaultOptions });

// We create singleton instance of QueryClient so that we do not create duplicates on the client
// and have access to the same cache everywhere.
export const getClientSideQueryClient = () => {
  if (__IS_SERVER__) {
    throw new Error('This function should only be called client side.');
  }

  if (!clientSideQueryClient) {
    clientSideQueryClient = new QueryClient({
      defaultOptions,
      logger: {
        error: (error: AxiosError) => {
          if (error?.code === 'ECONNABORTED') {
            logger.warn({
              meta: error,
              message: `Request aborted for ${error?.config?.url || 'unknown URL'}`,
            });
          } else {
            if (error.response) {
              // Request made but the server responded with an error
              logger.error({
                meta: {
                  // we don't know the shape of the response here
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  data: error.response.data,
                  status: error.response.status,
                  // we don't know the shape of the response here
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  headers: error.response.headers,
                },
                message: `Request error for ${error?.config?.url || 'unknown URL'}`,
              });
            } else if (error.request) {
              logger.error({
                // we don't know the shape of the request here
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                meta: error.request,
                message: `Request error for ${error?.config?.url || 'unknown URL'}`,
              });
            } else {
              logger.error({
                meta: { error },
                message: `react-query exception: ${error?.message}`,
              });
            }
          }
        },

        log: (message: unknown) => {
          logger.log({
            meta: message,
            message: 'react-query message',
          });
        },

        warn: (warning: unknown) => {
          logger.warn({
            meta: warning,
            message: 'react-query warning',
          });
        },
      },
    });
  }

  return clientSideQueryClient;
};
