/**
 * Copyright 2019-2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useRef, useEffect, useState } from 'react';
import NextImage from '@/components/NextImage';
import styles from '../../styles.module.scss';
import { Label } from '@/features/i18n';
import { useLabel } from '@/hooks/useLabels';
import { closeIcon } from '@/constants/images/closeIcon';
import { eventConstants } from '@/constants/event';
import cx from 'classnames';
import { useRouter } from 'next/router';

const hamburgerIcon = {
  src: '/images/hamburger_icon.svg',
  alt: '',
};
type Props = {
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
  dataTestIdBtn?: string;
  open: boolean;
  id?: string;
  isFixedHeader?: boolean;
};

export const MenuNavButton = ({
  handleClick,
  handleClose,
  open,
  dataTestIdBtn,
  id,
  isFixedHeader,
}: Props) => {
  const menuBtnRef = useRef<HTMLButtonElement>(null);
  const menuAriaLabel = useLabel('label_Homepage_content_Menu');
  const closeAriaLabel = useLabel('label_Homepage_content_Close');
  const [focus, setFocus] = useState(false);
  const { asPath } = useRouter();

  useEffect(() => {
    if (focus) {
      menuBtnRef?.current?.focus();
    }
  });

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [asPath, handleClose]);

  let menuLbl = <Label label="label_Homepage_content_Menu" />;
  let buttonClick = handleClick;
  let menuImg = (
    <div
      data-testid="menu-nav"
      className={cx(styles.menuImgOpen, {
        [styles.menuImgOpenSticky]: isFixedHeader,
      })}
    >
      <img
        src={hamburgerIcon.src}
        alt={hamburgerIcon.alt}
        data-testid="mobileMenuLogo"
        height={15}
        width={20}
        decoding="async"
      />
    </div>
  );

  if (open) {
    buttonClick = handleClose;
    menuLbl = <Label label="label_Homepage_content_Close" />;
    menuImg = (
      <div className={styles.menuImgClose}>
        <NextImage src={closeIcon.src} alt={closeIcon.alt} height={16} width={20} />
      </div>
    );
  }

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === eventConstants.escKeyType) {
      setFocus(true);
    }
    if (e.key === eventConstants.tabKeyType) {
      setFocus(false);
    }
  };

  return (
    <button
      id={id}
      ref={menuBtnRef}
      data-testid={dataTestIdBtn}
      onClick={buttonClick}
      onKeyDown={handleOnKeyDown}
      onMouseDown={(e) => e.stopPropagation()}
      aria-expanded={open}
      aria-label={open ? closeAriaLabel : menuAriaLabel}
      className={cx(styles.menuNavButton, {
        [styles.menuNavButtonSticky]: isFixedHeader,
      })}
    >
      {menuImg}
      <div className={cx(styles.menuText, { [styles.menuTextSticky]: isFixedHeader })}>
        {menuLbl}
      </div>
    </button>
  );
};

export default MenuNavButton;
