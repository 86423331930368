/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React from 'react';
import { Stack } from '@az/starc-ui';
import { type ContentStackLogoDescription } from '../../interface';
import { CMSImage } from '../CMSImage/CMSImage';
import styles from './CMSLogoDescription.module.scss';
import { CMSButton } from '../CMSButton/CMSButton';
import { isCMSButtonDefined } from '../../utils/isCMSButtonDefined';

type Props = {
  content: ContentStackLogoDescription;
};

export function CMSLogoDescription({ content }: Props) {
  const { headline, image, description, button, alignment = 'Center' } = content;

  return (
    <Stack gap={5} align={alignment === 'Center' ? 'center' : 'start'}>
      <Stack gap={8} align={alignment === 'Center' ? 'center' : 'start'}>
        {headline ? <h2 className={styles.headline}> {headline}</h2> : null}
        {image ? <CMSImage image={image} className={styles.image} /> : null}
      </Stack>

      {description ? (
        <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
      {button && isCMSButtonDefined(button) && <CMSButton options={button} />}
    </Stack>
  );
}
