/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import styles from './CMSTermsAndConditions.module.scss';
import CMSSubsectionGroup, { type Group } from './CMSSubsectionGroup';
import { View } from '@az/starc-ui';

export type Props = {
  groups: Group[];
};

const CMSTableOfContents = (menu: Props) => {
  return (
    <View className={styles.tableContainer}>
      {menu.groups.map((group, index) => (
        <CMSSubsectionGroup
          key={`${group.menu_heading}-${index}`}
          link={group.link}
          menu_heading={group.menu_heading}
          section_id={group.section_id}
        />
      ))}
    </View>
  );
};

export default CMSTableOfContents;
