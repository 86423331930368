/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import root from 'window-or-global';
import { BREAKPOINTS } from '../../constants/screen';
import { commonValidation } from '../../constants/validation';
import { countryCodes } from '@/constants/locale';
import type { Locale } from '@/types/i18n';
import { type NextApiRequest, type NextApiResponse } from 'next';

export const isEmptyObject = (obj: any): boolean => {
  return obj.constructor === Object && Object.entries(obj).length === 0;
};

// this also considers iPad as mobile
export const isMobile = (): boolean => {
  if (typeof global !== 'undefined' && typeof global.navigator !== 'undefined') {
    return navigator.userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
      ? true
      : false;
  }

  return false;
};

export const getSalesChannel = (): string => {
  let deviceType = '';

  if (root.innerWidth >= BREAKPOINTS.DESKTOP_MIN) {
    deviceType = commonValidation.AZRWEB;
  } else if (
    root.innerWidth >= BREAKPOINTS.TABLET_MIN &&
    root.innerWidth <= BREAKPOINTS.TABLET_MAX
  ) {
    deviceType = commonValidation.AZRTAB;
  } else if (root.innerWidth < BREAKPOINTS.MOBILE_MAX) {
    deviceType = commonValidation.AZRMOB;
  }

  return deviceType;
};

export const uniqueId = (): string => Math.random().toString(36).substr(2, 16);

export const isSafari = (): boolean =>
  !__IS_SERVER__ &&
  !navigator.userAgent.toLowerCase().includes('chrome/') &&
  !navigator.userAgent.toLowerCase().includes('chromium/');

export const getBaseSchemaUrl = (locale: Locale): string => {
  return locale === countryCodes.mx
    ? 'https://www.autozone.com.mx'
    : locale === countryCodes.ptBr
    ? 'https://www.autozone.com.br'
    : 'https://www.autozone.com';
};

export const getSafeNumber = (value?: number | string | null): number => {
  const parsedValue = parseFloat(String(value));
  return isNaN(parsedValue) ? 0.0 : parsedValue;
};

export const handleLoadMoreRedirect = (req: NextApiRequest, res: NextApiResponse) => {
  const url = new URL(req?.url!, `http://${req?.headers.host}`);

  if (url.searchParams.has('loadMore')) {
    url.searchParams.delete('loadMore');
    const newPath = url.pathname + url.search;
    res?.writeHead(302, { Location: newPath }).end();
  }
};
