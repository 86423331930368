/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import styles from './AltYMMEContentBlock.module.scss';
import cx from 'classnames';
import NextImage from '@/components/NextImage';
import { useRouter } from 'next/router';
import logger from '@/utils/logger';
import { YMMEModal } from './YMMEModal';
import { useContext, useEffect, useState } from 'react';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { useLabels } from '@/hooks/useLabels';
import { SnackBarContext } from '@/components/SnackBarContext';
import { usePreferredVehicle } from '../../header/hooks/usePreferredVehicle';
import { trackShopWithoutVehicle } from '@/utils/analytics/track/trackShopWithoutVehicle';
import { useDeleteDefaultVehicleMutation } from '../api/deleteDefaultVehicle';
import { setInteractionLocation } from '../utils/ymmeLocationHelper';
import type { InteractionLocation } from '@/types/analytics';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useCategoryByModel } from '@/features/category/api/getCategoryModels';
import { useRedirectAfterVehicleChanged } from '@/features/category/hooks/useRedirectAfterVehicleChanged';
import { Skeleton, View } from '@az/starc-ui';

type Props = {
  pageType?: string;
  onUpdateVehicleSuccess?: (() => void) | undefined;
  nonShopPage?: boolean;
};

export default function AltYMMEContentBlock({
  pageType,
  onUpdateVehicleSuccess,
  nonShopPage = false,
}: Props) {
  const router = useRouter();
  const [modelName, setModelName] = useState('');
  const [showYmme, setShowYmme] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // These variables are used to minimize repetitive code
  const isPartsRoute =
    router.asPath.startsWith('/parts') || router.asPath.startsWith('/refacciones');
  const seoUrlPathLength = router.query.seoUrlPath?.length ?? 0;
  const isCategoryPage = isPartsRoute && seoUrlPathLength >= 2;

  const [isLoading, setIsLoading] = useState(isPartsRoute && isCategoryPage ? true : false);

  const redirectAfterVehicleChanged = useRedirectAfterVehicleChanged();
  const isBrowsePageType = router.route === '/[...seoUrlPath]';

  const { data: categoryModelData } = useCategoryByModel({
    enabled: Boolean(isPartsRoute && isCategoryPage),
    seoUrl: router.asPath,
  });

  useEffect(() => {
    // This gets the name of the model with correct casing
    if (categoryModelData) {
      // If only needing model name without make
      if (categoryModelData.title && seoUrlPathLength === 2) {
        const titleParts = categoryModelData.title.split(' by ');
        if (titleParts.length > 1) {
          setModelName(titleParts[1].split(' by')[0]);
          setIsLoading(false);
          return;
        }
      }

      // If needing both model and make name
      if (categoryModelData.shopByMMYList && seoUrlPathLength === 3) {
        const foundModel = categoryModelData.shopByMMYList.find(
          (model) => model.seoUrl === router.asPath
        );
        setModelName(foundModel?.name || '');
        setIsLoading(false);
        return;
      }
    }
  }, [categoryModelData, seoUrlPathLength, router.asPath]);

  const labelMap = {
    lblInlineEntryPointTitle: 'label_InlineEntryPointTitle',
    lblInlineEntryPointSubtitle: 'label_InlineEntryPointSubtitle',
    lblCurrentlyShoppingFor: 'label_CurrentlyShoppingFor',
    lblSubModel: 'label_SubModel',
    lblEngine: 'label_Engine',
    lblAddVehicle: 'button_YMME_Body_AddVehicle',
    lblAddMakeModel: 'add_make_model',
    lblAddLicensePlate: 'button_YMME_Body_AddLicensePlate',
    lblAddVIN: 'button_YMME_Body_AddVIN',
    lblChangeVehicle: 'button_YMME_Body_ChangeVehicle',
    lblShopWithoutVehicle: 'button_YMME_Body_ShopWithoutVehicle',
    yourLabel: 'label_MyAccountMyProfile_landing_RewardYour',
    hasBeenMovedLabel: 'label_YMME_Body_HasBeenMovedTo',
    lblMakeModel: 'label_MakeModel',
    lblLicensePlate: 'label_LicensePlate',
    lblVin: 'label_Vin',
    lblGetExactFit: 'label_YMME_GetAnExactFitForYour',
    lblAddYearModel: 'label_Add_Year_Model',
    lblAddYear: 'label_Add_Year',
  };

  const preferredVehicle = usePreferredVehicle();
  const labels = useLabels(labelMap);

  const [selectedMethod, setSelectedMethod] = useState<string>(labels.lblMakeModel);

  // Parse vehicle information from preferredVehicle object
  // Extracts make, base model, sub-model, and engine info
  // Handles potential undefined values with optional chaining and fallback to empty arrays
  const makeArray = preferredVehicle?.make?.split(' ') ?? [];
  const modelArray = preferredVehicle?.model?.split(' ') ?? [];
  const make = makeArray[0];
  const baseModel = modelArray[0];
  const subModel = modelArray.slice(1);
  const engineInfo = preferredVehicle?.vehicleName.split(' ').slice(-3).join(' ');

  const locale = useLocale();
  const isMX = locale === countryCodes.mx;
  const isBR = locale === countryCodes.ptBr;

  const vehicleIconOrange = {
    src: '/images/vehicle-new-orange.svg',
    alt: 'car-icon',
  };

  const plusSignBlack = {
    src: '/images/plus-sign-black.svg',
    alt: 'plus-sign',
  };
  const ymmeCarIcon = {
    src: '/images/vehicle/ymme-car.svg',
    alt: 'car-icon',
  };

  const vehicleImage =
    preferredVehicle && !!preferredVehicle.vehicleImageURL ? (
      <NextImage
        src={preferredVehicle.vehicleImageURL}
        alt=""
        width={100}
        height={54.29}
        className={cx(styles.image)}
      />
    ) : (
      <NextImage src={vehicleIconOrange.src} alt={vehicleIconOrange.alt} width={80} height={50} />
    );

  const handleOpenYmme = (method: string) => {
    setSelectedMethod(method);
    setShowYmme(true);
    setInteractionLocation(pageType as InteractionLocation);
  };

  const closeYmme = () => {
    setShowYmme(false);
  };

  const { mutateAsync: removeDefaultVehicle } = useDeleteDefaultVehicleMutation();
  const { showSnackBar } = useContext(SnackBarContext);

  const removeVehicle = () => {
    try {
      void removeDefaultVehicle();
    } catch (err) {
      logger.error({
        message: 'Error when removing default vehicle',
        meta: {
          error: err,
        },
      });
    } finally {
      onUpdateVehicleSuccess?.();
    }
  };

  const handleShopWithoutVehicle = () => {
    setInteractionLocation(pageType as InteractionLocation);
    removeVehicle();
    const snackMessage = preferredVehicle?.vehicleName
      ? `${labels.yourLabel} ${preferredVehicle.vehicleName} ${labels.hasBeenMovedLabel}`
      : '';
    showSnackBar(snackMessage);
    trackShopWithoutVehicle();
  };

  const getAddLabel = () => {
    // Check if the router is on the Make Category Page or Make Shelf Page
    if (
      router.pathname === '/shopbymake' ||
      (isPartsRoute && seoUrlPathLength === 2) ||
      (pageType === 'shelf' && seoUrlPathLength === 3)
    ) {
      return labels.lblAddYearModel;
    } else if (
      // Check if the router is on the Model Category Page or Model Shelf Page
      router.pathname === '/shopbymodel' ||
      (isPartsRoute && seoUrlPathLength === 3) ||
      (pageType === 'shelf' && seoUrlPathLength === 4)
    ) {
      return labels.lblAddYear;
    }
    return isMobile ? labels.lblAddVehicle : labels.lblAddMakeModel;
  };

  if (isLoading) {
    return (
      <View className={styles.removeHorizontalPadding} height={isMobile ? '100%' : '115px'}>
        <Skeleton height="100%" width="100%" />
      </View>
    );
  }

  return (
    <div
      className={cx(styles.container, {
        [styles.nonShopContainer]: nonShopPage,
        [styles.pdp]: pageType === 'ProductDetail',
        [styles.nonUSContainer]: isMX || isBR,
        [styles.hasVehicleContainer]: preferredVehicle,
      })}
    >
      {preferredVehicle ? (
        <div className={cx(styles.hasVehicleContent)}>
          <div className={cx(styles.subtitle)}>
            <p>{labels.lblCurrentlyShoppingFor}:</p>
            <button
              type="button"
              onClick={() => setShowYmme(true)}
              className={cx(styles.underlinedButton)}
            >
              {labels.lblChangeVehicle}
            </button>
          </div>
          <div className={cx(styles.vehicleInfo)}>
            <div className={cx(styles.vehicleInfoDetails)}>
              <span
                className={cx(styles.noVehicleImageStyles, {
                  [styles.vehicleImageStyles]:
                    preferredVehicle && !!preferredVehicle.vehicleImageURL,
                })}
              >
                {vehicleImage}
              </span>
              <div>
                <h2>
                  {preferredVehicle.year} {make} {baseModel}
                </h2>
                <p>
                  <span>{labels.lblSubModel}:</span> {subModel.join(' ')}
                </p>
                <p>
                  <span>{labels.lblEngine}:</span> {engineInfo}
                </p>
              </div>
            </div>
            <div className={cx(styles.vehicleInfoActions, styles.buttons)}>
              <button type="button" onClick={() => setShowYmme(true)}>
                {labels.lblChangeVehicle}
              </button>
              <button
                type="button"
                onClick={handleShopWithoutVehicle}
                className={cx(styles.underlinedButton)}
              >
                {labels.lblShopWithoutVehicle}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className={cx(styles.content)}>
          <div className={cx(styles.titleContainer)}>
            <NextImage
              src={ymmeCarIcon.src}
              alt={ymmeCarIcon.alt}
              width={nonShopPage ? 40 : 31.99}
              height={nonShopPage ? 40 : 31.99}
            />
            <div className={cx(styles.titleText)}>
              <h2>{labels.lblInlineEntryPointTitle}</h2>
              {isPartsRoute && isCategoryPage ? (
                <p>
                  {labels.lblGetExactFit} <strong>{modelName}</strong>
                </p>
              ) : (
                <p>{labels.lblInlineEntryPointSubtitle}</p>
              )}
            </div>
          </div>
          <div className={cx(styles.buttons)}>
            <button type="button" onClick={() => handleOpenYmme(labels.lblMakeModel)}>
              <NextImage src={plusSignBlack.src} alt={plusSignBlack.alt} width={12} height={12} />{' '}
              {getAddLabel()}
            </button>
            {!isMX && !isBR && (
              <button type="button" onClick={() => handleOpenYmme(labels.lblLicensePlate)}>
                <NextImage src={plusSignBlack.src} alt={plusSignBlack.alt} width={12} height={12} />{' '}
                {labels.lblAddLicensePlate}
              </button>
            )}
            <button type="button" onClick={() => handleOpenYmme(labels.lblVin)}>
              <NextImage src={plusSignBlack.src} alt={plusSignBlack.alt} width={12} height={12} />{' '}
              {labels.lblAddVIN}
            </button>
          </div>
        </div>
      )}
      <YMMEModal
        onUpdateVehicleSuccess={() => {
          isBrowsePageType && void redirectAfterVehicleChanged();
          closeYmme();
        }}
        showYmme={showYmme}
        closeYmmeWidget={closeYmme}
        pageType={pageType}
        selectedMethod={
          isMobile && !getAddLabel().includes(labels.lblAddYear) ? '' : selectedMethod
        }
      />
    </div>
  );
}
