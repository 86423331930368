/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';
import type { ReduxState } from '@/types';
import GridContent from '@/components/ContentBlocks/MobileGridContent';
import { HeroComponentStack } from '@/features/home';
import HtmlBlock from '@/components/ContentBlocks/HtmlBlock/HtmlBlock';
import RewardsActivityBlock from '@/components/ContentBlocks/RewardsActivityBlock/RewardsActivityBlock';
import ImageTextBlock from '@/components/ContentBlocks/ImageTextBlock/imageTextBlock';
import PencilAdContentBlock from '@/components/ContentBlocks/HomePencilContent/PencilComponent';
import LiveTextBanner from '@/components/ContentBlocks/ShelfBanner/LiveTextBanner';
import VinDecoder from '../VinDecoder';
import DeviceWrapper from '@/components/AZCustomComponent/DeviceWrapper';
import ContainOverflow from '@/components/AZCustomComponent/ContainOverflow';
import { getCartridgeType } from '@/utils/getCartridgeType';
import { setCookie } from '@/utils/cookie';
import SplitContentBlockGeneric from '@/components/PageTemplates/Generic/SplitContentBlockGeneric';
import ShelfBannerPreBakedGeneric from '@/components/PageTemplates/Generic/ShelfBannerPreBakedGeneric';
import HeroPrebackedGeneric from '@/components/PageTemplates/Generic/HeroPreBakedGeneric';
import { YMMEContentBlock } from '@/features/ymme/components/YMMEContentBlock';
import { pageTypes } from '@/constants/page';
import MembershipStatusBlock from '@/components/ContentBlocks/MembershipStatusBlock';
import { type RewardsActivityFilters } from '@/features/rewards';
import { useRewardsActivity } from '@/features/rewards/api/getRewardsActivity';
import TableContentBlock from '@/components/ContentBlocks/TableContentBlock/TableContentBlock';
import { VideoContentBlock } from '@/components/ContentBlocks/VideoContentBlock';
import { RichTextContentBlock } from '@/components/ContentBlocks/RichTextContentBlock';
import FAQContentBlock from '@/components/ContentBlocks/FAQContentBlock';
import SplitContentBlockV2Generic from '@/components/PageTemplates/Generic/SplitContentBlockV2Generic';
import { useStreamLineSignIn } from '@/hooks/useStreamLineSignIn';
import { useDispatch } from '@/hooks/redux/useDispatch';
import SignInModal from '@/components/SignInModal';
import type { TabIndexes } from '@/components/SignInModal/interface';
import { useFeatureFlag } from '@/features/globalConfig';
import type { NotSpecifiedContentBlock, ContentBlock } from '@/types/legacy';
import type { CertonaRecommendation } from '@/features/certona';
import { isContentBlockSlot } from '@/utils/isContentBlockSlot';
import SignInModalV2 from '@/components/SignInModalV2';
import { SignInFlowTypeContext } from '@/components/SignInFlowTypeContext';
import dynamic from 'next/dynamic';
import AltYMMEContentBlock from '@/features/ymme/components/AltYMMEContentBlock';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';

const LazyPDFViewer = dynamic(() => import('@/components/PDFViewer').then((mod) => mod.PDFViewer), {
  ssr: false,
});

export type GenericTemplateProps = {
  content: Array<
    (
      | Omit<NotSpecifiedContentBlock, 'contentType'>
      | { contents: Array<Omit<NotSpecifiedContentBlock, 'contentType'>> }
    ) & { '@type': string }
  >;
  isList?: boolean;
  queryDisplayLabel?: string;
  certonaRecommendations?: CertonaRecommendation[];
};

export const LandingPageGeneric = ({ content }: GenericTemplateProps) => {
  const { setFlowTypeFn } = useContext(SignInFlowTypeContext);
  const dispatch = useDispatch();
  const router = useRouter();
  const [filters, setFilters] = useState<RewardsActivityFilters | undefined>();
  const { data: rewardsData } = useRewardsActivity(filters);
  const isLoggedIn = useSelector(({ appData }: ReduxState) => appData.userAuthenticated);
  const altYMMEContentBlock = useMonetateDecisionFlag('alternativeYMMEContentBlock');
  let hasRenderedAltYMME = false;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<TabIndexes>(1);
  const isLaunchSignInModalOnHTMLBlocksEnabled =
    useFeatureFlag('LAUNCH_SIGN_IN_MODAL_ON_HTML_BLOCKS') === 'true';

  const isSignInV2Enabled = useStreamLineSignIn();

  function closeModal() {
    setIsModalOpen(false);
  }

  function createOpenModalFunction(tabIndex: TabIndexes) {
    return function () {
      setCookie('loginInteractionMethod', 'Rewards Modal');
      setActiveTab(tabIndex);
      setIsModalOpen(true);
      if (isSignInV2Enabled) {
        setFlowTypeFn('login');
      }
    };
  }

  function modifyElementToLaunchModal(element: HTMLElement, openInTabIndex: TabIndexes) {
    const openModal = createOpenModalFunction(openInTabIndex);
    element.setAttribute('aria-label', `${element.innerHTML}`);
    element.removeAttribute('href');
    element.tabIndex = 0;
    element.style.cursor = 'pointer';
    element.addEventListener('click', openModal);
    element.addEventListener('keypress', (e) => {
      if (e.key === 'Enter') {
        openModal();
      }
    });
  }

  useEffect(() => {
    if (isLaunchSignInModalOnHTMLBlocksEnabled) {
      if (router.pathname === routePaths.rewardsLandingPage) {
        document.querySelectorAll<HTMLElement>('a.buttonB, a[href="/signin').forEach((element) => {
          modifyElementToLaunchModal(element, 0);
        });

        document
          .querySelectorAll<HTMLElement>('a.buttonA, a.buttonC, a[href="/create"] ')
          .forEach((element) => {
            modifyElementToLaunchModal(element, 1);
          });
      }
    }
    // We actually need run this effect every time, else we will sometimes get the href leading to /signin instead of modal
  });

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  });

  useEffect(() => {
    if (
      isLoggedIn &&
      (router.asPath === routePaths.rewardsLandingPage ||
        router.asPath.includes(`${routePaths.rewardsLandingPage}?`))
    ) {
      const toDate = dayjs();
      const fromDate = toDate.subtract(1, 'year');
      const dateFormat = 'YYYYMMDD';

      const toDateFormatted = dayjs(toDate).format(dateFormat);
      const fromDateFormatted = dayjs(fromDate).format(dateFormat);

      setFilters({
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        orderType: null,
      });
    }
  }, [dispatch, isLoggedIn, router.asPath]);

  const renderContentBlocks = (contentItem: NotSpecifiedContentBlock, i: number) => {
    const key = `${contentItem.contentType}-${i}`;

    const wrapWithAltYMME = (component: JSX.Element | null) => {
      if (component && !hasRenderedAltYMME && altYMMEContentBlock) {
        hasRenderedAltYMME = true;
        const shouldPlaceYMMEAbove =
          router.asPath === '/lp/find-a-repair-shop' ||
          router.asPath === '/lp/faqs' ||
          router.asPath === '/lp/termsAndConditions#returnsPolicy' ||
          router.asPath === '/lp/product-ingredient-disclosure' ||
          router.asPath === '/lp/restricciones';
        return (
          <>
            {!shouldPlaceYMMEAbove && component}
            <ContainOverflow>
              <AltYMMEContentBlock nonShopPage={true} />
            </ContainOverflow>
            {shouldPlaceYMMEAbove && component}
          </>
        );
      }
      return component;
    };

    const isSpecificContentBlock = (
      contentItem: NotSpecifiedContentBlock
    ): contentItem is ContentBlock => {
      return [
        'HTMLBlock',
        'HeroCarouselSlot',
        'SplitContentBlock',
        'SplitContentBlockV2',
        'ShelfBannerPreBaked',
        'Grid',
        'HeroPreBaked',
        'ImageText',
        'ShelfBannerLiveText',
        'PDFRender',
        'PencilAdLiveText',
        'VINCode',
        'YMME',
        'MembershipStatus',
        'RewardsActivity',
        'VideoContentBlock',
        'RichTextBlock',
        'TableContentBlock',
        'FAQsContentBlock',
      ].includes(contentItem.contentType);
    };

    if (isSpecificContentBlock(contentItem)) {
      switch (contentItem.contentType) {
        case 'HTMLBlock':
          return wrapWithAltYMME(
            <div className="htmlContentBlock" key={key}>
              <ContainOverflow key={key}>
                <HtmlBlock content={contentItem} key={key} />
              </ContainOverflow>
            </div>
          );

        case 'HeroCarouselSlot':
          return (
            <DeviceWrapper
              desktop={contentItem.desktop || false}
              tablet={contentItem.tablet || false}
              mobile={contentItem.mobile || false}
              key={key}
            >
              <ContainOverflow fullWidth key={key}>
                <HeroComponentStack data={contentItem.contents} key={key} />
              </ContainOverflow>
            </DeviceWrapper>
          );

        case 'SplitContentBlock':
          return <SplitContentBlockGeneric key={key} contentItem={contentItem} />;

        case 'SplitContentBlockV2':
          return <SplitContentBlockV2Generic key={key} contentItem={contentItem} />;

        case 'ShelfBannerPreBaked':
          return wrapWithAltYMME(
            <ShelfBannerPreBakedGeneric
              key={key}
              index={i}
              contentItem={contentItem}
              contentType={contentItem.contentType}
              keyProp={key}
            />
          );

        case 'Grid':
          return (
            <ContainOverflow key={key}>
              <GridContent contentBlock={contentItem.contentBlock} />
            </ContainOverflow>
          );

        case 'HeroPreBaked':
          return <HeroPrebackedGeneric key={key} keyProp={key} contentItem={contentItem} />;

        case 'ImageText':
          return (
            <ContainOverflow key={key}>
              <ImageTextBlock data={contentItem} key={key} />
            </ContainOverflow>
          );

        case 'ShelfBannerLiveText':
          return (
            <ContainOverflow key={key}>
              <LiveTextBanner contentItem={contentItem} />
            </ContainOverflow>
          );

        case 'PDFRender':
          return <LazyPDFViewer key={key} src={contentItem.pdfLink} />;

        case 'PencilAdLiveText':
          return <PencilAdContentBlock data={contentItem} key={key} />;

        case 'VINCode':
          return <VinDecoder key={key} />;

        case 'YMME':
          return (
            <ContainOverflow key={key} fullWidth={true}>
              {altYMMEContentBlock ? (
                <AltYMMEContentBlock pageType={pageTypes.landingPage} />
              ) : (
                <YMMEContentBlock pageType={pageTypes.landingPage} data={contentItem} />
              )}
            </ContainOverflow>
          );

        case 'MembershipStatus':
          if (isLoggedIn && rewardsData) {
            return (
              <div className="reactContentBlock" key={key}>
                <ContainOverflow key={key}>
                  <MembershipStatusBlock key={key} />
                </ContainOverflow>
              </div>
            );
          }

          break;

        case 'RewardsActivity':
          if (rewardsData?.rewardsActivityLineItems.length) {
            return (
              <div className="reactContentBlock" key={key}>
                <ContainOverflow key={key}>
                  <RewardsActivityBlock deviceTypes={contentItem} key={key} />
                </ContainOverflow>
              </div>
            );
          }
          break;

        case 'VideoContentBlock':
          return (
            <ContainOverflow key={key}>
              <VideoContentBlock data={contentItem} key={key} />
            </ContainOverflow>
          );

        case 'RichTextBlock':
          return (
            <ContainOverflow key={key}>
              <RichTextContentBlock data={contentItem} key={key} />
            </ContainOverflow>
          );

        case 'TableContentBlock':
          return <TableContentBlock content={contentItem}></TableContentBlock>;

        case 'FAQsContentBlock':
          return (
            <ContainOverflow key={key}>
              <FAQContentBlock content={contentItem} />
            </ContainOverflow>
          );
        default:
          return null;
      }
    }

    return null;
  };

  return (
    <>
      {isLaunchSignInModalOnHTMLBlocksEnabled ? (
        isSignInV2Enabled ? (
          <SignInModalV2
            onClose={closeModal}
            setActiveTab={setActiveTab}
            isOpen={isModalOpen}
            activeTab={activeTab}
          />
        ) : (
          <SignInModal
            onClose={closeModal}
            setActiveTab={setActiveTab}
            isOpen={isModalOpen}
            activeTab={activeTab}
          />
        )
      ) : null}

      {content.map((contentItem, i) => {
        const contentType = getCartridgeType(contentItem['@type']);

        if (
          contentType === 'Slot' &&
          isContentBlockSlot(contentItem) &&
          contentItem.contents.length > 0
        ) {
          return contentItem.contents.map((singleContentItem, mappedContentIndex) =>
            renderContentBlocks(
              {
                ...singleContentItem,
                contentType: getCartridgeType(singleContentItem['@type']),
              },
              mappedContentIndex
            )
          );
        }

        return renderContentBlocks(
          { ...contentItem, contentType: getCartridgeType(contentItem['@type']) },
          i
        );
      })}
    </>
  );
};
