/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect, useState, useRef, Suspense } from 'react';
import root from 'window-or-global';
import { useRouter } from 'next/router';
import ProgressModal from '@/components/AZCustomComponent/ProgressModal';
import { LandingPageTemplate } from '../components/PageTemplates/LandingPageTemplate';
import { routePaths } from '@/constants/routePaths';
import { useAnalytics } from '@/utils/analytics/useAnalytics';
import { usePageViewCall } from '@/utils/analytics/usePageViewCall';
import { pageTypes } from '@/utils/analytics/legacyUtils/pageTypes';
import { getSeoPageType } from '@/utils/analytics/legacyUtils/getSeoPageType';
import { getPageName } from '@/utils/analytics/legacyUtils/getPageName';
import { getContentBlockNames } from '@/utils/analytics/legacyUtils/getContentBlockNames';
import { getContentBlockTypes } from '@/utils/analytics/legacyUtils/getContentBlockTypes';
import { getAnalyticsRelativeComplementOf } from '@/utils/analytics/getAnalyticsRelativeComplementOf';
import { useGetLandingPageData } from '../api/getLandingPageData';
import { isLandingPage } from '@/utils/isLandingPage';
import type { LandingHead, LandingPageBody, LandingPageBanner } from '@/types/legacy';
import { siteSections } from '@/utils/siteSections';
import { useGetContentStackLandingPageData } from '../api/getContentStackLandingPageData';
import { isLandingPageHandledByContentStack } from '../utils/isLandingPageHandledByContentStack';
import { CMSModularHandler } from '../components/CMSModularHandler';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';
import { CMSSeoTags } from '@/features/contentstack/components/CMSSeoTags/CMSSeoTags';
import { useLocale } from '@/hooks/useLocale';
import { CMSBottomModularHandler } from '../components/CMSBottomModularHandler';
import { TrapFocus } from '@/components/Dialog/Modal/TrapFocus';
import dynamic from 'next/dynamic';
import { Drawer } from '@/components/Drawer/Drawer';
import { type AddCertonaItemObjectType } from '@/features/certona';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useSelector } from 'react-redux';
import { selectOrderDetailsFromState } from '@/constants/selectors';
import styles from './LandingPage.module.scss';
import { ScrolledPartCardSkeleton } from '@/features/certona';
import { useGetCertonaRecommendations } from '@/features/certona/api/useGetCertonaRecommendations';
import { certonaPageType } from '@/constants/certonaPageType';
import { useContentStackLivePreview } from '@/hooks/useContentStackLivePreview';

type Props = {
  pageName?: string;
};

type ParsedMessageData = {
  documentHeight: number;
};

function tryParseJSON(input: string) {
  try {
    const parsed: unknown = JSON.parse(input);

    if (parsed && typeof parsed === 'object' && 'documentHeight' in parsed) {
      return {
        valid: true,
        parsed: parsed as ParsedMessageData,
      };
    }

    throw new Error('Invalid JSON');
  } catch (e) {
    return {
      valid: false,
      parsed: undefined,
    };
  }
}

const LazyMiniCartFlyoutComp = dynamic(
  () => import('@/features/miniCartFlyout').then((mod) => mod.MiniCartFlyoutComp),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

export const LandingPageComp = (props: Props) => {
  const hasSetupAnalytics = useAnalytics();
  const pageViewCall = usePageViewCall();
  const router = useRouter();
  const locale = useLocale();

  const isFindARepairShop = router.asPath === routePaths.landingPageFindARepairShop;
  const [renderContent, setRenderContent] = useState(!isFindARepairShop);
  const [pageViewCalled, setPageViewStatus] = useState(false);
  const initialPageName = router.query.pageName?.toString() || props.pageName;
  const cleanPageName = initialPageName?.replace('/:', ':');
  const pageName = `lp/${cleanPageName ?? ''}`;
  const contentStackLandingPageEnabled = isLandingPageHandledByContentStack(
    useMonetateDecisionFlag('cmsLandingPagePhaseOneEnabled'),
    locale,
    cleanPageName
  );
  const isRecommendationsLandingPage = router.asPath.includes(
    routePaths.landingPageRecommendations
  );

  const [showCertonaMiniCartFlyoutModal, setShowCertonaMiniCartFlyoutModal] =
    useState<boolean>(false);
  const [certonaRecommendationAddedToCart, setCertonaRecommendationAddedToCart] =
    useState<boolean>(false);
  const [addedCertonaItemObject, setAddedCertonaItemObject] =
    useState<AddCertonaItemObjectType | null>(null);
  const closeStoreModal = () => {
    if (addedCertonaItemObject) {
      setCertonaRecommendationAddedToCart(false);
      setAddedCertonaItemObject(null);
    }
  };
  const toggleCertonaMiniCartFlyout = () => {
    setShowCertonaMiniCartFlyoutModal(() => !showCertonaMiniCartFlyoutModal);
    closeStoreModal();
  };
  useEffect(() => {
    if (addedCertonaItemObject) {
      setShowCertonaMiniCartFlyoutModal(true);
    }
  }, [addedCertonaItemObject]);
  const lazyMiniCartModalRef = useRef<HTMLDivElement>(null);
  const { data: headerData } = useHeaderData();
  const miniCartMap = headerData?.miniCartMap;
  const orderDetailsFromState = useSelector(selectOrderDetailsFromState);
  const landingPageQuery = useGetLandingPageData(initialPageName, !contentStackLandingPageEnabled);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    data: landingPageData,
    isLoading: isLoadingLandingPageData,
    isSuccess: isLandingPageQuerySuccess,
    error: landingPageQueryError,
  } = useGetContentStackLandingPageData({
    pageName: initialPageName,
    enabled: contentStackLandingPageEnabled,
  });
  const content = landingPageQuery.data;
  const pageFetchSuccess = landingPageQuery.isSuccess;
  const pageLoading = landingPageQuery.isLoading;
  const pageNotFound = Boolean(
    contentStackLandingPageEnabled
      ? landingPageQueryError
      : content && content['@error'] && content['@error'] === 'FileNotFound'
  );

  let landingPageTippyTop: Array<{ content: string; '@type': string }> | undefined;
  let landingPageHead: LandingHead[] | undefined;
  let landingPageBody: LandingPageBody[] | undefined;
  let landingPageBanner: LandingPageBanner[] | undefined;

  if (content && isLandingPage(content)) {
    landingPageTippyTop = content.landingTippyTop;
    landingPageHead = content.landingHead;
    landingPageBody = content.landingPageBody;
    landingPageBanner = content.landingPageBanner;
  }

  const vehicleId = headerData?.vehicleMap.catalogVehicleId || '';
  const certonaRecommendations = useGetCertonaRecommendations(
    {
      certonaType: certonaPageType.recommendations,
      vehicleID: vehicleId,
      recommendations: true,
    },
    [certonaPageType, vehicleId],
    true
  );

  const messageEventListner = (message: MessageEvent<string>) => {
    const messageData = message.data;
    const { valid, parsed } = tryParseJSON(messageData);

    if (valid && parsed) {
      const findShop = document.getElementById('find-a-shop');

      if (findShop) {
        findShop.style.height = `${parsed.documentHeight}px`;
      }
    }
  };

  useContentStackLivePreview({ enabled: contentStackLandingPageEnabled });

  useEffect(() => {
    if (pageNotFound) {
      void router.replace('/NotFound');
    }
  }, [pageNotFound, router]);

  useEffect(() => {
    if (isFindARepairShop) {
      root.addEventListener('message', messageEventListner);
      setRenderContent(true); //need to wait to render find a repair shop iframe until parent is mounted
    }

    return () => root.removeEventListener('message', messageEventListner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!pageViewCalled && hasSetupAnalytics && content && !contentStackLandingPageEnabled) {
      const pageNameForSEO = `lp:${cleanPageName ?? ''}${
        router.asPath.includes('#') ? router.asPath.slice(router.asPath.indexOf('#')) : ''
      }`;

      const initialName = router.asPath.replace('/', '');
      const name = initialName.replace('/:', ':');
      const pageSpecificAnalyticsData = {
        pageName: getPageName(pageTypes.landingPage, pageNameForSEO),
        siteSection: siteSections.siteSectionlandingPage,
        pageType: pageTypes.landingPage,
        pageHierarchy: `Home|${
          name.includes('-')
            ? name.replace(/-/g, ' ').replace(/\b([a-z])/g, (x: string) => x.toUpperCase())
            : name.replace(/\b([a-z])/g, (x: string) => x.toUpperCase())
        }`,
        contentBlockNames: [
          ...getContentBlockNames(landingPageTippyTop),
          ...getContentBlockNames(landingPageHead),
          ...getContentBlockNames(landingPageBody),
          ...getContentBlockNames(landingPageBanner),
        ],
        contentBlockTypes: [
          ...getContentBlockTypes(landingPageTippyTop),
          ...getContentBlockTypes(landingPageHead),
          ...getContentBlockTypes(landingPageBody),
          ...getContentBlockTypes(landingPageBanner),
        ],
        seoPageType: getSeoPageType(pageTypes.landingPage, pageName),
      };
      pageViewCall(
        pageSpecificAnalyticsData,
        getAnalyticsRelativeComplementOf(pageSpecificAnalyticsData, undefined)
      );
      setPageViewStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, hasSetupAnalytics]);

  useEffect(() => {
    if (!pageViewCalled && hasSetupAnalytics && landingPageData && contentStackLandingPageEnabled) {
      const pageNameForSEO = `lp:${cleanPageName ?? ''}${
        router.asPath.includes('#') ? router.asPath.slice(router.asPath.indexOf('#')) : ''
      }`;

      const initialName = router.asPath.replace('/', '');
      const name = initialName.replace('/:', ':');
      const pageSpecificAnalyticsData = {
        pageName: getPageName(pageTypes.landingPage, pageNameForSEO),
        siteSection: siteSections.siteSectionlandingPage,
        pageType: pageTypes.landingPage,
        pageHierarchy: `Home|${
          name.includes('-')
            ? name.replace(/-/g, ' ').replace(/\b([a-z])/g, (x: string) => x.toUpperCase())
            : name.replace(/\b([a-z])/g, (x: string) => x.toUpperCase())
        }`,
        seoPageType: getSeoPageType(pageTypes.landingPage, pageName),
      };
      pageViewCall(
        pageSpecificAnalyticsData,
        getAnalyticsRelativeComplementOf(pageSpecificAnalyticsData, undefined)
      );
      setPageViewStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingPageData, hasSetupAnalytics]);

  useEffect(() => {
    setPageViewStatus(false);
  }, [router]);
  if (contentStackLandingPageEnabled) {
    return (
      <div>
        {isLoadingLandingPageData ? (
          <div>
            <ProgressModal noScroll={true} />
          </div>
        ) : null}
        {landingPageData && isLandingPageQuerySuccess && (
          <>
            <CMSModularHandler
              content={landingPageData.modularBlocks}
              certonaRecommendations={certonaRecommendations}
              setAddedCertonaItemObject={setAddedCertonaItemObject}
              setCertonaRecommendationAddedToCart={setCertonaRecommendationAddedToCart}
              onlyShowAvailableProductRecommendations={isRecommendationsLandingPage}
              LoadingSkeleton={ScrolledPartCardSkeleton}
              isTopContent
            />
            <div className={styles.miniCartPopup}>
              <Drawer
                data-testid="mini-cart-flyout"
                anchor={'right'}
                open={showCertonaMiniCartFlyoutModal}
                onClose={toggleCertonaMiniCartFlyout}
                PaperProps={{ className: styles.customPaperDrawer }}
                drawerRef={lazyMiniCartModalRef}
              >
                <TrapFocus open={showCertonaMiniCartFlyoutModal}>
                  <div className={styles.miniCartFlyoutDialog} role="dialog" aria-modal="true">
                    <Suspense>
                      {showCertonaMiniCartFlyoutModal && addedCertonaItemObject && (
                        <LazyMiniCartFlyoutComp
                          handleClose={toggleCertonaMiniCartFlyout}
                          isModalOpen={showCertonaMiniCartFlyoutModal}
                          image={addedCertonaItemObject.productImage}
                          successData={addedCertonaItemObject.cartSuccessData}
                          productDetails={{
                            productName: addedCertonaItemObject.productDetails.productName,
                            certonaItemAddedTocart: certonaRecommendationAddedToCart,
                            productPartType: addedCertonaItemObject.productDetails.partType,
                            productId: addedCertonaItemObject.productDetails.productId,
                            originalPartTypeId: addedCertonaItemObject.productDetails.partType,
                            productAvailabilityInfo: {
                              aZBadgesFlagVO: null,
                              dealsInfo: undefined,
                              skuPricingAndAvailability: undefined,
                            },
                          }}
                          miniCartData={miniCartMap}
                          closeStoreModal={closeStoreModal}
                          cartUrl={addedCertonaItemObject.submitToCart}
                          productTitle={addedCertonaItemObject.productTitle}
                          pageName={addedCertonaItemObject.pageName}
                          orderDetailsFromState={orderDetailsFromState}
                          skuId={addedCertonaItemObject.productDetails.skuId}
                          parentRef={lazyMiniCartModalRef}
                        />
                      )}
                    </Suspense>
                  </div>
                </TrapFocus>
              </Drawer>
            </div>
          </>
        )}
        {landingPageData && isLandingPageQuerySuccess && landingPageData.bottomModularBlocks && (
          <CMSBottomModularHandler content={landingPageData.bottomModularBlocks} />
        )}
        {isLandingPageQuerySuccess && landingPageData && landingPageData.seoTags && (
          <CMSSeoTags seoData={landingPageData.seoTags} />
        )}
      </div>
    );
  }

  return (
    <div>
      {pageLoading ? (
        <div>
          <ProgressModal noScroll={true} />
        </div>
      ) : null}
      {content && pageFetchSuccess && renderContent && (
        <LandingPageTemplate
          pageName={pageName}
          landingPageHead={landingPageHead}
          landingPageBody={landingPageBody}
          landingPageBanner={landingPageBanner}
        />
      )}
    </div>
  );
};
