/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect } from 'react';
import cx from 'classnames';
import { Label } from '@/features/i18n';
import { useLabel } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import azCommonStyles from '../../../theme/globals.module.scss';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import { splitStringToTwoLabels } from '../../../utils/splitStringToTwoLabels';
import { getMapUrl, getPhoneUrl } from '@/utils/urlHelpers';
import { Button } from '@/components/Button';
import StoreServicesComp from '../../StoreServicesComp';
import styles from './styles.module.scss';
import StoreHours from './StoreHours';
import { trackGetDirectionInteraction } from '@/utils/analytics/track/trackGetDirectionInteraction';
import { trackStorePhoneInteraction } from '@/utils/analytics/track/trackStorePhoneInteraction';
import { pageTypeSiteSection } from '@/utils/analytics/dataLayer/pageTypeSiteSection';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import type { StoreDetails, DayType } from '@/types';
import { StoreStatus } from '@/components/AZCustomComponent/Store/components/StoreStatus';
import { type StoreHourData } from '@/components/StoreLocator/types';
import dayjs, { type Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { translateDayToSpanish } from '@/components/AZCustomComponent/Store/helpers';

/**
 * A modal dialog can only be closed by selecting one of the actions.
 */
type Props = {
  backBtnHandler: () => void;
  closeStoreModal: () => void;
  storeDetails: StoreDetails | undefined;
  storeStatusLabels?: React.ReactElement;
  storeDetailsLabel?: string;
  setStore?: (
    storeNumber: string,
    zipCode: string,
    isNearByStoreSelected: boolean,
    searchedInput?: string
  ) => void;
  moreDetailsStoreSelectedID?: number;
  moreDetailsStoreSelectedZip?: number;
  storeHours?: StoreHourData;
  moreDetailsLocIndex?: number;
};

function MessageDisplay({
  storeDetails,
  storeHours,
}: {
  storeDetails: StoreDetails | undefined;
  storeStatusLabels?: React.ReactElement;
  storeHours?: StoreHourData;
}) {
  const lblOpen24Hours = useLabel('label_Homepage_header_Open24hours');
  // in calls from yext, we already have the message generated as a
  // jsx component
  if (storeHours) {
    return <StoreStatus storeHourData={storeHours} />;
  }
  const timeString = storeDetails?.open24Hours ? lblOpen24Hours : storeDetails?.messageToDisplay;
  const timeStringObj = splitStringToTwoLabels(timeString || '');
  return (
    <>
      <span className={cx(azCommonStyles['az-button-text'], styles.availableUntil)}>
        {timeStringObj.firstLabel}
      </span>{' '}
      {timeStringObj.secondLabel}
    </>
  );
}

const AddressBlock = ({ storeDetails }: { storeDetails: any; moreDetailsLocIndex?: number }) => {
  const locale = useLocale();
  const isBR = locale === countryCodes.ptBr;

  if (!storeDetails) {
    return null;
  }

  const address2 =
    storeDetails.address2 !== 'NULL' && storeDetails.address2 !== undefined
      ? storeDetails.address2
      : '';

  const address3 =
    storeDetails.address3 !== 'NULL' && storeDetails.address3 !== undefined
      ? storeDetails.address3
      : '';

  const lastLine = isBR
    ? `CEP ${
        storeDetails.zip || ''
      }, ${storeDetails.city.toLocaleLowerCase()} ${storeDetails.state.toLocaleUpperCase()}`
    : `${storeDetails.city.toLocaleLowerCase()}, ${storeDetails.state.toLocaleUpperCase()} ${
        storeDetails.zip || ''
      }`;

  return (
    <div className={styles.address}>
      <div
        id="my-store-address"
        data-testid="my-store-address"
        className={cx(azCommonStyles['az-body-1-regular'], styles.addressAndStoreNumber)}
        /** switch to 24 px margin-bottom check mobile */
      >
        <div className={azCommonStyles['az-margin-bottom-xxs']}>
          <p className={styles.myStoreTextCapital}>
            {storeDetails.address1.toLocaleLowerCase()}
            {locale === countryCodes.mx || isBR ? ', ' + address2.toLocaleLowerCase() : ''}
          </p>
          {isBR && <p>{address3}</p>}
          <p className={styles.myStoreTextCapital} data-testid="my-store-location">
            {lastLine}
          </p>
        </div>
      </div>
      <div className={styles.myStoreStoreNumber}>
        <Label label="store_number" />
        {storeDetails.storeNumber}
      </div>
    </div>
  );
};

const PhoneNumberBlock = ({ storeDetails }: { storeDetails: any }) => {
  const locale = useLocale();
  return (
    <a
      id="storeLocatorPhoneNumber"
      href={getPhoneUrl(storeDetails?.phoneNumber, locale)}
      className={cx(azCommonStyles['az-body-1-link'], styles.noWrap)}
      onClick={() => {
        trackStorePhoneInteraction(storeDetails.phoneNumber);
      }}
    >
      {storeDetails && formatPhoneNumber(storeDetails.phoneNumber, locale)}
    </a>
  );
};

const BtnForChangeStoreOrSetStore = ({
  moreDetailsStoreSelectedID,
  moreDetailsStoreSelectedZip,
  setStore,
  backBtnHandler,
}: {
  moreDetailsStoreSelectedID?: number;
  moreDetailsStoreSelectedZip?: number;
  setStore?: (
    storeNumber: string,
    zipCode: string,
    isNearByStoreSelected: boolean,
    searchedInput?: string
  ) => void;
  backBtnHandler: () => void;
}) => {
  if (moreDetailsStoreSelectedID && moreDetailsStoreSelectedZip) {
    return (
      <Button
        variant="contained"
        fullWidth
        onClick={() => {
          setStore?.(
            moreDetailsStoreSelectedID.toString(),
            moreDetailsStoreSelectedZip.toString(),
            false
          );
        }}
        id="setStoreBtn"
      >
        <Label label="label_TopNav_content_SetYourStore" />
      </Button>
    );
  }

  return (
    <Button variant="contained" fullWidth onClick={backBtnHandler} id="changeStoreBtn">
      <Label label="label_Footer_modal_ChangeStore" />
    </Button>
  );
};

function isOpen24Hours({
  parsedClosingTime,
  parsedOpeningTime,
}: {
  parsedClosingTime: Dayjs;
  parsedOpeningTime: Dayjs;
}) {
  const FORMAT = 'H:m';
  const ALL_DAY_TIMESTAMP = '0:0';

  return (
    parsedClosingTime.format(FORMAT) === ALL_DAY_TIMESTAMP &&
    parsedOpeningTime.format(FORMAT) === ALL_DAY_TIMESTAMP
  );
}

const MyStore = ({
  moreDetailsStoreSelectedID,
  moreDetailsStoreSelectedZip,
  storeDetails,
  moreDetailsLocIndex,
  setStore,
  backBtnHandler,
  storeDetailsLabel,
  storeHours,
  closeStoreModal,
}: Props) => {
  useEffect(() => {
    if (moreDetailsStoreSelectedID && moreDetailsStoreSelectedZip) {
      pageTypeSiteSection('storedetails');
    }
  }, [moreDetailsStoreSelectedID, moreDetailsStoreSelectedZip]);
  const getDirectionsToStoreNo = useLabel('label_Store_getDirectionsToStoreNumber');
  const at = useLabel('label_MyAccountMyProfile_orderhistory_At');
  const zipCode = useLabel('label_CreateAccount_body_ZipCode');
  const locale = useLocale();
  const { data: storeData } = useStoreDetailsData();
  const isBopusEnabled = storeData?.bopusEnabled ?? false;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;

  const determineOpenNowValue = () => {
    dayjs.extend(customParseFormat);
    // ATG returns `12:00 AM` as midnight value, whereas, YEXT returns `0:00`
    const midNightValue = '12:00 AM';
    const now = dayjs();
    // Get the end of the day (midnight)
    const endOfDay = now.endOf('day');

    const todayDayOfWeekInEnglish = dayjs().format('dddd');
    const todayDayOfWeekInSpanish = translateDayToSpanish(todayDayOfWeekInEnglish);
    const todayInLocaleLang =
      locale === countryCodes.mx ? todayDayOfWeekInSpanish : todayDayOfWeekInEnglish;

    const storeFullHoursObj = storeDetails?.storeFullHours;

    const storeFullHoursToday =
      storeFullHoursObj?.[todayInLocaleLang as DayType] ||
      storeFullHoursObj?.[todayDayOfWeekInEnglish as DayType];

    const END_OF_THE_DAY = locale === countryCodes.ptBr ? '23:59' : '11:59 PM';
    const openingTime = storeFullHoursToday?.open;
    const closingTime = ['Midnight', 'Medianoche'].includes(storeFullHoursToday?.close ?? '')
      ? END_OF_THE_DAY
      : storeFullHoursToday?.close;
    const storeClosedAllDay =
      openingTime === midNightValue && closingTime === midNightValue && !storeDetails?.open24Hours;
    const nowIsBeforeMidnight = closingTime === midNightValue && now.isBefore(endOfDay);
    const timeFormat = locale === countryCodes.ptBr ? 'HH:mm' : 'HH:mm A';
    const parsedClosingTime = dayjs(closingTime, timeFormat);
    const parsedOpeningTime = dayjs(openingTime, timeFormat);

    const isDuringBusinessHours =
      (now.isAfter(parsedOpeningTime) && nowIsBeforeMidnight) ||
      (now.isAfter(parsedOpeningTime) && now.isBefore(parsedClosingTime)) ||
      isOpen24Hours({ parsedClosingTime, parsedOpeningTime });

    // Yext API returns openedNow as true even if store is past business hours
    if (storeDetails?.openedNow && !isDuringBusinessHours) {
      return false;
    }

    if (storeClosedAllDay) {
      return false;
    }

    if (storeDetails?.open24Hours) {
      return true;
    }
    if (storeDetails?.openedNow) {
      return true;
    }
    // Yext API returns openedNow as false even if store is still opened
    if (!storeDetails?.openedNow) {
      if (isDuringBusinessHours) {
        return true;
      }
      return false;
    }
    return null;
  };

  const openTextValue = useLabel('label_StoreLocator_modal_OPEN');

  const closedTextValue = useLabel('label_StoreLocator_modal_CLOSED');

  const messageToDisplay = storeDetails?.messageToDisplay;

  const openedNowValue = !!(messageToDisplay || '').includes(openTextValue)
    ? true
    : !!(messageToDisplay || '').includes(closedTextValue)
    ? false
    : determineOpenNowValue();

  return (
    <div className={styles.myStoreContainer}>
      <div className={`${styles.topRow}`}>
        <div className={styles.storeContent}>
          <div
            id="store-details-content"
            data-testid="store-details-content"
            className={cx(azCommonStyles['az-padding-right-xxs'], styles.storeDetailContent)}
            /** storeDetailContent switched to 8px top margin and padding. check mobile */
          >
            <h2
              id="my-store-header"
              data-testid="my-store-header"
              className={cx(
                azCommonStyles['az-margin-bottom-xxs'],
                azCommonStyles['az-title-3-medium'],
                styles.myStoreHeaderText
              )}
            >
              <Label label={storeDetailsLabel ? storeDetailsLabel : 'label_Footer_modal_MyStore'} />
            </h2>
            <AddressBlock storeDetails={storeDetails} moreDetailsLocIndex={moreDetailsLocIndex} />
            <div
              className={cx(
                styles.availableUntil,
                azCommonStyles['az-button-text'],
                azCommonStyles['az-margin-bottom-xxs'],
                openedNowValue ? styles.openUntil : styles.closeUntil
              )}
            >
              <MessageDisplay storeHours={storeHours} storeDetails={storeDetails} />
            </div>
            <div className={cx(styles.linkContentTop)}>
              <div className={cx(azCommonStyles['az-margin-bottom-m'], styles.linkContent)}>
                <a
                  href={getMapUrl(`${storeDetails?.latitude},${storeDetails?.longitude}`)}
                  className={cx(
                    azCommonStyles['az-body-1-link'],
                    azCommonStyles['az-margin-right-s'],
                    styles.noWrap
                  )}
                  id="storeLocatorGetDirections"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={trackGetDirectionInteraction}
                  aria-label={`${getDirectionsToStoreNo} ${storeDetails?.storeNumber} ${at} ${storeDetails?.address1} ${storeDetails?.city} ${storeDetails?.state} ${zipCode} ${storeDetails?.zip}`}
                >
                  <Label label="label_Footer_modal_GetDirections" />
                </a>
                <PhoneNumberBlock storeDetails={storeDetails} />
              </div>
              <BtnForChangeStoreOrSetStore
                moreDetailsStoreSelectedID={moreDetailsStoreSelectedID}
                moreDetailsStoreSelectedZip={moreDetailsStoreSelectedZip}
                setStore={setStore}
                backBtnHandler={backBtnHandler}
              />
            </div>
          </div>
          <div id="my-store-image" data-testid="my-store-image" className={styles.storeMapContent}>
            <div
              className={
                isBopusMexicoStoreEnabled
                  ? styles.mapReplacementBopusFacility
                  : styles.mapReplacement
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.myStoreHoursCotentBlock}>
        <div className={cx(styles.tabletStoreContainer)}>
          <div className={`${styles.tabletStoreView}`} tabIndex={0}>
            <h2
              id="my-store-store-hours-title"
              data-testid="my-store-store-hours-title"
              className={cx(
                azCommonStyles['az-title-5-medium'],
                azCommonStyles['az-button-text'],
                styles.myStoreHoursTitle
              )}
            >
              <Label label="label_Footer_modal_StoreHours" />
            </h2>
            <div className={cx(styles.hoursListContainer)}>
              <StoreHours storeDetails={storeDetails} />
            </div>
          </div>
          <div className={styles.myStoreContentBlock1}>
            <StoreServicesComp handleClose={closeStoreModal} />
          </div>
        </div>
      </div>
    </div>
  );
};

export { MyStore };
