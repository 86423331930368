/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { FulfillmentOptionFromAPI } from '@/adaptors/types';
import { getIsSTHFulfillmentTypeId } from '@/features/product/utils/getIsSTHFulfillmentTypeId';
import type { FulfillmentGroup, FulfillmentOption } from '@/types/reduxStore/skuDetails';

export function getFulfillmentOptionsByGroup(
  fulfillmentOptionsFromApi?: FulfillmentOptionFromAPI[] | null
): FulfillmentGroup[] {
  if (!fulfillmentOptionsFromApi) {
    return [];
  }

  const generateGroupFulfillmentOption = (
    fulfillmentOption: FulfillmentOptionFromAPI
  ): FulfillmentOption => ({
    alternateOptionsLabel: fulfillmentOption.alternateOptionsLabel,
    available: fulfillmentOption.available,
    availableLabel: fulfillmentOption.availableLabel,
    availableQty: fulfillmentOption.availableQty,
    estimatedDeliveryDate: fulfillmentOption.estimatedDeliveryDate,
    fulfillmentCutOffTimeMessage: fulfillmentOption.fulfillmentCutOffTimeMessage,
    fulfillmentTypeId: getIsSTHFulfillmentTypeId(fulfillmentOption.fulfillmentTypeId)
      ? 101
      : fulfillmentOption.fulfillmentTypeId,
    fulfillmentTypeIdOriginal: fulfillmentOption.fulfillmentTypeId,
    fulfillmentTypeLabel: fulfillmentOption.fulfillmentTypeLabel,
    fullfillmentCutOffDate: fulfillmentOption.fullfillmentCutOffDate,
    groupId: fulfillmentOption.groupTypeId,
    groupLabel: fulfillmentOption.displayLabel,
    selected: fulfillmentOption.selected,
    sisterStoreAvailable: fulfillmentOption.sisterStoreAvailable,
    dealsAvailableQty: fulfillmentOption.dealsAvailableQty,
    numberOfSisterStoreAvailable: fulfillmentOption.numberOfSisterStoreAvailable,
    dealAvaiableSisterStores: fulfillmentOption.dealAvaiableSisterStores,
  });

  const fulfillmentOptionsGroupList: Record<
    number,
    {
      groupFulfillmentOptions: FulfillmentOption[];
      available: boolean;
      selected: boolean;
      maxAvailableQty: number;
      groupId: number;
      groupLabel: string;
    }
  > = {};
  fulfillmentOptionsFromApi.forEach((fulfillmentOption) => {
    if (fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId]) {
      fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId].groupFulfillmentOptions.push(
        generateGroupFulfillmentOption(fulfillmentOption)
      );
      // if the current group is marked as unavailable, and the new option is
      // available, then set it to available since we only need one of them
      // to be true for the group to be selectable
      if (
        fulfillmentOption.available &&
        !fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId].available
      ) {
        fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId].available =
          fulfillmentOption.available;
      }
      // if the current group is marked as not selected, and the new option is
      // selected, then set the group to seelcted since if any option is selected
      // the group is selected
      if (
        fulfillmentOption.selected &&
        !fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId].selected
      ) {
        fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId].selected =
          fulfillmentOption.selected;
      }
      // get the max available quantity by selecting the highest qty among
      // all the options
      if (
        fulfillmentOption.availableQty >
        fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId].maxAvailableQty
      ) {
        fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId].maxAvailableQty =
          fulfillmentOption.availableQty;
      }
    } else {
      fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId] = {
        groupId: fulfillmentOption.groupTypeId,
        groupLabel: fulfillmentOption.displayLabel,
        groupFulfillmentOptions: [generateGroupFulfillmentOption(fulfillmentOption)],
        selected: fulfillmentOption.selected, //check business rules for which is the defaulted by default.
        available: fulfillmentOption.available,
        maxAvailableQty: fulfillmentOption.availableQty || 0,
      };
    }
  });
  const unSorted = Object.values(fulfillmentOptionsGroupList);
  return unSorted.sort((a, b) => b.groupId - a.groupId);
}
