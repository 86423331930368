/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { loggerURL } from '@/config/serviceAPI';
import { type LogObject } from '@/utils/logger';
import root from 'window-or-global';

root.sentLabels = new Set<string>();

root.missingLabels = new Set<string>();

export async function reportMissingLabels(_event: Event) {
  if (document.visibilityState === 'hidden') {
    const difference = new Set<string>();

    for (const label of root.missingLabels) {
      if (!root.sentLabels.has(label)) {
        difference.add(label);
        root.sentLabels.add(label);
      }
    }

    root.missingLabels.clear();

    const size = difference.size;

    if (size > 0) {
      const logData: LogObject = {
        message: `Missing labels: ${size}`,
        level: 'error',
        meta: {
          currentPage: root.location.toString(),
          keys: Array.from(difference),
        },
      };

      const payload = JSON.stringify(logData);
      if ('sendBeacon' in navigator) {
        navigator.sendBeacon(loggerURL + '?beacon=true', payload);
      } else {
        await fetch(loggerURL, {
          body: payload,
          method: 'POST',
          keepalive: true,
          headers: { 'Content-Type': 'application/json' },
        });
      }
    }
  }
}
