/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View } from '@az/starc-ui';
import cx from 'classnames';
import { Legal } from '@/components/AZCustomComponent/Legal';
import { mapContentstackLegalValues } from '@/utils/mapContentstackLegalValues';
import { type ContentStackBlogtypeGrid } from '../../interface';
import styles from './CMSBlogGrid.module.scss';
import { CMSHeadline } from '../CMSHeadline/CMSHeadline';
import { CMSImageContentCard } from '../CMSImageContentCard/CMSImageContentCard';
import { useEffect, useRef, useState } from 'react';
import { isLegalDefined } from '../../utils/isLegalDefined';

type Props = {
  content: ContentStackBlogtypeGrid;
};

export const CMSBlogGrid = ({ content }: Props) => {
  const { headline, blog_content, background_color, legal } = content;
  const imgRefs = useRef<Array<HTMLElement | null>>([]);
  const [imageHeight, setImageHeight] = useState(0);

  const findHighestImage = () => {
    if (imgRefs.current.length) {
      const highestImageSize = imgRefs.current.reduce(
        (maxHeight, imgHeight) => {
          const imageHeight = imgHeight?.clientHeight ?? 0;

          return maxHeight > imageHeight ? maxHeight : imageHeight;
        },

        0
      );

      setImageHeight(highestImageSize);
    }
  };

  useEffect(() => {
    findHighestImage();
    window.addEventListener('resize', findHighestImage);
    imgRefs.current.forEach((imgRef) => imgRef?.addEventListener('load', findHighestImage));
    const imageReferences = imgRefs.current;

    return () => {
      imageReferences.forEach((imgRef) => imgRef?.removeEventListener('load', findHighestImage));
      window.removeEventListener('resize', findHighestImage);
    };
  }, [imgRefs]);

  return (
    <View className={styles.outerContainer}>
      <CMSHeadline fullWidth>{headline}</CMSHeadline>
      <View className={styles.container}>
        <View className={styles.blogContent}>
          {blog_content?.map((content, index) => (
            <CMSImageContentCard
              key={index}
              {...content}
              withBackground={background_color}
              ref={(img: HTMLImageElement) => (imgRefs.current[index] = img)}
            />
          ))}
        </View>

        {background_color && (
          <View
            className={styles.background}
            attributes={{ style: { '--bg-height': `${imageHeight / 2}px` } }}
          />
        )}
      </View>
      {legal && isLegalDefined(legal) && (
        <View
          className={cx(
            styles.legalContainer,
            background_color && styles.legalContainerWithBackground
          )}
        >
          <Legal content={mapContentstackLegalValues(legal)} overrideClassName={styles.legalText} />
        </View>
      )}
    </View>
  );
};
