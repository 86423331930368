/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState } from 'react';
import cx from 'classnames';
import { Label } from '@/features/i18n';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes, defaultStoreUS } from '@/constants/locale';
import azCommonStyles from '@/theme/globals.module.scss';
import { storeModalType } from '@/constants/storeModal';
import { keyCodes } from '@/constants/keyCodes';
import styles from './styles.module.scss';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import Skeleton from '@/components/Skeleton/Skeleton';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import dynamic from 'next/dynamic';
import ProgressModal from '@/components/AZCustomComponent/ProgressModal';
import { useGlobalState } from '@/hooks/useGlobalState';

const LazySearchStoreModal = dynamic(
  () =>
    import('@/components/Store/StoreModal/SearchStoreModal').then((mod) => mod.SearchStoreModal),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

const LazyMyStoreModal = dynamic(
  () => import('@/components/Store/StoreModal/MyStoreModal').then((mod) => mod.MyStoreModal),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

const storeIconSource = '/images/store-new.svg';

const storeSelectedIconSource = {
  mobile: '/images/icon-store-selected-m.svg',
  tablet: '/images/icon-store-selected-t.svg',
  desktop: '/images/icon-store-selected-d.svg',
};

const storeIcon = {
  src: '/images/store-new.svg',
  alt: 'My Store',
};

type Props = {
  dataTestIdStoreName?: string;
  closeAllModals?: () => void;
  closeModalOnBack?: boolean;
  isFixedHeader?: boolean;
};

export function TopNavStore({
  dataTestIdStoreName,
  closeModalOnBack,
  closeAllModals,
  isFixedHeader = false,
}: Props) {
  const locale = useLocale();
  const { data: storeData, isRefetching } = useStoreDetailsData();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(storeModalType.searchStore);

  const openStoreModal = (modalTypeSelected: string) => {
    setModalOpen(true);
    setModalType(modalTypeSelected);
  };

  const closeStoreModal = () => {
    setModalOpen(false);
  };

  const storeNumber = storeData?.storeNumber ?? defaultStoreUS;
  const storeAddress1 = storeData?.address1 ? storeData.address1.toLowerCase() : '';
  const storeAddress2 = storeData?.address2 ? storeData.address2.toLowerCase() : undefined;

  let storeName = storeAddress1;

  if (storeAddress2 && storeAddress2 !== 'null' && locale === countryCodes.mx) {
    storeName += `, ${storeAddress2}`;
  }

  if (storeName.length > 35 && !isFixedHeader) {
    storeName = `${storeName.slice(0, 30)}...`;
  }
  const storeIsSet = storeNumber !== defaultStoreUS;

  const getStoreHandler = () =>
    storeIsSet
      ? openStoreModal(storeModalType.myStore)
      : openStoreModal(storeModalType.searchStore);

  const handleStoreKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const code = event.keyCode;

    if (code === keyCodes.enter) {
      getStoreHandler();
    }
  };

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const currentSize = isDesktop ? 'desktop' : isMobile ? 'mobile' : 'tablet';

  const [storeState] = useGlobalState('store');
  if (isRefetching || storeState?.setStoreStatus === 'loading') {
    return (
      <div className={styles.storeAlign} data-testid="store-not-set">
        <img
          data-testid="store-not-set-icon"
          className={styles.unsetStore}
          src={storeSelectedIconSource[currentSize]}
          alt={storeIcon.alt}
          width={currentSize === 'desktop' || currentSize === 'tablet' ? 25 : 20}
          height={currentSize === 'desktop' || currentSize === 'tablet' ? 23 : 19}
          decoding="async"
        />
        <div className={styles.storeDetails}>
          <Skeleton className={styles.storeLocatorSkeletonTop} animation="wave" />
          <Skeleton className={styles.storeLocatorSkeletonBottom} animation="wave" />
        </div>
        <div
          className={cx(styles.storeArrowContainer, {
            [styles.storeArrowContainerSticky]: isFixedHeader,
          })}
        >
          <img
            src={'/images/header-arrow.svg'}
            alt=""
            data-testid="at_store_arrow_img"
            width={8}
            height={12}
            decoding="async"
          />
        </div>
      </div>
    );
  }

  const storeIconWidth = isFixedHeader
    ? 20
    : currentSize === 'desktop' || currentSize === 'tablet'
    ? 25
    : 20;

  const storeIconHeight = isFixedHeader
    ? 20
    : currentSize === 'desktop' || currentSize === 'tablet'
    ? 23
    : 19;

  return (
    <>
      <div
        onClick={getStoreHandler}
        onKeyDown={handleStoreKeyUp}
        tabIndex={0}
        role="button"
        data-testid="at_store_locator_homepage"
      >
        <div
          className={cx(storeIsSet ? styles.savedStoreAlign : styles.storeAlign, {
            [styles.storeAlignSticky]: isFixedHeader,
          })}
          data-testid={storeIsSet ? 'store-set-info' : 'store-not-set'}
        >
          <div
            className={cx(styles.storeIconContainer, {
              [styles.storeIconContainerSticky]: isFixedHeader,
            })}
          >
            <img
              data-testid={storeIsSet ? 'store-set-icon' : 'store-not-set-icon'}
              className={storeIsSet ? styles.storeIcon : styles.unsetStore}
              src={storeIsSet ? storeSelectedIconSource[currentSize] : storeIconSource}
              alt={storeIsSet ? storeIcon.alt : ''}
              width={storeIconWidth}
              height={storeIconHeight}
              decoding="async"
            />
          </div>
          {storeIsSet ? (
            <div
              className={cx(styles.storeDetails, {
                [styles.storeDetailsSticky]: isFixedHeader,
              })}
            >
              <span data-testid={dataTestIdStoreName} className={styles.storeAddress}>
                {storeName}
              </span>
              {storeData?.messageToDisplay && (
                <span
                  className={
                    storeData.messageToDisplay.toLowerCase().includes('open') ||
                    storeData.messageToDisplay.toLowerCase().includes('abierto') ||
                    storeData.messageToDisplay.toLowerCase().includes('aberto')
                      ? cx(styles.storeOpen, {
                          [styles.storeOpenSticky]: isFixedHeader,
                        })
                      : cx(styles.storeClosed, {
                          [styles.storeClosedSticky]: isFixedHeader,
                        })
                  }
                  aria-label={storeData.messageToDisplay.toLowerCase()}
                >
                  {storeData.messageToDisplay}
                </span>
              )}
            </div>
          ) : (
            <div
              data-testid="search-store-text"
              className={cx([azCommonStyles['az-body-2-regular']], styles.storeText, {
                [styles.storeTextSticky]: isFixedHeader,
              })}
            >
              <Label label="label_topnav_body_SelectStore" />
            </div>
          )}
          <div
            className={cx(styles.storeArrowContainer, {
              [styles.storeArrowContainerSticky]: isFixedHeader,
            })}
          >
            <img
              src={'/images/header-arrow.svg'}
              alt=""
              data-testid="at_store_arrow_img"
              width={isFixedHeader ? 6 : 8}
              height={isFixedHeader ? 11 : 12}
              decoding="async"
            />
          </div>
        </div>
      </div>
      {modalOpen ? (
        modalType === storeModalType.myStore ? (
          <LazyMyStoreModal
            closeModalOnBack={closeModalOnBack}
            closeAllModals={closeAllModals}
            closeStoreModal={closeStoreModal}
          />
        ) : (
          <LazySearchStoreModal closeAllModals={closeAllModals} closeStoreModal={closeStoreModal} />
        )
      ) : null}
    </>
  );
}
