/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import DeviceWrapper from '@/components/AZCustomComponent/DeviceWrapper';
import { Table } from '@/components/Table/Table';
import { TableBody } from '@/components/TableBody/TableBody';
import { TableCell } from '@/components/TableCell/TableCell';
import { TableRow } from '@/components/TableRow/TableRow';
import { useRef } from 'react';
import { Button } from '@/components/Button';
import styles from './styles.module.scss';
import { SmartLink as Link } from '../../../utils/smartLink';
import azCommonStyles from '@/theme/globals.module.scss';
import cx from 'classnames';
import useIsOverflow from '@/hooks/useIsOverflow';
import type {
  TableContentBlock as TableContent,
  TableCellContent,
  RowsContent,
  ColumnHeaders,
} from '@/types/legacy';
import { Picture, type PictureProps } from '@/components/Picture/Picture';
import { getImagePaths } from '@/utils/getImagePaths';

type Props = {
  content: TableContent;
};

const TableContentBlock = (props: Props) => {
  const {
    tableHeaderContent = [],
    tableRowsContent = [],
    desktop,
    tablet,
    mobile,
    theme = 'gray',
  } = props.content;
  const { rowsContent = [] } = tableRowsContent[0] ?? {};
  const { columnHeaders } = tableHeaderContent[0] ?? {};
  const ref = useRef<HTMLDivElement | null>(null);
  const isOverflown = useIsOverflow(ref, false);

  const deviceClassName = {
    desktop: desktop,
    tablet: tablet,
    mobile: mobile,
  };

  const getKeyTableRow = (hasImages: Boolean, hasButtons: Boolean, topHeader: Boolean) => {
    if (hasImages) {
      return topHeader ? 'table-row-image-top' : 'table-row-image-bottom';
    } else if (hasButtons) {
      return topHeader ? 'table-row-button-top' : 'table-row-button-bottom';
    }
    return '';
  };

  const renderTableRows = (rowContentData: RowsContent[]) => {
    return rowContentData?.map((rowData: RowsContent, rowIndex: number) => {
      return (
        <TableRow
          className={cx(styles.tableRow, theme === 'blue' ? styles.blueTheme : styles.grayTheme)}
          key={rowIndex}
          data-testid={`table-content-row-${rowIndex}`}
        >
          <TableCell
            component="td"
            className={cx(azCommonStyles['az-overline-nav'], styles.tableCellHeader)}
          >
            {rowData.name}
          </TableCell>
          {rowData.columnContent?.map((cellData: TableCellContent, cellIndex: number) => {
            return (
              <TableCell
                key={cellIndex}
                component="td"
                align="center"
                className={cx(
                  azCommonStyles['az-body-1-regular'],
                  styles.tableCell,
                  theme === 'blue' ? styles.blueTheme : styles.grayTheme
                )}
                onFocus={(e: any) => {
                  if (
                    e.target.getBoundingClientRect().left <
                    e.currentTarget.parentNode.firstChild.getBoundingClientRect().right
                  ) {
                    e.target.scrollIntoView({ block: 'center', inline: 'center' });
                  }
                }}
              >
                <p>{cellData.content} </p>
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  };
  const renderImage = (ctaImageLink: string, columnheaderCellData: ColumnHeaders) => {
    const imagePaths = getImagePaths(columnheaderCellData.imagePath);
    const pictureProps: PictureProps = {
      mobile: { src: imagePaths.phoneImage, width: 130, height: 130 },
      tablet: { src: imagePaths.tabletImage, width: 130, height: 130 },
      desktop: { src: imagePaths.desktopImage, width: 200, height: 200 },
      imageClassName: styles.tableHeadingImg,
      altText: columnheaderCellData.altText,
      loading: 'lazy',
    };
    return ctaImageLink ? (
      <Link
        to={ctaImageLink}
        samePage
        ariaLabel={columnheaderCellData.altText}
        className={styles.ctaLink}
      >
        <Picture {...pictureProps} tabIndex={-1} />
      </Link>
    ) : (
      <Picture {...pictureProps} />
    );
  };

  const renderTableHeaderRowBottom = (
    columnHeaderRowData: ColumnHeaders[],
    tableRowKey: string
  ) => {
    return (
      <TableRow className={styles.tableRowHeader} key={tableRowKey} data-testid="table-row-bottom">
        {columnHeaderRowData?.map((columnheaderCellData: ColumnHeaders, cellIndex: number) => {
          return (
            <TableCell
              component="th"
              align="center"
              className={cx(
                styles.tableCellButton,
                theme === 'blue' ? styles.blueTheme : styles.grayTheme
              )}
              key={cellIndex}
              onFocus={(e: any) => {
                if (
                  e.target.getBoundingClientRect().left <
                    e.currentTarget.parentNode.firstChild.getBoundingClientRect().right &&
                  cellIndex > 0
                ) {
                  e.target.scrollIntoView({ block: 'nearest', inline: 'center' });
                }
              }}
            >
              <div className={styles.bottomCellContainer}>
                {!!columnheaderCellData.legalText ? (
                  <p
                    className={styles.legalText}
                    data-testid={`table-bottom-cell-legal-${cellIndex}`}
                  >
                    {columnheaderCellData.legalText}
                  </p>
                ) : null}
                {columnheaderCellData.ctaBottomText ? (
                  <Link
                    to={columnheaderCellData.ctaBottomLink}
                    ariaLabel={
                      columnheaderCellData.altText
                        ? `${columnheaderCellData.ctaBottomText} ${columnheaderCellData.altText}`
                        : columnheaderCellData.ctaBottomText
                    }
                    samePage
                    className={styles.ctaLink}
                    data-testid={`table-bottom-cell-button-${cellIndex}`}
                  >
                    <Button
                      customClass={styles.ctaButton}
                      variant="contained"
                      ariaHidden={true}
                      tabIndex={-1}
                    >
                      {columnheaderCellData.ctaBottomText}
                    </Button>
                  </Link>
                ) : null}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const renderTableHeaderRowTop = (
    columnHeaderRowData: ColumnHeaders[],
    hasImages: Boolean = false,
    hasButtons: Boolean = false,
    tableRowKey: string
  ) => {
    return (
      <TableRow className={styles.tableRowHeader} key={tableRowKey} data-testid={tableRowKey}>
        {columnHeaderRowData?.map((columnheaderCellData: ColumnHeaders, cellIndex: number) => {
          const hasImage = hasImages && !!columnheaderCellData.imagePath;
          const hasButton = hasButtons && !!columnheaderCellData.ctaTopLink;
          return (
            <TableCell
              component="th"
              scope="row"
              align="center"
              className={styles.tableCellButton}
              key={cellIndex}
              data-testid={`table-header-cell-${
                hasImage ? 'image' : hasButton ? 'button' : ''
              }-${cellIndex}`}
              onFocus={(e: any) => {
                if (
                  e.target.getBoundingClientRect().left <
                    e.currentTarget.parentNode.firstChild.getBoundingClientRect().right &&
                  cellIndex > 0
                ) {
                  e.target.scrollIntoView({ block: 'center', inline: 'center' });
                }
              }}
            >
              {hasImage ? (
                renderImage(columnheaderCellData.ctaImageLink, columnheaderCellData)
              ) : hasButton ? (
                <Link
                  to={columnheaderCellData.ctaTopLink}
                  ariaLabel={
                    columnheaderCellData.altText
                      ? `${columnheaderCellData.ctaTopText} ${columnheaderCellData.altText}`
                      : columnheaderCellData.ctaTopText
                  }
                  samePage
                  className={styles.ctaLink}
                >
                  <Button
                    customClass={styles.ctaButton}
                    variant="contained"
                    ariaHidden={true}
                    tabIndex={-1}
                  >
                    {columnheaderCellData.ctaTopText}
                  </Button>
                </Link>
              ) : null}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return tableRowsContent ? (
    <DeviceWrapper {...deviceClassName}>
      <div
        className={cx(styles.tableContentBlock, {
          [cx(styles.tableOverflown)]: isOverflown,
        })}
        data-testid="table-content-block-container"
      >
        <div className={styles.scrollingContainer} ref={ref}>
          <Table className={cx(styles.table)}>
            <TableBody className={styles.tableBody}>
              {renderTableHeaderRowTop(
                columnHeaders,
                true,
                false,
                getKeyTableRow(true, false, true)
              )}
              {renderTableHeaderRowTop(
                columnHeaders,
                false,
                true,
                getKeyTableRow(false, true, true)
              )}
              {renderTableRows(rowsContent)}
              {renderTableHeaderRowBottom(columnHeaders, getKeyTableRow(true, false, false))}
            </TableBody>
          </Table>
        </div>
      </div>
    </DeviceWrapper>
  ) : null;
};

export default TableContentBlock;
