/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useDeviceType } from '@/utils/useDeviceType';
import { type NextRouter, useRouter } from 'next/router';
import { useFeatureFlag } from '@/features/globalConfig';

export const useIs24ProductViewEnabled = () => {
  const deviceType = useDeviceType();
  const router = useRouter();
  const flagValue = useFeatureFlag('IS_24_PRODUCT_VIEW_ENABLED') === 'true';

  return is24ProductViewEnabled({
    deviceType,
    query: router.query,
    flagValue,
  });
};

export const is24ProductViewEnabled = ({
  query,
  deviceType,
  flagValue,
}: {
  query: NextRouter['query'];
  deviceType: string;
  flagValue: boolean;
}) => flagValue && deviceType !== 'bot' && (!query.recsPerPage || query.recsPerPage === '24');
