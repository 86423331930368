/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import styles from './ScrollToTopButton.module.scss';
import Image from '@/components/NextImage';
import root from 'window-or-global';

const upArrow = '/images/az-up-arrow.svg';

const ScrollToTopButton = () => {
  return (
    <div
      data-testid="scroll-to-top-button"
      className={styles.buttonContainer}
      onClick={() => {
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }}
    >
      <Image alt={`scroll-to-top-image`} src={upArrow} height={50} width={50} />
    </div>
  );
};

export default ScrollToTopButton;
