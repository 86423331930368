/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type KeyboardEvent, type MouseEvent } from 'react';
import { View, Icon, Actionable } from '@az/starc-ui';
import { Clock, Close } from '@az/starc-ui-icons';
import styles from './styles.module.scss';
import cx from 'classnames';

const unHighlightSearchText = (suggestion: string, searchText: string) => {
  const currentIndex = suggestion.toLowerCase().indexOf(searchText.toLowerCase());
  return (
    <span id="suggestionHigh">
      {suggestion.substring(0, currentIndex)}
      <span className={cx({ [styles.matchedText]: currentIndex > -1 })}>
        {suggestion.substring(currentIndex, currentIndex + searchText?.length)}
      </span>
      {suggestion.substring(currentIndex + searchText?.length)}
    </span>
  );
};

type RecentSearchItemProps = {
  entry: string;
  index: number;
  cursor: number;
  searchText: string;
  deleteEntryButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
  handleEntryClick: (e: KeyboardEvent | MouseEvent, entry: string) => void;
  handleDeleteClick: (e: KeyboardEvent | MouseEvent, index: number) => void;
};

const RecentSearchItem = ({
  entry,
  handleEntryClick,
  deleteEntryButtonRef,
  index,
  searchText,
  handleDeleteClick,
  cursor,
}: RecentSearchItemProps) => {
  return (
    <View className={styles.recentSearchItem}>
      <View
        padding={[0, 4]}
        className={cx(styles.bottomDivider, { [styles.active]: index === cursor })}
      >
        <View
          as="li"
          attributes={{
            tabIndex: 0,
            onClick: (e) => handleEntryClick(e, entry),
            onKeyDown: (e) => handleEntryClick(e, entry),
          }}
          className={styles.recentSearch}
        >
          <Icon svg={Clock} size={3} />
          <View padding={[2.5, 5, 2.5, 2]} className={styles.text}>
            {unHighlightSearchText(entry, searchText)}
          </View>
        </View>
      </View>
      <div className={styles.delete}>
        <Actionable ref={deleteEntryButtonRef} onClick={(e) => handleDeleteClick(e, index)}>
          <Icon svg={Close} size={3} />
        </Actionable>
      </div>
    </View>
  );
};

export default RecentSearchItem;
