/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React from 'react';
import { type TextProps, View } from '@az/starc-ui';
import { type ContentStackFeaturedContent } from '../../interface';
import { CMSFeaturedContentItem } from './components/CMSFeaturedContentItem';
import { Legal } from '@/components/AZCustomComponent/Legal';
import { mapContentstackLegalValues } from '@/utils/mapContentstackLegalValues';
import { CMSHeadline } from '../CMSHeadline/CMSHeadline';
import styles from './CMSFeaturedContent.module.scss';
import cx from 'classnames';
import { useMediaQuery } from '@/hooks/useMediaQuery';

type Props = {
  content: ContentStackFeaturedContent;
};

export function CMSFeaturedContent({ content }: Props) {
  const {
    headline,
    headline_underline = false,
    background_color,
    feature_content,
    legal,
    headline_position,
    layout,
  } = content;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const hasBackgroundColor = !!background_color?.hex && background_color?.hex !== '#ffffff';
  const textColor: TextProps['color'] = hasBackgroundColor ? 'secondary' : 'primary';

  if (!feature_content) {
    return null;
  }

  return (
    <div
      className={cx(hasBackgroundColor ? styles.containerBg : null)}
      style={{
        backgroundColor: background_color?.hex,
      }}
      data-testid="cms-featured-content"
    >
      {headline && (
        <CMSHeadline color={textColor} underline={headline_underline}>
          {headline}
        </CMSHeadline>
      )}
      <div
        className={cx(styles.itemsContainer, {
          [styles.oneColumn]: isMobile,
          [styles.twoColumns]: layout === 'Two' || (isMobile && !!layout),
          [styles.threeColumns]: layout === 'Three',
          [styles.fourColumns]: layout === 'Four',
          [styles.fiveColumns]: layout === 'Five',
        })}
      >
        {feature_content?.length && feature_content?.length > 1 ? (
          feature_content?.map((item, i) => (
            <div key={i} className={styles.itemContainer}>
              <CMSFeaturedContentItem
                {...item}
                textColor={textColor}
                direction={headline_position === 'Center' ? 'center' : 'start'}
              />
            </div>
          ))
        ) : (
          <CMSFeaturedContentItem
            {...feature_content[0]}
            textColor={textColor}
            direction={headline_position === 'Center' ? 'center' : 'start'}
          />
        )}
      </div>
      {legal && (
        <View className={cx(styles.legal, hasBackgroundColor ? styles.legalColor : null)}>
          <Legal content={mapContentstackLegalValues(legal)} />
        </View>
      )}
    </div>
  );
}
