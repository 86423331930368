/**
 * Copyright 2019-2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  getStoredYmmeActionSource,
  removeYmmeActionSource,
} from '@/features/ymme/utils/ymmeActionSourceHelper';
import { type InteractionLocation } from '@/types/analytics';
import { clickTrack } from './analytics/clickTrack';
import { ymmeActionSources } from './ymmeHelpers';
import root from 'window-or-global';
import { type HeaderData } from '@/features/header';
import { getStoredInteractionLocation } from '@/features/ymme/utils/ymmeLocationHelper';

const addVehicle = (vehicleData: any) => {
  const { pageName = '', pageType = '', siteSection = '' } = root.utag_data || {};
  const customLinkName = `${pageType}:${vehicleData.actionSource || ymmeActionSources.directURL}`;
  const vehicleInteractionLocation: InteractionLocation = vehicleData.interactionLocation ?? 'URL';
  clickTrack({
    eventType: 'addVehicle',
    userVehicleCount: vehicleData.userVehicleCount,
    vehicleYear: vehicleData.vehicleYear,
    vehicleMake: vehicleData.vehicleMake,
    vehicleModel: vehicleData.vehicleModel,
    vehicleEngine: vehicleData.vehicleEngine,
    vehicleId: vehicleData.vehicleId,
    vehicle_interaction_location: vehicleInteractionLocation,
    pageName,
    pageType,
    siteSection,
    customLinkName: customLinkName,
  });
  removeYmmeActionSource();
};

export const trackAddVehicle = (isAddByURL: boolean, headerData?: HeaderData) => {
  const vehicleData = headerData?.vehicleMap || {
    vehicleCount: '',
    make: '',
    model: '',
    engine: '',
    catalogVehicleId: '',
    year: '',
  };
  addVehicle({
    userVehicleCount: vehicleData.vehicleCount,
    vehicleMake: vehicleData.make,
    vehicleModel: vehicleData.model,
    vehicleEngine: vehicleData.engine,
    vehicleId: vehicleData.catalogVehicleId,
    vehicleYear: vehicleData.year,
    interactionLocation: isAddByURL ? 'URL' : getStoredInteractionLocation(),
    actionSource: getStoredYmmeActionSource(),
  });
};
