/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useLabel } from '@/hooks/useLabels';

type LabelProps = {
  label: string;
  upper?: boolean;
  lower?: boolean;
};

/**
 * @deprecated Avoid using this component, instead use the useLabels() hook
 */
export const Label = ({ label, upper, lower }: LabelProps) => {
  const output = useLabel(label);
  if (upper) {
    return <>{output.toUpperCase()}</>;
  } else if (lower) {
    return <>{output.toLocaleLowerCase()}</>;
  }
  return output ? <>{output}</> : null;
};
