/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type * as React from 'react';

export function setRef<T>(ref: React.Ref<any>, value: T) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    // @ts-expect-error
    ref.current = value;
  }
}
