/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './HorizontalCard.module.scss';
import Image from '@/components/NextImage';
import { useSwipeable } from 'react-swipeable';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import type { ContentStackHorizontalScrollCards } from '@/features/contentstack/interface';
import HorizontalCard from './HorizontalCard';

type HorizontalCardsProps = {
  horizontalCardsData: ContentStackHorizontalScrollCards['card_content'];
};

const HorizontalCards: React.FC<HorizontalCardsProps> = ({ horizontalCardsData }) => {
  const cardContainerRef = useRef<HTMLDivElement>(null);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleScrollForward = () => {
    if (!cardContainerRef.current) return;

    const lastCard = cardContainerRef.current.lastElementChild;
    if (
      !lastCard ||
      lastCard.getBoundingClientRect().right <=
        cardContainerRef.current.getBoundingClientRect().right
    ) {
      return;
    }

    const containerRight = cardContainerRef.current.getBoundingClientRect().right;
    const lastCardRight = lastCard.getBoundingClientRect().right;
    const remainingScroll = lastCardRight - containerRight + 20;
    const cardWidth =
      cardContainerRef.current.firstElementChild?.clientWidth !== undefined
        ? cardContainerRef.current.firstElementChild?.clientWidth + 11
        : 231;
    cardContainerRef.current.scrollBy({
      left: Math.min(remainingScroll, cardWidth),
      behavior: 'smooth',
    });
  };

  const handleScrollBackward = () => {
    if (!cardContainerRef.current) return;

    const firstCard = cardContainerRef.current.firstElementChild;
    if (
      !firstCard ||
      firstCard.getBoundingClientRect().left >=
        cardContainerRef.current.getBoundingClientRect().left
    ) {
      return;
    }

    const containerLeft = cardContainerRef.current.getBoundingClientRect().left;
    const firstCardLeft = firstCard.getBoundingClientRect().left;
    const remainingScroll = containerLeft - firstCardLeft + 20;
    const cardWidth =
      cardContainerRef.current.firstElementChild?.clientWidth !== undefined
        ? cardContainerRef.current.firstElementChild?.clientWidth + 11
        : 231;
    cardContainerRef.current.scrollBy({
      left: -Math.min(remainingScroll, cardWidth),
      behavior: 'smooth',
    });
  };

  const checkArrowsVisibility = useCallback(() => {
    if (cardContainerRef.current) {
      const container = cardContainerRef.current;
      const firstChild = container.firstElementChild as HTMLElement;
      const lastChild = container.lastElementChild as HTMLElement;

      if (lastChild && firstChild) {
        setHideRightArrow(
          lastChild.getBoundingClientRect().right <= container.getBoundingClientRect().right
        );
        setHideLeftArrow(
          firstChild.getBoundingClientRect().left >= container.getBoundingClientRect().left
        );
      }
      if (isMobile) {
        setHideLeftArrow(true);
        setHideRightArrow(true);
      }
    }
  }, [isMobile]);

  useEffect(() => {
    const container = cardContainerRef.current;
    if (container) {
      container.addEventListener('scroll', checkArrowsVisibility);
      window.addEventListener('resize', checkArrowsVisibility);
      checkArrowsVisibility();
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', checkArrowsVisibility);
        window.removeEventListener('resize', checkArrowsVisibility);
      }
    };
  }, [cardContainerRef, checkArrowsVisibility]);

  const swipeableHandlers = useSwipeable({
    onSwipedLeft: handleScrollForward,
    onSwipedRight: handleScrollBackward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div {...swipeableHandlers} className={styles.container}>
      <div className={styles.cardContentContainer}>
        <div
          aria-label="Previous card arrow"
          role="button"
          data-testid="carousel-left-arrow"
          className={`${styles.navButton} ${styles.navButtonLeft} ${
            hideLeftArrow ? styles.hidden : ''
          }`}
          tabIndex={0}
          onClick={handleScrollBackward}
          onKeyDown={(e) => {
            if ((e || window.event).keyCode === 13) {
              handleScrollBackward();
            }
          }}
        >
          <Image
            className={styles.chevronImg}
            src="/images/arrow_right_initial.svg"
            alt="scroll carousel left"
            height={64}
            width={57}
          />
          <Image
            className={styles.chevronImgHover}
            src="/images/arrow_right_hover.svg"
            alt=""
            height={68}
            width={57}
          />
        </div>
        <div
          className={styles.cardContainer}
          ref={cardContainerRef}
          role="list"
          aria-label="Horizontal Cards"
          tabIndex={0}
        >
          {horizontalCardsData?.map((card, index: number) => (
            <HorizontalCard
              key={`${index}-${card.heading}`}
              index={index}
              card={card}
              numberOfCards={horizontalCardsData.length}
            />
          ))}
        </div>
        <div
          aria-label="Next card arrow"
          role="button"
          data-testid="carousel-right-arrow"
          className={`${styles.navButton} ${styles.navButtonRight} ${
            hideRightArrow ? styles.hidden : ''
          }`}
          tabIndex={0}
          onClick={handleScrollForward}
          onKeyDown={(e) => {
            if ((e || window.event).keyCode === 13) {
              handleScrollForward();
            }
          }}
        >
          <Image
            className={styles.rightChevronImg}
            src="/images/arrow_right_initial.svg"
            alt=""
            height={64}
            width={57}
          />
          <Image
            className={styles.chevronImgHover}
            src="/images/arrow_right_hover.svg"
            alt=""
            height={68}
            width={57}
          />
        </div>
      </div>
    </div>
  );
};

export default HorizontalCards;
