/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type PreFooterMiddleItem } from '../interface';

const COLUMNS_TO_UPDATE = ['related models', 'related makes'];

const updatePrefooterBackendLinks = (
  prefooterData: PreFooterMiddleItem[],
  canonicalPath: string
) => {
  const result = prefooterData.map((prefooterColumn) => {
    const columnTitle = prefooterColumn.contents?.[0].title ?? '';

    if (COLUMNS_TO_UPDATE.includes(columnTitle.trim().toLocaleLowerCase())) {
      const columns = prefooterColumn.contents?.[0].content;

      if (!columns || !columns.length || !prefooterColumn.contents) {
        return prefooterColumn;
      }

      prefooterColumn = {
        ...prefooterColumn,
        contents: [
          {
            ...prefooterColumn.contents[0],
            content: columns.map((columnItem) => {
              if (columnItem.CTALink.startsWith('/parts')) {
                return columnItem;
              }

              return {
                ...columnItem,
                CTALink: `${canonicalPath}${columnItem.CTALink}`,
              };
            }),
          },
        ],
      };
    }

    return prefooterColumn;
  });

  return result;
};

export { updatePrefooterBackendLinks };
