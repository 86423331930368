/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Label } from '@/features/i18n';
import { Text, type TextProps } from '@az/starc-ui';

type SalutationProps = {
  textVariant: TextProps['variant'];
  greeting?: string;
  exclaimed?: boolean;
  isUS?: boolean;
  userFirstName: string | undefined;
  isFetchingProfile: boolean;
};

const elipsis = '…';
const nameCharacterLimit = 20;
const lineHeight = '22px';

export function Salutation({
  textVariant,
  greeting,
  userFirstName,
  exclaimed,
  isUS,
  isFetchingProfile,
}: SalutationProps) {
  const firstName = userFirstName?.toLowerCase() ?? '';
  const firstNameMax = `${firstName?.slice(0, 20)}${
    firstName && firstName.length > nameCharacterLimit ? elipsis : ''
  }`;
  const firstNames = firstNameMax.split(' ');
  for (let i = 0; i < firstNames.length; i++) {
    firstNames[i] = firstNames[i].charAt(0).toUpperCase() + firstNames[i].slice(1);
  }
  const firstNameMaxCapitalized = firstNames.join(' ');

  if (!userFirstName) {
    return isFetchingProfile ? <div style={{ height: lineHeight }} /> : null;
  }

  return (
    <Text variant={textVariant} maxLines={1} attributes={{ style: { lineHeight } }}>
      {exclaimed && !isUS ? '¡' : ''}
      {greeting ?? <Label label="label_myAccount_greeting" />}
      {` ${firstNameMaxCapitalized}${exclaimed ? '!' : ''}`}
    </Text>
  );
}
