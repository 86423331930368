/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { createContext } from 'react';
import type { UseProductListDataResult } from './interface';

export const ProductListContext = createContext<
  UseProductListDataResult & {
    setShouldFetchSecondaryProductSkus: React.Dispatch<React.SetStateAction<boolean>> | undefined;
    loadMoreClicked: boolean;
  }
>({
  data: undefined,
  status: 'idle',
  isFetching: false,
  isSuccess: false,
  isLoading: false,
  isError: false,
  isIdle: true,
  productSkuIsFetching: false,
  productSkuIsLoading: false,
  secondaryProductSkuIsFetching: false,
  setShouldFetchSecondaryProductSkus: undefined,
  loadMoreClicked: false,
  additionalProductSkuDetailsIsFetching: false,
});
