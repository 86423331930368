/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { requestBaseURL } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { type AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';
import type {
  AddToCartData,
  AddToCartResponse,
  AddToCartPayload,
} from '@/types/reduxStore/cartUpdate';

const URL = `${requestBaseURL}/ecomm/b2c/order/v4/item`;

const addToCart = async (options: AddToCartPayload, axiosInstance?: AxiosInstance) => {
  const {
    skuId,
    productId,
    quantity,
    fulfillmentTypeId,
    vehicleId,
    storeNumber,
    searchedTerm,
    configData,

    xFusionQueryId,
  } = options;

  const response = await getAxios(axiosInstance).post<AddToCartResponse>(URL, {
    skuId,
    productId,
    quantity,
    fulfillmentTypeId,
    vehicleId,
    storeNumber,
    configData,
    searchedTerm,
    xFusionQueryId,
  });

  return selector(response.data);
};

const selector = (data: AddToCartResponse): AddToCartData => data;

export const useAddToCart = ({ onAddToCartSuccess }: { onAddToCartSuccess?: () => void } = {}) => {
  return useMutation({
    mutationFn: ({
      skuId,
      productId,
      quantity,
      fulfillmentTypeId,
      vehicleId,
      storeNumber,
      configData,
      searchedTerm,
      xFusionQueryId,
    }: AddToCartPayload) =>
      addToCart({
        skuId,
        productId,
        quantity,
        fulfillmentTypeId,
        vehicleId,
        storeNumber,
        configData,
        searchedTerm,
        xFusionQueryId,
      }),
    onSuccess: () => {
      onAddToCartSuccess?.();
    },
  });
};
