/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from '@/hooks/redux/useDispatch';
import actionConstants from '@/types/action';
import { type ReduxState } from '@/types';
import hideConfirmationPopup from '@/actions/loginConfirmation';
import type { RewardSigninModalProps } from './RewardSigninModal/RewardSigninModal';
import type { RewardsMergeConfirmationModalProps } from './RewardsMergeConfirmationModal/RewardsMergeConfirmationModal';
import type { LinkRewardsAccountModalProps } from './LinkRewardsAccountModal/LinkRewardsAccountModal';
import type { ValidateRewardsModalProps } from './ValidateRewardsModal/ValidateRewardsModal';
import { globalConstant } from '@/constants/global';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';
import { useValidateRewardsAccountMutation } from '../api/postValidateRewards';
import dynamic from 'next/dynamic';
import ProgressModal from '@/components/AZCustomComponent/ProgressModal';

const LazyRewardsMergeConfirmationModal = dynamic<RewardsMergeConfirmationModalProps>(
  () =>
    import('./RewardsMergeConfirmationModal/RewardsMergeConfirmationModal').then(
      ({ RewardsMergeConfirmationModal }) => RewardsMergeConfirmationModal
    ),
  {
    loading: () => <ProgressModal noScroll />,
  }
);
const LazyRewardSigninModal = dynamic<RewardSigninModalProps>(
  () =>
    import('./RewardSigninModal/RewardSigninModal').then(
      ({ RewardSigninModal }) => RewardSigninModal
    ),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

const LazyValidateRewardsModal = dynamic<ValidateRewardsModalProps>(
  () =>
    import('./ValidateRewardsModal/ValidateRewardsModal').then(
      ({ ValidateRewardsModal }) => ValidateRewardsModal
    ),
  {
    loading: () => <ProgressModal noScroll />,
  }
);
const LazyLinkRewardsAccountModal = dynamic<LinkRewardsAccountModalProps>(
  () =>
    import('./LinkRewardsAccountModal/LinkRewardsAccountModal').then(
      ({ LinkRewardsAccountModal }) => LinkRewardsAccountModal
    ),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

export function RewardsModals() {
  const readyStatus = useSelector(
    ({ loginConfirmation }: ReduxState) => loginConfirmation.readyStatus
  );

  const show = useSelector(({ loginConfirmation }: ReduxState) => loginConfirmation.show);

  const router = useRouter();

  const dispatch = useDispatch();
  const hideModal = async () => {
    dispatch(hideConfirmationPopup());
    if (router.asPath === routePaths.checkRewardAccount) {
      await router.push(routePaths.blankURL);
    }
  };

  const validateRewardsAccountMutation = useValidateRewardsAccountMutation({});

  const isTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const modalSize = isTablet ? globalConstant.medium : globalConstant.small;

  if (!show) {
    return null;
  }

  switch (readyStatus) {
    case actionConstants.SHOW_REWARD_SIGNIN:
      return <LazyRewardSigninModal hideModal={hideModal} modalSize={modalSize} />;

    case actionConstants.LINK_REWARDS_ACC:
      return (
        <LazyLinkRewardsAccountModal
          validateRewardsAccountMutation={validateRewardsAccountMutation}
          hideModal={hideModal}
          modalSize={modalSize}
        />
      );

    case actionConstants.VALIDATE_REWARD_QUESTIONS:
      return (
        <LazyValidateRewardsModal
          validateRewardsAccountMutation={validateRewardsAccountMutation}
          hideModal={hideModal}
          modalSize={modalSize}
        />
      );

    case actionConstants.SHOW_REWARD_CONFIRMATION:
    case actionConstants.SHOW_CREATE_ACCOUNT_CONFIRMATION:
      return (
        <LazyRewardsMergeConfirmationModal
          validateRewardsAccountData={validateRewardsAccountMutation.data}
          hideModal={hideModal}
          modalSize={modalSize}
        />
      );

    default:
      return null;
  }
}
