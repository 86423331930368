/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View } from '@az/starc-ui';
import { type ContentStackIconGrid } from '../../interface';
import { CMSImage } from '../CMSImage/CMSImage';
import styles from './styles.module.scss';
import { CMSLink } from '../CMSLink/CMSLink';

type Props = {
  content: ContentStackIconGrid;
};

export const CMSIconGrid = ({ content }: Props) => {
  const { grid_content, background_color } = content;
  const containerClasses = `${styles.container} ${background_color ? styles.background : ''}`;
  return (
    <View justify="center" direction={{ s: 'column', m: 'row' }} className={containerClasses}>
      {grid_content?.map((item, idx) => {
        return (
          <CMSLink key={idx} to={item.link ?? ''} className={styles.image}>
            {item.image && <CMSImage image={item.image} />}
          </CMSLink>
        );
      })}
    </View>
  );
};
