/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { forwardRef } from 'react';
import type { ContentStackImage, SizePerDevice } from '../../interface';
import { useResponsiveImageData } from '../../hooks/useResponsiveImageData';
import { Picture } from '@/components/Picture/Picture';

type Props = {
  image: ContentStackImage;
  sizePerDevice?: SizePerDevice;
  className?: string;
  priority?: boolean;
  loading?: 'lazy' | 'eager';
};

const defaultSizePerDevice: SizePerDevice = {
  desktop: {
    width: '100%',
    height: '100%',
  },
  mobile: {
    width: '100%',
    height: '100%',
  },
  tablet: {
    width: '100%',
    height: '100%',
  },
};

export const CMSImage = forwardRef<HTMLImageElement, Props>(function CMSImageComponent(
  { image, loading, sizePerDevice = {}, className, priority }: Props,
  ref
) {
  const imageData = useResponsiveImageData({
    image,
    sizePerDevice: { ...defaultSizePerDevice, ...sizePerDevice },
  });

  return (
    <Picture
      ref={ref}
      mobile={imageData.mobile}
      priority={priority}
      loading={loading}
      tablet={imageData.tablet}
      desktop={imageData.desktop}
      altText={image.image_alt_text ?? ''}
      imageClassName={className}
    />
  );
});
