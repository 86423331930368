/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type AnyAction } from 'redux';
import type { logiConfirmationData } from '../types';
import actionTypes from '../types/action';

export const initialState = {
  readyStatus: '',
  err: null,
  show: false,
  type: 1,
  data: {} as {},
  state: null,
};
const {
  SHOW_REWARD_CONFIRMATION,
  SHOW_CREATE_ACCOUNT_CONFIRMATION, // USE REWARDS CONTEXT HERE - NOT USED
  LINK_REWARDS_ACC,
  SHOW_REWARD_SIGNIN,
  VALIDATE_REWARD_QUESTIONS,
  HIDE_POPUP,
} = actionTypes;

const logiConfirmation = (
  state: logiConfirmationData = initialState,
  action: AnyAction
): logiConfirmationData => {
  switch (action.type) {
    case SHOW_REWARD_CONFIRMATION:
      return {
        ...state,
        readyStatus: SHOW_REWARD_CONFIRMATION,
        state: action.state || null,
        show: true,
      };

    case SHOW_CREATE_ACCOUNT_CONFIRMATION:
      return { ...state, readyStatus: SHOW_REWARD_CONFIRMATION, show: true };

    case LINK_REWARDS_ACC:
      return {
        ...state,
        readyStatus: LINK_REWARDS_ACC,
        state: action.state || null,
        show: true,
      };

    case SHOW_REWARD_SIGNIN:
      return { ...state, readyStatus: SHOW_REWARD_SIGNIN, show: true };

    case VALIDATE_REWARD_QUESTIONS:
      return {
        ...state,
        readyStatus: VALIDATE_REWARD_QUESTIONS,
        state: action.state || null,
        show: true,
      };

    case HIDE_POPUP:
      return { ...state, state: null, show: false, data: action.data };

    default:
      return state;
  }
};

export default logiConfirmation;
