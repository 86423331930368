/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import azCommonStyles from '@/theme/globals.module.scss';
import autoCompleteStyles from './AutoComplete.module.scss';
import { type Item, type AutoCompleteItemsProps } from './types';
import cx from 'classnames';
import React from 'react';

export const AutoCompleteItems = React.forwardRef<HTMLDivElement, AutoCompleteItemsProps>(
  function AutoCompleteItems(props, ref) {
    const {
      showMenu,
      filteredList,
      listScrollHandler,
      selectedItem,
      handleItemClick,
      onMouseDown,
      onMouseUp,
      id,
      onKeyDown,
    } = props;

    if (!showMenu || filteredList.length === 0) {
      return null;
    }

    return (
      <div
        className={autoCompleteStyles.listStyle}
        ref={ref}
        data-testid={`${id}-dropdown-list`}
        onScroll={listScrollHandler}
      >
        <ul data-testid={`${id}-menu`}>
          {filteredList.map((listItem: Item, index: number) => {
            const suggestionStyle =
              selectedItem === listItem.label ? autoCompleteStyles.activeItem : '';
            const isLabel = listItem.isLabel ? autoCompleteStyles.labelItem : '';

            return (
              <li
                key={`${listItem.value}-${listItem.isPopular ? 'popular' : 'all'}`}
                role="presentation"
              >
                <button
                  id={`filterListItem${index}`}
                  data-testid={`${id}-dropdown-list-item-${index + 1}`}
                  onClick={() => !listItem.isLabel && handleItemClick(listItem.label)}
                  onMouseDown={onMouseDown}
                  onMouseUp={onMouseUp}
                  className={cx(
                    azCommonStyles['az-body-2-regular'],
                    autoCompleteStyles.listItem,
                    suggestionStyle,
                    isLabel
                  )}
                  onKeyDown={onKeyDown}
                  disabled={listItem.isLabel}
                >
                  {listItem.label}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
);
