/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import { useForkRef } from '@/hooks/useForkRef';
import { ownerDocument } from '@/utils/ownerDocument';

const candidatesSelector = [
  'input',
  'select',
  'textarea',
  'a[href]',
  'button',
  '[tabindex]',
  'audio[controls]',
  'video[controls]',
  '[contenteditable]:not([contenteditable="false"])',
].join(',');

function getTabIndex(node: HTMLInputElement) {
  // @ts-expect-error we check for NaN later, TODO: refactor
  const tabindexAttr = parseInt(node.getAttribute('tabindex'));

  if (!Number.isNaN(tabindexAttr)) {
    return tabindexAttr;
  }

  // Browsers do not return `tabIndex` correctly for contentEditable nodes;
  // https://bugs.chromium.org/p/chromium/issues/detail?id=661108&q=contenteditable%20tabindex&can=2
  // so if they don't have a tabindex attribute specifically set, assume it's 0.
  // in Chrome, <details/>, <audio controls/> and <video controls/> elements get a default
  //  `tabIndex` of -1 when the 'tabindex' attribute isn't specified in the DOM,
  //  yet they are still part of the regular tab order; in FF, they get a default
  //  `tabIndex` of 0; since Chrome still puts those elements in the regular tab
  //  order, consider their tab index to be 0.
  if (
    node.contentEditable === 'true' ||
    ((node.nodeName === 'AUDIO' || node.nodeName === 'VIDEO' || node.nodeName === 'DETAILS') &&
      node.getAttribute('tabindex') === null)
  ) {
    return 0;
  }

  return node.tabIndex;
}

function isNonTabbableRadio(node: HTMLInputElement) {
  if (node.tagName !== 'INPUT' || node.type !== 'radio') {
    return false;
  }

  if (!node.name) {
    return false;
  }

  const getRadio = (selector: string) =>
    node.ownerDocument.querySelector(`input[type="radio"]${selector}`);

  let roving = getRadio(`[name="${node.name}"]:checked`);

  if (!roving) {
    roving = getRadio(`[name="${node.name}"]`);
  }

  return roving !== node;
}

function isNodeMatchingSelectorFocusable(node: HTMLInputElement) {
  if (
    node.disabled ||
    (node.tagName === 'INPUT' && node.type === 'hidden') ||
    isNonTabbableRadio(node)
  ) {
    return false;
  }

  return true;
}

function defaultGetTabbable(root: HTMLElement) {
  const regularTabNodes: HTMLInputElement[] = [];
  const orderedTabNodes: {
    documentOrder: number;
    tabIndex: number;
    node: HTMLInputElement;
  }[] = [];
  Array.from(root.querySelectorAll<HTMLInputElement>(candidatesSelector)).forEach((node, i) => {
    const nodeTabIndex = getTabIndex(node);

    if (nodeTabIndex === -1 || !isNodeMatchingSelectorFocusable(node)) {
      return;
    }

    if (nodeTabIndex === 0) {
      regularTabNodes.push(node);
    } else {
      orderedTabNodes.push({
        documentOrder: i,
        tabIndex: nodeTabIndex,
        node,
      });
    }
  });

  return orderedTabNodes
    .sort((a, b) =>
      a.tabIndex === b.tabIndex ? a.documentOrder - b.documentOrder : a.tabIndex - b.tabIndex
    )
    .map((a) => a.node)
    .concat(regularTabNodes);
}

function defaultIsEnabled() {
  return true;
}

type Props = {
  children: any;
  disableAutoFocus?: boolean;
  disableEnforceFocus?: boolean;
  disableRestoreFocus?: boolean;
  getTabbable?: (root: HTMLInputElement) => Array<HTMLInputElement>;
  isEnabled?: () => boolean;
  open: boolean;
  shouldFocus?: boolean;
};
/**
 * Utility component that locks focus inside the component.
 */

export function TrapFocus(props: Props) {
  const {
    children,
    disableAutoFocus = false,
    disableEnforceFocus = false,
    disableRestoreFocus = false,
    getTabbable = defaultGetTabbable,
    isEnabled = defaultIsEnabled,
    open,
    shouldFocus,
  } = props;
  const ignoreNextEnforceFocus = React.useRef<boolean>();
  const sentinelStart = React.useRef(null);
  const sentinelEnd = React.useRef<HTMLDivElement | null>(null);
  const nodeToRestore = React.useRef<React.FocusEvent['target'] | null>(null);
  const reactFocusEventTarget = React.useRef<React.FocusEvent['target'] | null>(null);
  // This variable is useful when disableAutoFocus is true.
  // It waits for the active element to move into the component to activate.
  const activated = React.useRef(false);

  const rootRef = React.useRef<HTMLInputElement | null>(null);
  const handleRef = useForkRef(children.ref, rootRef);
  const lastKeydown = React.useRef<KeyboardEvent | null>(null);
  React.useEffect(() => {
    // We might render an empty child.
    if (!open || !rootRef.current) {
      return;
    }

    activated.current = !disableAutoFocus;
  }, [disableAutoFocus, open]);

  React.useEffect(() => {
    // We might render an empty child.
    if (!open || !rootRef.current) {
      return;
    }

    const doc = ownerDocument(rootRef.current);

    if (!rootRef.current?.contains(doc.activeElement)) {
      if (!rootRef.current?.hasAttribute('tabIndex')) {
        rootRef.current?.setAttribute('tabIndex', '-1');
      }

      if (activated.current && shouldFocus) {
        rootRef.current?.focus();
      }
    }

    return () => {
      // restoreLastFocus()
      if (!disableRestoreFocus) {
        // In IE11 it is possible for document.activeElement to be null resulting
        // in nodeToRestore.current being null.
        // Not all elements in IE11 have a focus method.
        // Once IE11 support is dropped the focus() call can be unconditional.
        // @ts-expect-error to be removed
        if (nodeToRestore.current?.focus) {
          ignoreNextEnforceFocus.current = true;
          // @ts-expect-error to be removed
          nodeToRestore.current?.focus();
        }

        nodeToRestore.current = null;
      }
    };
    // Missing `disableRestoreFocus` which is fine.
    // We don't support changing that prop on an open TrapFocus
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    // We might render an empty child.
    if (!open || !rootRef.current) {
      return;
    }

    const doc = ownerDocument(rootRef.current);

    const contain = (nativeEvent?: FocusEvent | KeyboardEvent) => {
      const { current: rootElement } = rootRef;
      // Cleanup functions are executed lazily in React 17.
      // Contain can be called between the component being unmounted and its cleanup function being run.

      if (rootElement === null) {
        return;
      }

      if (
        !doc.hasFocus() ||
        disableEnforceFocus ||
        !isEnabled() ||
        ignoreNextEnforceFocus.current
      ) {
        ignoreNextEnforceFocus.current = false;
        return;
      }

      if (!rootElement.contains(doc.activeElement)) {
        // if the focus event is not coming from inside the children's react tree, reset the refs
        if (
          (nativeEvent && reactFocusEventTarget.current !== nativeEvent.target) ||
          doc.activeElement !== reactFocusEventTarget.current
        ) {
          reactFocusEventTarget.current = null;
        } else if (reactFocusEventTarget.current !== null) {
          return;
        }

        if (!activated.current) {
          return;
        }

        let tabbable: HTMLInputElement[] = [];

        if (
          doc.activeElement === sentinelStart.current ||
          doc.activeElement === sentinelEnd.current
        ) {
          if (rootRef.current) {
            tabbable = getTabbable(rootRef.current);
          }
        }

        if (tabbable.length > 0) {
          const isShiftTab = Boolean(
            lastKeydown.current?.shiftKey && lastKeydown.current?.key === 'Tab'
          );

          const focusNext = tabbable[0];
          const focusPrevious = tabbable[tabbable.length - 1];

          if (isShiftTab) {
            focusPrevious.focus();
          } else {
            focusNext.focus();
          }
        }
      }
    };

    const loopFocus = (nativeEvent: KeyboardEvent) => {
      lastKeydown.current = nativeEvent;

      if (disableEnforceFocus || !isEnabled() || nativeEvent.key !== 'Tab') {
        return;
      }

      // Make sure the next tab starts from the right place.
      // doc.activeElement referes to the origin.
      if (doc.activeElement === rootRef.current && nativeEvent.shiftKey) {
        // We need to ignore the next contain as
        // it will try to move the focus back to the rootRef element.
        ignoreNextEnforceFocus.current = true;
        sentinelEnd.current?.focus();
      }
      const closeBtnHeight = document.getElementById('forgot_containerClose')?.clientHeight;
      const targetEl = nativeEvent?.target as Element;
      if (
        nativeEvent?.target &&
        targetEl.id === 'forgot-password-continue-button' &&
        nativeEvent.shiftKey === false
      ) {
        nativeEvent.stopImmediatePropagation();
        nativeEvent.preventDefault();
        closeBtnHeight === 0
          ? document.getElementById('forgot-modal-close-btn')?.focus()
          : document.getElementById('forgot_containerClose')?.focus();
        return;
      }
      if (nativeEvent.shiftKey) {
        if (
          (nativeEvent?.target && targetEl.id && targetEl.id === 'forgot_containerClose') ||
          targetEl.id === 'forgot-modal-close-btn'
        ) {
          nativeEvent.stopImmediatePropagation();
          nativeEvent.preventDefault();
          document.getElementById('forgot-password-continue-button')?.focus();
        }
      }
    };

    doc.addEventListener('focus', contain, true);
    doc.addEventListener('keydown', loopFocus, true);

    // With Edge, Safari and Firefox, no focus related events are fired when the focused area stops being a focused area.
    // e.g. https://bugzilla.mozilla.org/show_bug.cgi?id=559561.
    // Instead, we can look if the active element was restored on the BODY element.
    //
    // The whatwg spec defines how the browser should behave but does not explicitly mention any events:
    // https://html.spec.whatwg.org/multipage/interaction.html#focus-fixup-rule.
    const interval = setInterval(() => {
      if (doc.activeElement?.tagName === 'BODY') {
        contain();
      }
    }, 50);
    return () => {
      clearInterval(interval);
      doc.removeEventListener('focus', contain, true);
      doc.removeEventListener('keydown', loopFocus, true);
    };
  }, [disableAutoFocus, disableEnforceFocus, disableRestoreFocus, isEnabled, open, getTabbable]);

  const onFocus = (event: React.FocusEvent) => {
    if (nodeToRestore.current === null) {
      nodeToRestore.current = event.relatedTarget;
    }

    activated.current = true;
    reactFocusEventTarget.current = event.target;
    const childrenPropsHandler = children.props.onFocus;

    if (childrenPropsHandler) {
      childrenPropsHandler(event);
    }
  };

  const handleFocusSentinel = (event: React.FocusEvent) => {
    if (nodeToRestore.current === null) {
      nodeToRestore.current = event.relatedTarget;
    }

    activated.current = true;
  };

  return (
    <React.Fragment>
      <div tabIndex={0} role="button" onFocus={handleFocusSentinel} ref={sentinelStart} />
      {React.cloneElement(children, { ref: handleRef, onFocus })}
      <div tabIndex={0} role="button" onFocus={handleFocusSentinel} ref={sentinelEnd} />
    </React.Fragment>
  );
}
