/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { PageTypeData } from '@/types';
import { type NextRouter } from 'next/router';
import { productListingPageTypes } from '../constants/productListingPageTypes';
import { getPathnameFromAsPath } from '@/utils/urlHelpers';
import { type SearchSkuRecordResponse } from '../../search/interface';

export const generateContentStackPLPParams = ({
  router,
  isCSBrandShelfPageEnabled,
  isCSShelfPageEnabled,
  isCSSearchResultsPageEnabled,
  pageTypeData,
  searchSkuRecordData,
}: {
  router: NextRouter;
  isCSShelfPageEnabled: boolean;
  isCSBrandShelfPageEnabled: boolean;
  isCSSearchResultsPageEnabled: boolean;
  pageTypeData: PageTypeData | undefined;
  searchSkuRecordData: SearchSkuRecordResponse | undefined;
}) => {
  let productListingPageType;
  let taxonomyPath;
  let url;
  let isCSProductListingPageEnabled = false;
  switch (router.pathname) {
    case '/deals/[cmsPath]/[[...slug]]':
      productListingPageType = productListingPageTypes.merchShelf;
      taxonomyPath = `/deals/${router.query.cmsPath as string}`;
      url = `/deals/${router.query.cmsPath as string}`;
      isCSProductListingPageEnabled = isCSShelfPageEnabled;
      break;
    case '/brands/[brandName]':
      productListingPageType = productListingPageTypes.brandShelfDefault;
      url = getPathnameFromAsPath(router.asPath);
      isCSProductListingPageEnabled = isCSBrandShelfPageEnabled;
      break;
    case '/[...seoUrlPath]':
      taxonomyPath = pageTypeData?.botEnabledFacetPath
        ? `${pageTypeData.taxonomyPath}${pageTypeData.botEnabledFacetPath}`
        : pageTypeData?.taxonomyPath ?? '';
      productListingPageType = productListingPageTypes.default;
      isCSProductListingPageEnabled =
        isCSShelfPageEnabled && pageTypeData?.pageType === 'ProductShelf';
      break;
    case '/searchresult':
      url = searchSkuRecordData?.skuRecord?.taxonomyPath;
      productListingPageType = productListingPageTypes.searchResult;
      isCSProductListingPageEnabled =
        !!searchSkuRecordData?.skuRecord?.taxonomyPath && isCSSearchResultsPageEnabled;
      break;
  }

  return { productListingPageType, taxonomyPath, url, isCSProductListingPageEnabled };
};
